import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, List, ListItem, ListItemIcon } from "@material-ui/core";
import { measures } from "../../data/measures";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { navigation } from "../../data/navigation";

const styles = (theme) => ({
  drawer: {
    flexShrink: 0,
    width: measures["expandedWidth"],
  },
  paper: {
    webkitTransition: "width 0.3s ease-in-out",
    transition: "width 0.3s ease-in-out",
    width: measures["expandedWidth"],
    backgroundColor: "#F1F1F1",
    borderRight: "1px solid #AAA",
  },
  toolbar: theme.mixins.toolbar,
  sidebar: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  activeNav: {
    fontWeight: 500,
  },
  sidebarItem: {
    padding: 15,
  },
  listItemIcon: {
    minWidth: 40,
  },
  sidebarIcon: {
    color: theme.palette.secondary.main,
    fontSize: 22,
  },
  sidebarText: {
    fontSize: 16,
    marginLeft: 5,
  },
});

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

function Sidebar({ classes, auth }) {
  return (
    <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.paper }}>
      <div className={classes.toolbar} />
      <div className={classes.sidebar}>
        <List>
          {navigation.map((nav, index) => {
            if (auth.user && nav.roles.indexOf(Number(auth.user.userType)) !== -1) {
              return (
                <NavLink activeClassName={classes.activeNav} key={index} to={nav.to} className={classes.link}>
                  <ListItem className={classes.sidebarItem} button>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <FontAwesomeIcon icon={nav.icon} className={classes.sidebarIcon} />
                    </ListItemIcon>
                    <span className={classes.sidebarText}>{nav.name}</span>
                  </ListItem>
                </NavLink>
              );
            } else {
              return null;
            }
          })}
        </List>
      </div>
    </Drawer>
  );
}

export default connect(mapStateToProps, null)(withStyles(styles)(Sidebar));

import * as ActionTypes from "../config/ActionTypes";

export const initialState = {
  isLoading: false,
  errorMessage: null,
  users: null,
  addingUser: false,
  addUserErrorMessage: null,
  addUserSuccess: false,
  deletingUser: false,
  deleteUserErrorMessage: null,
  deleteUserSuccess: false,
};

export const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_USERS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        users: action.payload,
      };

    case ActionTypes.USERS_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };

    case ActionTypes.USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
        users: null,
      };

    case ActionTypes.ADD_USER:
      action.payload.userName = action.payload.username;
      let newUsers = state.users || [];
      newUsers.push(action.payload);

      return {
        ...state,
        addingUser: false,
        addUserErrorMessage: null,
        addUserSuccess: true,
        users: newUsers,
      };

    case ActionTypes.ADDING_USER:
      return {
        ...state,
        addingUser: true,
        addUserErrorMessage: null,
        addUserSuccess: false,
      };

    case ActionTypes.ADD_USER_FAILED:
      return {
        ...state,
        addingUser: false,
        addUserErrorMessage: action.payload,
        addUserSuccess: false,
      };

    case ActionTypes.DELETE_USER:
      let nonDeletedUsers =
        state.users &&
        state.users.filter((item, index) => {
          if (item.id !== action.payload) {
            return item;
          }
          return null;
        });

      return {
        ...state,
        deletingUser: false,
        deleteUserErrorMessage: null,
        deleteUserSuccess: true,
        users: nonDeletedUsers,
      };

    case ActionTypes.DELETING_USER:
      return {
        ...state,
        deletingUser: true,
        deleteUserErrorMessage: null,
        deleteUserSuccess: false,
      };

    case ActionTypes.DELETE_USER_FAILED:
      return {
        ...state,
        deletingUser: false,
        deleteUserErrorMessage: action.payload,
        deleteUserSuccess: false,
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

import { httpVerbs, URL } from "./config";
import { baseService } from "./base.service";

export const authService = {
  login,
  getAccount,
  register,
  changePassword,
  logout,
  payoldsubscriptions,
  fetchConfigurations,
  fetchPFConfigurations,
  updateConfigurations,
  updatePFConfigurations,
};

async function login(loginRequest) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(false),
    body: JSON.stringify(loginRequest),
  };

  return await fetch(`${URL}/auth/login`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function getAccount() {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  return await fetch(`${URL}/account`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function register(registerRequest) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(registerRequest),
  };

  return await fetch(`${URL}/auth/register`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function changePassword(changePasswordRequest) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(changePasswordRequest),
  };

  return await fetch(`${URL}/auth/password`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function logout() {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: {},
  };

  return await fetch(`${URL}/auth/logout`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function payoldsubscriptions() {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: {},
  };

  return await fetch(`${URL}/subscriptors/payold`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function fetchConfigurations(clientId) {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  requestOptions.headers.append("clientId", clientId);

  return await fetch(`${URL}/clients/configuration`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function fetchPFConfigurations(pfId) {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  return await fetch(`${URL}/paymentFacilitators/configuration/${pfId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function updateConfigurations(clientId, configurations) {
  const requestOptions = {
    method: httpVerbs.Put,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(configurations),
  };

  requestOptions.headers.append("clientId", clientId);

  return await fetch(`${URL}/clients/configuration`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function updatePFConfigurations(paymentFacilitatorId, configurations) {
  const requestOptions = {
    method: httpVerbs.Put,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(configurations),
  };

  return await fetch(`${URL}/paymentFacilitators/configuration/${paymentFacilitatorId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

import React from "react";
import "./styles/app.scss";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSignOutAlt,
  faUser,
  faCreditCard,
  faBook,
  faBalanceScale,
  faCashRegister,
  faReceipt,
  faPiggyBank,
  faAddressCard,
  faTrash,
  faEdit,
  faExclamationCircle,
  faHome,
  faKey,
  faUsers,
  faCog,
  faGift,
  faSearch,
  faChevronRight,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";
import { ConfigureStore } from "./store/config/configureStore.js";
import Main from "./components/Main";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/es";
import MomentUtils from "@date-io/moment";

library.add(
  faSignOutAlt,
  faUser,
  faUsers,
  faCreditCard,
  faBook,
  faBalanceScale,
  faCashRegister,
  faReceipt,
  faPiggyBank,
  faAddressCard,
  faBalanceScale,
  faTrash,
  faEdit,
  faExclamationCircle,
  faHome,
  faKey,
  faCog,
  faGift,
  faSearch,
  faChevronRight,
  faPlug
);

const theme = createMuiTheme({
  palette: {
    primary: { main: "#3c3c3c" },
    secondary: { main: "#256FB5" },
    error: {
      main: "#f11930",
      light: "#f9ecec",
      dark: "#c54343",
    },
    warning: {
      main: "#ffc400",
      light: "#fff9e5",
      dark: "#ffc400",
    },
    success: {
      main: "#529252",
      light: "#edf4ed",
      dark: "#529252",
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
});

const store = ConfigureStore();

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Router basename={process.env.REACT_APP_BASENAME}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            <Main />
          </MuiPickersUtilsProvider>
        </Router>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchPFIssuers, fetchIssuers } from "../../store/action_creators/issuers.actions";
import { Button } from "@material-ui/core";
import PaymentFacilitatorIssuerForm from "./PaymentFacilitatorIssuerForm";
import Loading from "../common/animations/Loading";
import Error from "../common/animations/Error";
import PaymentFacilitatorIssuer from "./PaymentFacilitatorIssuer";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    issuers: state.issuers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPFIssuers: (pfId) => {
    dispatch(fetchPFIssuers(pfId));
  },
  fetchIssuers: () => {
    dispatch(fetchIssuers());
  },
});

function PaymentFacilitatorIssuers({ auth, issuers, fetchPFIssuers, fetchIssuers }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!issuers.issuers) {
      fetchIssuers();
    }
  }, [issuers.issuers, fetchIssuers]);

  useEffect(() => {
    if (
      issuers.pfIssuers === null &&
      !issuers.pfIssuersLoading &&
      issuers.pfIssuersErrorMessage === null &&
      auth.user
    ) {
      fetchPFIssuers(auth.user.paymentFacilitatorDetails.id);
    }
  }, [issuers.pfIssuers, fetchPFIssuers, issuers.pfIssuersLoading, issuers.pfIssuersErrorMessage, auth.user]);

  const retryIssuers = () => {
    fetchPFIssuers(auth.user.paymentFacilitatorDetails.id);
  };

  if ((issuers.pfIssuers === null && !issuers.pfIssuersErrorMessage) || issuers.pfIssuersLoading) {
    return <Loading />;
  } else if (issuers.pfIssuersErrorMessage) {
    return <Error message={texts.paymentFacilitatorIssuersError} retry={retryIssuers} />;
  } else {
    return (
      <div className="pf-issuers">
        <div className="pf-issuers-title">
          <h3>{texts.paymentFacilitatorIssuers}</h3>
          <Button color="secondary" variant="contained" onClick={() => setOpen(true)}>
            {texts.newPaymentFacilitatorIssuer}
          </Button>
        </div>
        <div className="pf-issuers-list">
          {issuers.pfIssuers &&
            issuers.pfIssuers.map((pfIssuer, index) => {
              return <PaymentFacilitatorIssuer key={index} position={index} data={pfIssuer} />;
            })}
        </div>
        <PaymentFacilitatorIssuerForm open={open} setOpen={setOpen} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFacilitatorIssuers);

import React from "react";
import { Grow } from "@material-ui/core";
import { frequencyHelper } from "../../helpers/frequencyHelper";
import { texts } from "../../data/texts";

function Subscription({ position, data, openSubscription }) {
  return (
    <Grow in={true} timeout={300 * position}>
      <div className="subscription-container" onClick={(e) => openSubscription(e, data)}>
        <div className="subscription">
          <span className="name">{data.name}</span>
          <span className="description">{data.description}</span>
          <span className="subscription-type-id" onClick={(e) => {e.stopPropagation()}}>ID: {data.id}</span>
          <div className="numbers-line">
            <span className="frequence">{`${texts.frequency} ${frequencyHelper.getFrequencyName(
              data.frequence
            )}`}</span>
            <span
              className="cost"
              style={{ display: data.regularType ? "block" : "none" }}
            >{`${texts.cost}: ${data.subscriptionBaseCost}`} + IVA</span>
          </div>
        </div>
      </div>
    </Grow>
  );
}

export default Subscription;

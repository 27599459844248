import * as ActionTypes from "../config/ActionTypes";
import { reportsService } from "../../services/reports.service";

//#region Fetch dashboard

export const fetchDashboard = (clientId) => (dispatch) => {
  dispatch(dashboardLoading(clientId));

  return reportsService.fetchDashboard(clientId).then(
    (dashboard) => {
      dispatch(addDashboard(dashboard));
    },
    (error) => {
      dispatch(dashboardFailed(error));
    }
  );
};

const addDashboard = (dashboard) => ({
  type: ActionTypes.ADD_DASHBOARD,
  payload: dashboard,
});

const dashboardLoading = (clientId) => ({
  type: ActionTypes.DASHBOARD_LOADING,
  payload: clientId,
});

const dashboardFailed = (errorMessage) => ({
  type: ActionTypes.DASHBOARD_FAILED,
  payload: errorMessage,
});

//#endregion

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchSubscriptors } from "../../store/action_creators/subscriptors.actions";
import Loading from "../common/animations/Loading";
import Error from "../common/animations/Error";
import SubscriptorRow from "./SubscriptorRow";
import SubscriptorsFilters from "./SubscriptorsFilters";
import { constants } from "../../data/constants";
import SubscriptorTitle from "./SubscriptorTitle";
import Search from "../common/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import ExportSubscriptorsCSV from "../common/ExportSubscriptorsCSV";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    subscriptors: state.subscriptors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptors: ({ page, limit, filters, search, reset }) => {
    dispatch(fetchSubscriptors({ page, limit, filters, search, reset }));
  },
});

function Subscriptors({ subscriptors, fetchSubscriptors }) {
  const [searchTerms, setSearchTerms] = useState(subscriptors.search);
  const hasMore = subscriptors.currentPage < subscriptors.pageCount;

  useEffect(() => {
    if (!subscriptors.isLoading && subscriptors.errorMessage === null && !subscriptors.subscriptors) {
      fetchSubscriptors({
        page: 1,
        limit: constants.subscriptorsLimit,
        filters: subscriptors.filters,
      });
    }
  }, [
    subscriptors.isLoading,
    subscriptors.errorMessage,
    subscriptors.subscriptors,
    subscriptors.filters,
    fetchSubscriptors,
  ]);

  const loadMore = () => {
    if (hasMore) {
      fetchSubscriptors({
        page: subscriptors.currentPage + 1,
        limit: constants.subscriptorsLimit,
        filters: subscriptors.filters,
      });
    }
  };

  const search = (value) => {
    fetchSubscriptors({
      page: 1,
      limit: constants.subscriptorsLimit,
      filters: subscriptors.filters,
      search: value,
      reset: true,
    });
  };

  return (
    <div className="subscriptors">
      <div className="subscriptors-title">
        <h3>{texts.subscriptors}</h3>
        <ExportSubscriptorsCSV fileName={texts.subscriptors} buttonText={texts.exportSubscriptors} />
      </div>
      <SubscriptorsFilters />
      <div className="subscriptors-search">
        <Search value={searchTerms} setValue={setSearchTerms} searchFunction={search} />
      </div>
      {(!subscriptors.subscriptors && !subscriptors.errorMessage) ||
      (subscriptors.isLoading && subscriptors.subscriptors.length === 0) ? (
        <Loading />
      ) : subscriptors.errorMessage ? (
        <Error message={texts.subscriptorsError} retry={fetchSubscriptors} />
      ) : (
        <InfiniteScroll
          hasMore={hasMore}
          next={loadMore}
          dataLength={subscriptors.subscriptors ? subscriptors.subscriptors.length : 0}
          loader={subscriptors.isLoading && <Loading />}
        >
          <div className="subscriptors-list">
            <SubscriptorTitle />
            {subscriptors.subscriptors && subscriptors.subscriptors.length > 0 ? (
              subscriptors.subscriptors.map((subscriptor, index) => {
                return (
                  <SubscriptorRow key={index} position={index} data={subscriptor} limit={constants.subscriptorsLimit} />
                );
              })
            ) : (
              <div className="empty-list">{texts.noSubscriptionsForFilterError}</div>
            )}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptors);

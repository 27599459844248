import React from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import FormTextField from "../common/forms/FormTextField";
import { createIssuer, updateIssuer } from "../../store/action_creators/issuers.actions";
import schemas from "../../data/schemas";
import { texts } from "../../data/texts";

const mapDispatchToProps = (dispatch) => ({
  createIssuer: ({ name, normalizedName, pictureUri, plexoId, active }) => {
    dispatch(createIssuer({ name, normalizedName, pictureUri, plexoId, active }));
  },
  updateIssuer: ({ issuerId, name, normalizedName, pictureUri, plexoId, active }) => {
    dispatch(
      updateIssuer({
        issuerId,
        name,
        normalizedName,
        pictureUri,
        plexoId,
        active,
      })
    );
  },
});

function IssuerForm({ open, setOpen, selectedIssuer, createIssuer, updateIssuer }) {
  const submitIssuer = (values, { setSubmitting }) => {
    const isNew = selectedIssuer === undefined;

    if (isNew) {
      createIssuer({
        name: values.name,
        normalizedName: values.name.toUpperCase(),
        pictureUri: "TODO",
        plexoId: Number(values.plexoId),
        active: true,
      });
    } else {
      updateIssuer({
        issuerId: selectedIssuer.id,
        name: values.name,
        normalizedName: values.name.toUpperCase(),
        pictureUri: "TODO",
        plexoId: Number(values.plexoId),
        active: true,
      });
    }

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <Formik
        initialValues={{
          name: selectedIssuer ? selectedIssuer.name : "",
          plexoId: selectedIssuer ? selectedIssuer.plexoId : "",
        }}
        validationSchema={schemas.IssuerSchema}
        onSubmit={submitIssuer}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <DialogTitle>{selectedIssuer ? `Editar ${selectedIssuer.name}` : texts.newIssuer}</DialogTitle>
            <DialogContent>
              <Field label={texts.name} name="name" component={FormTextField} />
              <Field label={texts.plexoId} name="plexoId" type="number" component={FormTextField} />
            </DialogContent>
            <DialogActions>
              <Button className="save" variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                {texts.save}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(null, mapDispatchToProps)(IssuerForm);

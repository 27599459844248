import * as ActionTypes from "../config/ActionTypes";
import { authService } from "../../services/auth.service";
import { cookieHelper } from "../../helpers/cookieHelper";
import { createClient } from "./clients.actions";

//#region Login

export const login =
  ({ username, password }) =>
  (dispatch) => {
    const loginRequest = {
      username,
      password,
    };
    dispatch(loginUserIn());

    return authService.login(loginRequest).then(
      (response) => {
        const user = {
          ...response.account,
          accessToken: response.accessToken,
        };
        cookieHelper.setCookies(user);
        dispatch(loginUser(user));
      },
      (error) => {
        dispatch(loginFailed(error));
      }
    );
  };

export const loginUser = (user) => ({
  type: ActionTypes.LOGIN,
  payload: user,
});

const loginUserIn = () => ({
  type: ActionTypes.LOGIN_REQUEST,
});

const loginFailed = (errorMessage) => ({
  type: ActionTypes.LOGIN_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Logout

export const logout = () => {
  cookieHelper.removeCookies();

  return {
    type: ActionTypes.LOGOUT,
  };
};

//#endregion

//#region GetAccount

export const getAccount = () => (dispatch) => {
  dispatch(gettingAccount());

  return authService.getAccount().then(
    (response) => {
      const user = {
        ...response,
        accessToken: cookieHelper.getAllCookies()["accessToken"],
      };

      cookieHelper.setCookies(user);
      dispatch(loadAccount(user));
    },
    (error) => {
      dispatch(getAccountFailed(error));
    }
  );
};

export const loadAccount = (user) => ({
  type: ActionTypes.GET_ACCOUNT_SUCCESS,
  payload: user,
});

const gettingAccount = () => ({
  type: ActionTypes.GETTING_ACCOUNT,
});

const getAccountFailed = (errorMessage) => ({
  type: ActionTypes.GET_ACCOUNT_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Register admin

export const register = (clientRequest, registerRequest) => (dispatch) => {
  dispatch(registeringUser());

  return authService.register(registerRequest).then(
    (user) => {
      dispatch(addUser(user));

      if (clientRequest) {
        clientRequest.adminUserId = user.userId;
        dispatch(createClient(clientRequest));
      }
    },
    (error) => {
      dispatch(registerUserFailed(error));
    }
  );
};

export const addUser = (user) => ({
  type: ActionTypes.ADD_USER,
  payload: user,
});

export const registeringUser = () => ({
  type: ActionTypes.ADDING_USER,
});

export const registerUserFailed = (errorMessage) => ({
  type: ActionTypes.ADD_USER_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Change password

export const changePassword =
  ({ password, newPassword, repeatNewPassword }) =>
  (dispatch) => {
    const changePasswordRequest = {
      oldPassword: password,
      newPassword,
    };

    dispatch(changingPassword());

    return authService.changePassword(changePasswordRequest).then(
      (response) => {
        dispatch(changePasswordSuccess());
      },
      (error) => {
        dispatch(changePasswordFailed(error));
      }
    );
  };

const changePasswordSuccess = () => ({
  type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
});

const changingPassword = () => ({
  type: ActionTypes.CHANGING_PASSWORD,
});

const changePasswordFailed = (errorMessage) => ({
  type: ActionTypes.CHANGE_PASSWORD_FAILED,
  payload: errorMessage,
});
//#endregion

//#region  Pay old subscriptions
export const payOldSubscriptions = () => (dispatch) => {
  dispatch(payingOldSubscriptions());

  return authService.payoldsubscriptions().then(
    (response) => {
      dispatch(payOldSubscriptionsSuccess(response));
    },
    (error) => {
      dispatch(payOldSubscriptionsFailed(error));
    }
  );
};

const payingOldSubscriptions = () => ({
  type: ActionTypes.PAYING_OLD_SUBSCRIPTORS,
});

const payOldSubscriptionsSuccess = (response) => ({
  type: ActionTypes.SUBSCRIPTORS_PAY_OLD_SUCCESS,
  payload: response,
});

const payOldSubscriptionsFailed = (errorMessage) => ({
  type: ActionTypes.SUBSCRIPTORS_PAY_OLD_FAILED,
  payload: errorMessage,
});
//#endregion

//#region  Payment callbacks url

export const fetchPFConfigurations =
  ({ pfId }) =>
  (dispatch) => {
    dispatch(loadingConfigurations());

    return authService.fetchPFConfigurations(pfId).then(
      (response) => {
        dispatch(addConfigurations(response));
      },
      (error) => {
        dispatch(configurationsFailed(error));
      }
    );
  };

export const fetchConfigurations =
  ({ clientId }) =>
  (dispatch) => {
    dispatch(loadingConfigurations());

    return authService.fetchConfigurations(clientId).then(
      (response) => {
        dispatch(addConfigurations(response));
      },
      (error) => {
        dispatch(configurationsFailed(error));
      }
    );
  };

export const updatePFConfigurations =
  ({ paymentFacilitatorId, configurations }) =>
  (dispatch) => {
    dispatch(loadingConfigurations());

    return authService.updatePFConfigurations(paymentFacilitatorId, configurations).then(
      (response) => {
        dispatch(addConfigurations(configurations));
      },
      (error) => {
        dispatch(configurationsFailed(error));
      }
    );
  };

export const updateConfigurations =
  ({ clientId, configurations }) =>
  (dispatch) => {
    dispatch(loadingConfigurations());

    return authService.updateConfigurations(clientId, configurations).then(
      (response) => {
        dispatch(addConfigurations(configurations));
      },
      (error) => {
        dispatch(configurationsFailed(error));
      }
    );
  };

const loadingConfigurations = () => ({
  type: ActionTypes.LOADING_CONFIGURATIONS,
});

const addConfigurations = (configurations) => ({
  type: ActionTypes.CONFIGURATIONS_SUCCESS,
  payload: configurations,
});

const configurationsFailed = (errorMessage) => ({
  type: ActionTypes.CONFIGURATIONS_FAILED,
  payload: errorMessage,
});

//#endregion

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Select, MenuItem, FormControl, InputLabel, withStyles } from "@material-ui/core";
import { fetchSubscriptions } from "../../store/action_creators/subscriptions.actions";
import { fetchSubscriptors } from "../../store/action_creators/subscriptors.actions";
import { constants } from "../../data/constants";
import { texts } from "../../data/texts";

const styles = (theme) => ({
  control: {
    minWidth: 160,
    marginRight: 15,
  },
  bigControl: {
    minWidth: 260,
    marginRight: 15,
  },
});

const mapStateToProps = (state) => {
  return {
    subscriptions: state.subscriptions,
    subscriptors: state.subscriptors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptions: () => {
    dispatch(fetchSubscriptions());
  },
  fetchSubscriptors: ({ page, limit, filters, search, reset }) => {
    dispatch(fetchSubscriptors({ page, limit, filters, search, reset }));
  },
});

function SubscriptorsFilters({ classes, subscriptions, subscriptors, fetchSubscriptions, fetchSubscriptors }) {
  const [state, setState] = useState(subscriptors.filters.state);
  const [subscriptionTypes, setSubscriptionTypes] = useState(
    subscriptors.filters.subscriptionTypes.map((st) => {
      return st.id;
    })
  );

  useEffect(() => {
    if (!subscriptions.subscriptionTypes) {
      fetchSubscriptions();
    }
  }, [subscriptions.subscriptionTypes, fetchSubscriptions]);

  const applyFilters = () => {
    let objectSubscriptionTypes = subscriptionTypes.map((st) => {
      return subscriptions.subscriptionTypes.filter((s) => s.id === st)[0];
    });

    const filters = {
      state,
      subscriptionTypes: objectSubscriptionTypes,
    };

    fetchSubscriptors({
      page: 1,
      limit: constants.subscriptorsLimit,
      filters,
      search: subscriptors.search,
      reset: true,
    });
  };

  return (
    <div className="subscriptors-filters">
      <FormControl classes={{ root: classes.control }} variant="outlined">
        <InputLabel id="state-label">{texts.status}</InputLabel>
        <Select
          variant="outlined"
          labelId="state-label"
          id="state-label"
          value={state}
          onChange={(e) => {
            setState(e.target.value);
          }}
          labelWidth={55}
        >
          <MenuItem value={0}>{texts.all}</MenuItem>
          <MenuItem value={1}>{texts.active}</MenuItem>
          <MenuItem value={2}>{texts.inactive}</MenuItem>
        </Select>
      </FormControl>
      {subscriptions.subscriptionTypes ? (
        <FormControl classes={{ root: classes.bigControl }} variant="outlined">
          <InputLabel id="subscription-type-label">{texts.subscriptionType}</InputLabel>
          <Select
            variant="outlined"
            labelId="subscription-type-label"
            id="subscription-type-label"
            multiple
            value={subscriptionTypes}
            onChange={(e) => setSubscriptionTypes(e.target.value)}
            labelWidth={145}
          >
            {subscriptions.subscriptionTypes.map((st) => {
              return (
                <MenuItem key={st.id} value={st.id}>
                  {st.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
      <div className="button">
        <Button color="secondary" variant="outlined" onClick={applyFilters}>
          {texts.apply}
        </Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SubscriptorsFilters));

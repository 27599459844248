import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchCommerces } from "../../store/action_creators/commerces.actions";
import Loading from "../common/animations/Loading";
import Error from "../common/animations/Error";
import { Button } from "@material-ui/core";
import Commerce from "./Commerce";
import CommerceForm from "./CommerceForm";
import { adminTypes } from "../../data/adminTypes";
import { fetchPFIssuers } from "../../store/action_creators/issuers.actions";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    commerces: state.commerces,
    issuers: state.issuers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCommerces: (clientId) => {
    dispatch(fetchCommerces(clientId));
  },
  fetchPFIssuers: (pfId, clientId) => {
    dispatch(fetchPFIssuers(pfId, clientId));
  },
});

function Commerces({ auth, commerces, issuers, fetchCommerces, fetchPFIssuers, clientId }) {
  const [open, setOpen] = useState(false);
  const [selectedCommerce, setSelectedCommerce] = useState(null);

  useEffect(() => {
    if (
      (!commerces.commerces && !commerces.isLoading && !commerces.errorMessage) ||
      clientId !== commerces.commercesClientId
    ) {
      fetchCommerces(clientId);
    }
  }, [
    commerces.commerces,
    commerces.isLoading,
    commerces.errorMessage,
    fetchCommerces,
    clientId,
    commerces.commercesClientId,
  ]);

  useEffect(() => {
    if (
      issuers.pfIssuers === null &&
      !issuers.pfIssuersLoading &&
      issuers.pfIssuersErrorMessage === null &&
      auth.user &&
      auth.user.paymentFacilitatorDetails
    ) {
      const realClientId =
        auth.user.userType === adminTypes.CLIENTADMIN && auth.user.clientDetails
          ? auth.user.clientDetails.id
          : clientId;

      fetchPFIssuers(auth.user.paymentFacilitatorDetails.id, realClientId);
    }
  }, [issuers.pfIssuers, fetchPFIssuers, issuers.pfIssuersLoading, issuers.pfIssuersErrorMessage, auth.user, clientId]);

  const openNewCommerce = () => {
    setSelectedCommerce(null);
    setOpen(true);
  };

  const openCommerce = (commerce) => {
    setSelectedCommerce(commerce);
    setOpen(true);
  };

  const isSuperAdmin = auth.user && auth.user.userType === adminTypes.SUPERADMIN;
  const isPFAdmin = auth.user && auth.user.userType === adminTypes.PFADMIN;
  const canAddCommerces = auth.user && auth.user.clientDetails && auth.user.clientDetails.canCreateAdditionalIssuers;

  if ((!commerces.commerces && !commerces.errorMessage) || commerces.isLoading) {
    return <Loading />;
  } else if (commerces.errorMessage) {
    return <Error message="Ocurrió un error al obtener los comercios" retry={fetchCommerces} />;
  } else {
    return (
      <div className="commerces">
        {!isPFAdmin && (
          <div className="commerces-title">
            <h3>{texts.commerces}</h3>
            {canAddCommerces && (
              <Button color="secondary" variant="contained" onClick={openNewCommerce}>
                {texts.newCommerce}
              </Button>
            )}
          </div>
        )}
        <div className="commerces-list">
          {(isSuperAdmin || isPFAdmin) && (
            <Commerce key="new" position={0} data={null} openCommerce={openNewCommerce} />
          )}
          {commerces.commerces.length === 0 && !clientId ? (
            <div className="empty-list">{texts.noCommercesText}</div>
          ) : (
            commerces.commerces.map((commerce, index) => {
              return (
                <Commerce
                  key={index}
                  auth={auth}
                  issuers={issuers}
                  position={index + 1}
                  data={commerce}
                  openCommerce={openCommerce}
                />
              );
            })
          )}
          {}
        </div>
        <CommerceForm open={open} setOpen={setOpen} selectedCommerce={selectedCommerce} clientId={clientId} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Commerces);

import { httpVerbs, URL } from "./config";
import { baseService } from "./base.service";

export const subscriptorsService = {
  fetchSubscriptors,
  getSubscriptor,
  addCourtesySubscriptor,
  deleteCourtesySubscriptor,
  cancelSubscription,
};

async function fetchSubscriptors(page, limit, filtersString) {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  let subscriptorsUrl = `${URL}/subscriptors?page=${page}&limit=${limit}${filtersString}`;

  return await fetch(subscriptorsUrl, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function getSubscriptor(externalId) {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  return await fetch(`${URL}/subscriptors/${externalId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function addCourtesySubscriptor(courtesySubscriptorRequest) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(courtesySubscriptorRequest),
  };

  return await fetch(`${URL}/subscriptors/courtesy`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function deleteCourtesySubscriptor(externalId, subscriptionTypeId) {
  const requestBody = {
    externalId,
    subscriptionTypeId,
  };

  const requestOptions = {
    method: httpVerbs.Delete,
    headers: baseService.getHeaders(true, externalId),
    body: JSON.stringify(requestBody),
  };

  return await fetch(`${URL}/subscriptors/courtesy`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function cancelSubscription(subscriptionToken, externalId) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true, externalId),
    body: JSON.stringify({ cancelTransaction: true }),
  };

  return await fetch(`${URL}/subscriptors/cancel/${subscriptionToken}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

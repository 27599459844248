import * as ActionTypes from "../config/ActionTypes";
import { subscriptorsService } from "../../services/subscriptors.service";
import { filteringHelper } from "../../helpers/filteringHelper";

const applyCourtesyFilters = ({ filters, search }) => ({
    type: ActionTypes.APPLY_COURTESY_SUBSCRIPTORS_FILTERS,
    payload: { filters, search },
  });

//#region Fetch courtesy subscriptors

export const fetchCourtesySubscriptors = ({ page, limit, filters, search, reset }) => (dispatch, getState) => {
    dispatch(courtesySubscriptorsLoading());

  
    let courtesySubscriptionTypes = getState().subscriptions.subscriptionTypes.filter(
      (st) => !st.regularType
    );

  if(courtesySubscriptionTypes.length > 0)
  {
    if(filters.subscriptionTypes.length === 0){
      filters.subscriptionTypes = courtesySubscriptionTypes;
    }
      
    if(reset) {
        dispatch(applyCourtesyFilters({ filters, search }))
      }
  
    let courtesyFiltersString = filteringHelper.applySubscriptorsFilters(filters);
  
    if(search){
      courtesyFiltersString = courtesyFiltersString.concat(`&subscriptorEmailOrNameOrId=${search}`);
    }
  
  
  
    return subscriptorsService.fetchSubscriptors(page, limit, courtesyFiltersString).then(
      (response) => {
        dispatch(addCourtesySubscriptors(response));
      },
      (error) => {
        dispatch(courtesySubscriptorsFailed(error));
      }
    );
}else{
  dispatch(addCourtesySubscriptors({results: []}))
}
  };
  
  const addCourtesySubscriptors = (subscriptors) => ({
    type: ActionTypes.ADD_COURTESY_SUBSCRIPTORS,
    payload: subscriptors,
  });
  
  const courtesySubscriptorsLoading = () => ({
    type: ActionTypes.COURTESY_SUBSCRIPTORS_LOADING,
  });
  
  const courtesySubscriptorsFailed = (errorMessage) => ({
    type: ActionTypes.COURTESY_SUBSCRIPTORS_FAILED,
    payload: errorMessage,
  });
  
  //#endregion
  
  //#region Create courtesy subscriptor
  
  export const createCourtesySubscriptor = ({ validToDate, subscriptionTypeId, externalId, email, name, fullName }) => (
    dispatch
  ) => {
    const subscriptorRequest = {
      externalId,
      email,
      name,
      fullName,
    };
  
    const courtesySubscriptorRequest = {
      addSubscriptorRequest: subscriptorRequest,
      validToDate,
      subscriptionTypeId,
    };
  
    dispatch(addingCourtesySubscriptor());
  
    return subscriptorsService.addCourtesySubscriptor(courtesySubscriptorRequest).then(
      (response) => {
        dispatch(
          addCourtesySubscriptor({
            subscriptorRequest: courtesySubscriptorRequest,
            subscriptorResponse: response,
          })
        );
      },
      (error) => {
        dispatch(courtesySubscriptorFailed(error));
      }
    );
  };
  
  const addCourtesySubscriptor = ({ subscriptorRequest, subscriptorResponse }) => ({
    type: ActionTypes.ADD_COURTESY_SUBSCRIPTOR,
    payload: { subscriptorRequest, subscriptorResponse },
  });
  
  const addingCourtesySubscriptor = () => ({
    type: ActionTypes.ADDING_COURTESY_SUBSCRIPTOR,
  });
  
  const courtesySubscriptorFailed = (errorMessage) => ({
    type: ActionTypes.ADD_COURTESY_SUBSCRIPTOR_FAILED,
    payload: errorMessage,
  });
  
  //#endregion
  
  //#region Remove courtesy subscriptor
  
  export const removeCourtesySubscriptor = ({ externalId, subscriptionTypeId }) => (dispatch) => {
    dispatch(deletingCourtesySubscriptor());
  
    return subscriptorsService.deleteCourtesySubscriptor(externalId, subscriptionTypeId).then(
      (response) => {
        dispatch(deleteCourtesySubscriptor(externalId));
      },
      (error) => {
        dispatch(deleteCourtesySubscriptorFailed(error));
      }
    );
  };
  
  const deleteCourtesySubscriptor = (externalId) => ({
    type: ActionTypes.DELETE_COURTESY_SUBSCRIPTOR,
    payload: externalId,
  });
  
  const deletingCourtesySubscriptor = () => ({
    type: ActionTypes.DELETING_COURTESY_SUBSCRIPTOR,
  });
  
  const deleteCourtesySubscriptorFailed = (errorMessage) => ({
    type: ActionTypes.DELETE_COURTESY_SUBSCRIPTOR_FAILED,
    payload: errorMessage,
  });
  
  //#endregion
import React from "react";
import { Grow, TextField, Tooltip, InputAdornment, withStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../data/colors";
import { texts } from "../../data/texts";

const styles = (theme) => ({
  errorIcon: {
    color: colors["errorRed"],
    fontSize: 15,
    cursor: "pointer",
  },
  tooltip: {
    backgroundColor: colors["errorRed"],
    fontSize: 15,
    padding: "5px 15px",
  },
  input: {
    fontSize: 15,
    color: colors["notBlack"],
  },
});

function MarkFields({ classes, issuer, submitted, getPlexoFieldData, selectedCommerce }) {
  if (issuer) {
    return (
      <div className="fields">
        {issuer.fields &&
          issuer.fields.map((field, index) => {
            const data = getPlexoFieldData(field.fieldType);

            let theField = selectedCommerce
              ? field.fieldType === 2049
                ? selectedCommerce.commerceNumber
                : selectedCommerce.commerceTerminalNumber
              : "";

            const value = selectedCommerce ? theField : data.value;
            const hasError = submitted && value === "";

            if (data.name !== "") {
              return (
                <Grow in={true} timeout={300 * (index + 1)} key={index}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    error={hasError}
                    style={{ marginTop: 10 }}
                    label={data.name}
                    value={selectedCommerce ? theField : data.value}
                    onChange={(e) => data.setValue(e.target.value)}
                    disabled={selectedCommerce !== null}
                    InputProps={{
                      classes: { input: classes.input },
                      endAdornment: hasError ? (
                        <Tooltip title={texts.requiredField} classes={{ tooltip: classes.tooltip }}>
                          <InputAdornment position="end">
                            <FontAwesomeIcon icon="exclamation-circle" className={classes.errorIcon} />
                          </InputAdornment>
                        </Tooltip>
                      ) : null,
                    }}
                  />
                </Grow>
              );
            } else {
              return null;
            }
          })}
      </div>
    );
  } else {
    return <span />;
  }
}

export default withStyles(styles)(MarkFields);

export const stringHelper = {
  getMaskedInstrument,
};

function getMaskedInstrument(instrumentName) {
  if (instrumentName) {
    const length = instrumentName.length;
    return `**** ${instrumentName.substr(length - 4, 4)}`;
  }
}

import React from "react";
import { texts } from "../../data/texts";

function ClientsTitle() {
  return (
    <div className="client-title">
      <div className="column">{texts.name}</div>
      <div className="column">{texts.legalName}</div>
      <div className="column">{texts.admin}</div>
      <div className="column">{texts.actions}</div>
    </div>
  );
}

export default ClientsTitle;

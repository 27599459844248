import React from "react";
import { Route, Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import { adminTypes } from "../../data/adminTypes";

function PrivateRoute({ cookies, component: Component, roles, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentRole = cookies.cookies.userType;

        if (!currentRole) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        }

        if (roles && roles.indexOf(Number(currentRole)) === -1) {
          if (Number(currentRole) === adminTypes.CLIENTADMIN) {
            return <Redirect to={{ pathname: "/dashboard" }} />;
          }

          return <Redirect to={{ pathname: "/clients" }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default withCookies(PrivateRoute);

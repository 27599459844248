import * as ActionTypes from "../config/ActionTypes";

export const initialState = {
  isLoading: false,
  errorMessage: null,
  clients: null,
  creatingClient: false,
  createClientErrorMessage: null,
  createClientSuccess: false,
  updatingClient: false,
  updateClientErrorMessage: null,
  updateClientSuccess: false,
  deletingClient: false,
  deleteClientErrorMessage: null,
  deleteClientSuccess: false,
  updatingClientSecret: false,
  updateClientSecretErrorMessage: null,
  updateClientSecretSuccess: false,
  updatingClientNotifications: false,
  updateClientNotificationsErrorMessage: null,
  updateClientNotificationsSuccess: false,
};

export const ClientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CLIENTS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        clients: action.payload,
      };

    case ActionTypes.CLIENTS_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        clients: null,
      };

    case ActionTypes.CLIENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
        clients: null,
      };

    case ActionTypes.ADD_CLIENT:
      let newClients = state.clients || [];
      newClients.push(action.payload);

      return {
        ...state,
        creatingClient: false,
        createClientErrorMessage: null,
        createClientSuccess: true,
        clients: newClients,
      };

    case ActionTypes.CREATING_CLIENT:
      return {
        ...state,
        creatingClient: true,
        createClientErrorMessage: null,
        createClientSuccess: false,
      };

    case ActionTypes.CREATE_CLIENT_FAILED:
      return {
        ...state,
        creatingClient: false,
        createClientErrorMessage: action.payload,
        createClientSuccess: false,
      };

    case ActionTypes.UPDATE_CLIENT:
      let updatedClients = state.clients.map((item, index) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        return {
          ...action.payload,
        };
      });

      return {
        ...state,
        updatingClient: false,
        updateClientErrorMessage: null,
        updateClientSuccess: true,
        clients: updatedClients,
      };

    case ActionTypes.UPDATING_CLIENT:
      return {
        ...state,
        updatingClient: true,
        updateClientErrorMessage: null,
        updateClientSuccess: false,
      };

    case ActionTypes.UPDATE_CLIENT_FAILED:
      return {
        ...state,
        updatingClient: false,
        updateClientErrorMessage: action.payload,
        updateClientSuccess: false,
      };

    case ActionTypes.DELETE_CLIENT:
      let nonDeletedClients = state.clients.filter((item, index) => {
        if (item.id !== action.payload) {
          return item;
        }
        return null;
      });

      return {
        ...state,
        deletingClient: false,
        deleteClientErrorMessage: null,
        deleteClientSuccess: true,
        clients: nonDeletedClients,
      };

    case ActionTypes.DELETING_CLIENT:
      return {
        ...state,
        deletingClient: true,
        deleteClientErrorMessage: null,
        deleteClientSuccess: false,
      };

    case ActionTypes.DELETE_CLIENT_FAILED:
      return {
        ...state,
        deletingClient: false,
        deleteClientErrorMessage: action.payload,
        deleteClientSuccess: false,
      };

    case ActionTypes.UPDATE_CLIENT_SECRET_SUCCESS:
      return {
        ...state,
        updatingClientSecret: false,
        updateClientSecretErrorMessage: null,
        updateClientSecretSuccess: true,
      };

    case ActionTypes.UPDATING_CLIENT_SECRET:
      return {
        ...state,
        updatingClientSecret: true,
        updateClientSecretErrorMessage: null,
        updateClientSecretSuccess: false,
      };

    case ActionTypes.UPDATE_CLIENT_SECRET_FAILED:
      return {
        ...state,
        updatingClientSecret: false,
        updateClientSecretErrorMessage: action.payload,
        updateClientSecretSuccess: false,
      };

    case ActionTypes.UPDATING_CLIENT_NOTIFICATIONS:
      return {
        ...state,
        updatingClientNotifications: true,
        updateClientNotificationsErrorMessage: null,
        updateClientNotificationsSuccess: false,
      };

    case ActionTypes.UPDATE_CLIENT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        updatingClientNotifications: false,
        updateClientNotificationsErrorMessage: null,
        updateClientNotificationsSuccess: true,
      };

    case ActionTypes.UPDATE_CLIENT_NOTIFICATIONS_FAILED:
      return {
        ...state,
        updatingClientNotifications: false,
        updateClientNotificationsErrorMessage: action.payload,
        updateClientNotificationsSuccess: false,
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

import { httpVerbs, URL } from "./config";
import { baseService } from "./base.service";

export const transactionsService = {
  fetchTransactions,
};

async function fetchTransactions(page, limit, filtersString, clientId) {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  if (clientId) {
    requestOptions.headers.append("clientId", clientId);
  }

  return await fetch(`${URL}/transactions?page=${page}&limit=${limit}${filtersString}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

import { adminTypes } from "./adminTypes";
import { texts } from "./texts";

const navigation = [
  {
    name: texts.dashboard,
    to: "/dashboard",
    icon: "home",
    roles: [adminTypes.CLIENTADMIN],
  },
  {
    name: texts.clients,
    to: "/clients",
    icon: "address-card",
    roles: [adminTypes.SUPERADMIN, adminTypes.PFADMIN],
  },
  {
    name: texts.paymentFacilitators,
    to: "/facilitators",
    icon: "plug",
    roles: [adminTypes.SUPERADMIN],
  },
  {
    name: texts.issuers,
    to: "/issuers",
    icon: "credit-card",
    roles: [adminTypes.SUPERADMIN],
  },
  {
    name: texts.paymentFacilitatorIssuers,
    to: "/pfIssuers",
    icon: "credit-card",
    roles: [adminTypes.PFADMIN],
  },
  {
    name: texts.configuration,
    to: "/configuration",
    icon: "cog",
    roles: [adminTypes.SUPERADMIN, adminTypes.CLIENTADMIN, adminTypes.PFADMIN],
  },
  {
    name: texts.subscriptionTypes,
    to: "/subscriptions",
    icon: "balance-scale",
    roles: [adminTypes.CLIENTADMIN],
  },
  {
    name: texts.commerceCodes,
    to: "/commerces",
    icon: "cash-register",
    roles: [adminTypes.CLIENTADMIN],
  },
  {
    name: texts.subscriptors,
    to: "/subscriptors",
    icon: "users",
    roles: [adminTypes.CLIENTADMIN],
  },
  {
    name: texts.transactions,
    to: "/transactions",
    icon: "piggy-bank",
    roles: [adminTypes.CLIENTADMIN],
  },
  {
    name: texts.courtesySubscriptors,
    to: "/courtesy",
    icon: "gift",
    roles: [adminTypes.CLIENTADMIN],
  },
];

export { navigation };

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Switch,
  Chip,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import generator from "generate-password";
import React, { useState } from "react";
import { connect } from "react-redux";
import schemas from "../../data/schemas";
import { updateClient } from "../../store/action_creators/clients.actions";
import { register } from "../../store/action_creators/auth.actions";
import FormTextField from "../common/forms/FormTextField";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { adminTypes } from "../../data/adminTypes";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  updateClient: (updateClientRequest) => {
    dispatch(updateClient(updateClientRequest));
  },
  register: (clientRequest, registerRequest) => {
    dispatch(register(clientRequest, registerRequest));
  },
});

function ClientForm({ open, setOpen, selectedClient, auth, updateClient, register }) {
  const generateSecretText = () => {
    return generator.generate({ length: 12, numbers: true, uppercase: true });
  };
  const isNew = selectedClient === undefined;
  const isPFAdmin = auth.user && auth.user.paymentFacilitatorDetails !== null;

  const [secret, setSecret] = useState(generateSecretText);
  const [invoicingActivated, setInvoicingActivated] = useState(
    selectedClient ? selectedClient.electronicBillingEnabled : false
  );
  const [addCommercesAllowed, setAddCommercesAllowed] = useState(
    selectedClient && selectedClient.paymentFacilitatorConfiguration
      ? selectedClient.paymentFacilitatorConfiguration.canCreateAdditionalIssuers
      : true
  );
  const [selectedCommerce, setSelectedCommerce] = useState(0);
  const [commerceError, setCommerceError] = useState(false);

  const handleInvoicingToggle = (e) => {
    setInvoicingActivated(e.target.checked);
  };

  const handleCommercesToggle = (e) => {
    setAddCommercesAllowed(e.target.checked);
  };

  const generateSecret = () => {
    setSecret(generateSecretText);
  };

  const submitClient = (values, { setSubmitting }) => {
    if (isNew) {
      if (selectedCommerce !== 0 || !isPFAdmin) {
        const registerRequest = {
          username: values.username,
          password: values.password,
          userType: adminTypes.CLIENTADMIN,
        };

        const clientRequest = {
          clientName: values.name,
          clientLegalId: values.clientLegalId,
          clientLegalName: values.clientLegalName,
          clientSecret: secret,
          pfId: auth.user && auth.user.paymentFacilitatorDetails ? auth.user.paymentFacilitatorDetails.id : "",
          pfCommerceId: selectedCommerce,
          canAddIssuers: addCommercesAllowed,
        };

        register(clientRequest, registerRequest);
        setOpen(false);
      } else {
        setCommerceError(true);
      }
    } else {
      const updateClientRequest = {
        clientId: selectedClient.id,
        clientName: values.name,
        clientLegalId: values.clientLegalId,
        clientLegalName: values.clientLegalName,
        pfId: selectedClient.pfId,
        paymentFacilitatorCommerceId:
          selectedCommerce !== 0
            ? selectedCommerce
            : selectedClient.paymentFacilitatorConfiguration
            ? selectedClient.paymentFacilitatorConfiguration.pfCommerceId
            : 0,
        adminUserId: selectedClient.admin.userId,
        electronicBillingEnabled: invoicingActivated,
        canCreateAdditionalIssuers: addCommercesAllowed,
      };

      updateClient(updateClientRequest);
      setOpen(false);
    }

    setSubmitting(false);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <Formik
        initialValues={{
          name: selectedClient ? selectedClient.name : "",
          clientLegalId: selectedClient ? selectedClient.clientLegalId : "",
          clientLegalName: selectedClient ? selectedClient.clientLegalName : "",
          userId: selectedClient ? selectedClient.admin.userId : "",
          username: selectedClient ? selectedClient.admin.userName : "",
          password: "",
          invoicingActivated: selectedClient ? selectedClient.electronicBillingEnabled : false,
        }}
        validationSchema={selectedClient ? schemas.UpdateClientSchema : schemas.NewClientSchema}
        onSubmit={submitClient}
      >
        {({ values, isSubmitting }) => (
          <Form className="form">
            <DialogTitle>{selectedClient ? `Editar ${selectedClient.name}` : texts.newClient}</DialogTitle>
            <DialogContent>
              <Field label="Nombre" name="name" component={FormTextField} />
              <Field label="RUT o CFDI" name="clientLegalId" component={FormTextField} />
              <Field label="Nombre legal" name="clientLegalName" component={FormTextField} />
              {isPFAdmin && auth.user.paymentFacilitatorDetails && (
                <>
                  <div className="commerce-subtitle">
                    <Typography style={{ marginTop: 8 }}>{texts.assignedCommerceId}</Typography>
                    {commerceError && <span className="error">{texts.commerceIdError}</span>}
                  </div>
                  {auth.user.paymentFacilitatorDetails.paymentFacilitatorCommerceIds.map((c) => {
                    const isSelected =
                      selectedClient && selectedClient.paymentFacilitatorConfiguration && selectedCommerce === 0
                        ? selectedClient.paymentFacilitatorConfiguration.pfCommerceId === c
                        : false;

                    return (
                      <Chip
                        key={c}
                        label={c}
                        style={{ marginRight: 8, marginTop: 4, marginBottom: 8, paddingLeft: 8, paddingRight: 8 }}
                        clickable
                        color={isSelected ? "secondary" : selectedCommerce === c ? "secondary" : "default"}
                        onClick={() => setSelectedCommerce(c)}
                      />
                    );
                  })}
                  <div>
                    <label>{texts.allowAddCommerces}</label>
                    <Field
                      label={texts.allowAddCommerces}
                      name="addCommercesAllowed"
                      component={Switch}
                      checked={addCommercesAllowed}
                      onChange={handleCommercesToggle}
                    />
                  </div>
                </>
              )}
              {isNew && (
                <div className="form-secret">
                  <label>
                    <span>{texts.clientSecret}</span> {secret}
                  </label>
                  <IconButton onClick={generateSecret} color="primary" variant="outlined">
                    <AutorenewIcon />
                  </IconButton>
                </div>
              )}
              {isNew && <Typography>{texts.adminData}</Typography>}
              <Field
                label={isNew ? texts.user : texts.admin}
                name="username"
                component={FormTextField}
                disabled={!isNew}
              />
              {isNew && <Field label="Contraseña" name="password" type="password" component={FormTextField} />}
              {!isNew && (
                <div>
                  <label>{texts.activateElectronicBilling}</label>
                  <Field
                    label={texts.activateElectronicBilling}
                    name="invoicingActivated"
                    component={Switch}
                    checked={invoicingActivated}
                    onChange={handleInvoicingToggle}
                  />
                </div>
              )}
              {!isNew && <div className="form-secret">{`ClientId: ${selectedClient.id}`}</div>}
            </DialogContent>
            <DialogActions>
              <Button className="save" variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                {texts.save}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientForm);

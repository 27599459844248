import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import CourtesySubscriptorForm from "./CourtesySubscriptorForm";
import { fetchCourtesySubscriptors } from "../../store/action_creators/courtesySubscriptors.actions";
import { fetchSubscriptions } from "../../store/action_creators/subscriptions.actions";
import { constants } from "../../data/constants";
import Loading from "../common/animations/Loading";
import Error from "../common/animations/Error";
import SubscriptorRow from "../subscriptors/SubscriptorRow";
import SubscriptorTitle from "../subscriptors/SubscriptorTitle";
import CourtesySubscriptorsFilters from "./CourtesySubscriptorsFilters";
import Search from "../common/Search";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    subscriptors: state.courtesySubscriptors,
    subscriptions: state.subscriptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCourtesySubscriptors: ({ page, limit, filters, search }) => {
    dispatch(fetchCourtesySubscriptors({ page, limit, filters, search }));
  },
  fetchSubscriptions: () => {
    dispatch(fetchSubscriptions());
  },
});

function CourtesySubscriptors({ subscriptors, subscriptions, fetchCourtesySubscriptors, fetchSubscriptions }) {
  const [open, setOpen] = useState(false);
  const [searchTerms, setSearchTerms] = useState(subscriptors.search);
  useEffect(() => {
    if (!subscriptions.subscriptionTypes) {
      fetchSubscriptions();
    }

    if (
      !subscriptors.courtesySubscriptorsLoading &&
      subscriptors.courtesySubscriptorsErrorMessage === null &&
      !subscriptors.courtesySubscriptors &&
      subscriptions.subscriptionTypes
    ) {
      fetchCourtesySubscriptors({
        page: 1,
        limit: 1000,
        filters: subscriptors.filters,
      });
    }
  }, [
    subscriptions.subscriptionTypes,
    subscriptors.courtesySubscriptors,
    subscriptors.courtesySubscriptorsLoading,
    subscriptors.courtesySubscriptorsErrorMessage,
    subscriptors.filters,
    fetchSubscriptions,
    fetchCourtesySubscriptors,
  ]);

  const search = (value) => {
    fetchCourtesySubscriptors({
      page: 1,
      limit: constants.subscriptorsLimit,
      filters: subscriptors.filters,
      search: value,
      reset: true,
    });
  };

  return (
    <div className="subscriptors">
      <div className="courtesy-subscriptors-title">
        <h3>{texts.courtesySubscriptors}</h3>
        <Button color="secondary" variant="contained" onClick={() => setOpen(true)}>
          {texts.newCourtesySubscriptor}
        </Button>
      </div>
      <CourtesySubscriptorsFilters />
      <div className="subscriptors-search">
        <Search value={searchTerms} setValue={setSearchTerms} searchFunction={search} />
      </div>
      {(!subscriptors.courtesySubscriptors && !subscriptors.courtesySubscriptorsErrorMessage) ||
      (subscriptors.courtesySubscriptorsLoading && subscriptors.courtesySubscriptors.length === 0) ? (
        <Loading />
      ) : subscriptors.courtesySubscriptorsErrorMessage ? (
        <Error message={texts.courtesySubscriptorsError} retry={fetchCourtesySubscriptors} />
      ) : subscriptors.courtesySubscriptors.length <= 0 && subscriptors.filters.subscriptionTypes.length <= 0 ? (
        <div className="empty-list">{texts.noCourtesySubscriptorsText}</div>
      ) : (
        <>
          <div className="subscriptors-list">
            <SubscriptorTitle isCourtesy />
            {subscriptors.courtesySubscriptors && subscriptors.courtesySubscriptors.length > 0 ? (
              subscriptors.courtesySubscriptors.map((subscriptor, index) => {
                return (
                  <SubscriptorRow
                    key={index}
                    position={index}
                    data={subscriptor}
                    limit={constants.subscriptorsLimit}
                    isCourtesy
                  />
                );
              })
            ) : (
              <div className="empty-list">{texts.noCourtesySubscriptorsForFilterText}</div>
            )}
          </div>
        </>
      )}
      <CourtesySubscriptorForm open={open} setOpen={setOpen} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CourtesySubscriptors);

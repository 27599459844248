import React from "react";
import { Grow } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { texts } from "../../data/texts";

function ClientRow({ position, data }) {
  const history = useHistory();

  return (
    <Grow in={true} direction="left" timeout={300 * position}>
      <div className="client-row">
        <div className="column">{data.name}</div>
        <div className="column">{data.clientLegalName}</div>
        <div className="column">{data.admin.userName}</div>
        <div className="column">
          <span className="blue-link" onClick={() => history.push(`clients/${data.id}`)}>
            {texts.seeDetails}
          </span>
        </div>
      </div>
    </Grow>
  );
}

export default ClientRow;

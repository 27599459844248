import React from "react";
import { Grow } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { texts } from "../../data/texts";

function Commerce({ auth, issuers, position, data, openCommerce }) {
  const isPF = auth && auth.user && auth.user.paymentFacilitatorDetails !== null;
  const isPFIssuer =
    isPF &&
    data.issuer &&
    issuers &&
    issuers.pfIssuers &&
    issuers.pfIssuers.find((pfi) => pfi.issuer.id === data.issuer.id);

  return (
    <Grow in={true} timeout={300 * position}>
      <div
        className={`commerce-container ${data === null ? "new-commerce-container" : ""}`}
        onClick={() => openCommerce(data)}
      >
        <div className="commerce">
          {data ? (
            <div className="name-line">
              <img src={data.issuer.issuerImageUrl} alt="issuer" />
              <span className="name">{data ? data.name : texts.newCommerce}</span>
            </div>
          ) : (
            <div className="new-commerce">
              <AddIcon />
              <span>{texts.newCommerce}</span>
            </div>
          )}
          {data && data.commerceNumber !== "" && !isPFIssuer && (
            <span className="description">
              {texts.commerceNumber}: {data.commerceNumber}
            </span>
          )}
          {data && data.commerceTerminalNumber !== "" && !isPFIssuer && (
            <span className="description">
              {texts.terminalNumber}: {data.commerceTerminalNumber}
            </span>
          )}
        </div>
      </div>
    </Grow>
  );
}

export default Commerce;

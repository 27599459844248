import * as ActionTypes from "../config/ActionTypes";
import { transactionsService } from "../../services/transactions.service";
import { filteringHelper } from "../../helpers/filteringHelper";

//#region Fetch transactions

export const fetchTransactions =
  ({ page, limit, filters, reset, clientId }) =>
  (dispatch) => {
    dispatch(transactionsLoading(clientId));

    if (reset) {
      dispatch(applyFilters(filters));
    }

    let filtersString = filteringHelper.applyTransactionsFilters(filters);

    return transactionsService.fetchTransactions(page, limit, filtersString, clientId).then(
      (response) => {
        dispatch(addTransactions(response));
      },
      (error) => {
        dispatch(transactionsFailed(error));
      }
    );
  };

const applyFilters = (filters) => ({
  type: ActionTypes.APPLY_FILTERS,
  payload: filters,
});

const addTransactions = (transactions) => ({
  type: ActionTypes.ADD_TRANSACTIONS,
  payload: transactions,
});

const transactionsLoading = (clientId) => ({
  type: ActionTypes.TRANSACTIONS_LOADING,
  payload: clientId,
});

const transactionsFailed = (errorMessage) => ({
  type: ActionTypes.TRANSACTIONS_FAILED,
  payload: errorMessage,
});

//#endregion

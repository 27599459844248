import React, { useState } from "react";
import { connect } from "react-redux";
import { Grow, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IssuerForm from "./IssuerForm";
import { deleteIssuer } from "../../store/action_creators/issuers.actions";
import DeleteDialog from "../common/dialogs/DeleteDialog";

const mapDispatchToProps = (dispatch) => ({
  deleteIssuer: ({ issuerId }) => {
    dispatch(
      deleteIssuer({
        issuerId,
      })
    );
  },
});

function Issuer({ position, data, deleteIssuer }) {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const submitDelete = () => {
    deleteIssuer({ issuerId: data.id });
    setDeleteOpen(false);
  };

  return (
    <Grow in={true} timeout={300 * position}>
      <div className="issuer-container">
        <div className="issuer">
          <div className="data">
            <img src={data.issuerImageUrl} alt="issuer" />
            <span className="name">{data.name}</span>
          </div>
          <div className="actions">
            <IconButton onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon="edit" className="edit-icon" />
            </IconButton>
            <IconButton onClick={() => setDeleteOpen(true)}>
              <FontAwesomeIcon icon="trash" className="delete-icon" />
            </IconButton>
          </div>
        </div>
        <IssuerForm open={open} setOpen={setOpen} selectedIssuer={data} />
        <DeleteDialog
          open={deleteOpen}
          setOpen={setDeleteOpen}
          text={`Seguro que deseas borrar el issuer ${data.name}?`}
          submit={submitDelete}
        />
      </div>
    </Grow>
  );
}

export default connect(null, mapDispatchToProps)(Issuer);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchClients } from "../../store/action_creators/clients.actions";
import { Button } from "@material-ui/core";
import ClientRow from "./ClientRow";
import ClientForm from "./ClientForm";
import Loading from "../common/animations/Loading";
import Error from "../common/animations/Error";
import CustomSnackbar from "../common/CustomSnackbar";
import ClientsTitle from "./ClientsTitle";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchClients: () => {
    dispatch(fetchClients());
  },
});

function Clients({ clients, users, fetchClients }) {
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(false);

  const closeDeleteSnack = () => {
    setDeleting(false);
  };

  const closeCreateSnack = () => {
    setCreating(false);
  };

  const closeUpdateSnack = () => {
    setUpdating(false);
  };

  useEffect(() => {
    if (!clients.clients) {
      fetchClients();
    }
  }, [clients.clients, fetchClients]);

  useEffect(() => {
    if (clients.deletingClient) {
      setDeleting(true);
    }
  }, [clients.deletingClient]);

  useEffect(() => {
    if (clients.updatingClient) {
      setUpdating(true);
    }
  }, [clients.updatingClient]);

  useEffect(() => {
    if (clients.creatingClient || users.addingUser) {
      setCreating(true);
    }
  }, [clients.creatingClient, users.addingUser]);

  if (
    (!clients.clients && !clients.errorMessage) ||
    clients.isLoading ||
    clients.deletingClient ||
    clients.creatingClient
  ) {
    return <Loading />;
  } else if (clients.errorMessage) {
    return <Error message={texts.fetchClientsError} retry={fetchClients} />;
  } else {
    return (
      <div className="clients">
        <div className="clients-title">
          <h3>{texts.clients}</h3>
          <Button color="secondary" variant="contained" onClick={() => setOpen(true)}>
            {texts.newClient}
          </Button>
        </div>
        <div className="clients-list">
          <ClientsTitle />
          {clients.clients &&
            clients.clients.map((client, index) => {
              return <ClientRow key={index} position={index} data={client} />;
            })}
        </div>
        <ClientForm open={open} setOpen={setOpen} />
        <CustomSnackbar
          open={deleting && (clients.deleteClientSuccess || clients.deleteClientErrorMessage)}
          message={clients.deleteClientSuccess ? texts.clientDeletionSuccess : texts.clientDeletionError}
          handleClose={closeDeleteSnack}
          type={clients.deleteClientSuccess ? 0 : 1}
        />
        <CustomSnackbar
          open={creating && (clients.createClientSuccess || clients.createClientErrorMessage)}
          message={
            clients.createClientSuccess
              ? texts.clientCreationSuccess
              : texts.clientCreationError + clients.createClientErrorMessage
          }
          handleClose={closeCreateSnack}
          type={clients.createClientSuccess ? 0 : 1}
        />
        <CustomSnackbar
          open={updating && (clients.updateClientSuccess || clients.updateClientErrorMessage)}
          message={
            clients.updateClientSuccess
              ? texts.clientEditionSuccess
              : texts.clientEditionError + clients.updateClientErrorMessage
          }
          handleClose={closeUpdateSnack}
          type={clients.updateClientSuccess ? 0 : 1}
        />
        <CustomSnackbar
          open={creating && users.addUserErrorMessage}
          message={`${texts.clientAdminCreationError} ${users.addUserErrorMessage}`}
          handleClose={closeCreateSnack}
          type={1}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients);

import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { texts } from "../../../data/texts";

function LogoutDialog({ open, setOpen, submit }) {
  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
      <DialogTitle>{texts.logout}</DialogTitle>
      <DialogContent>{texts.logoutQuestion}</DialogContent>
      <DialogActions>
        <Button
          className="save"
          color="primary"
          variant="contained"
          onClick={submit}
          style={{
            margin: "0 15px 15px 0",
            fontSize: 16,
          }}
        >
          {texts.logout}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LogoutDialog;

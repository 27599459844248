import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withStyles,
  IconButton,
  Typography,
} from "@material-ui/core";
import generator from "generate-password";
import { updateClientSecret } from "../../../store/action_creators/clients.actions";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CloseIcon from "@material-ui/icons/Close";
import CustomSnackbar from "../CustomSnackbar";
import { texts } from "../../../data/texts";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  button: {
    margin: 15,
    width: 200,
    fontSize: 16,
  },
  clientSecret: {
    padding: "0.3rem 1rem",
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.grey["200"],
  },
  title: {
    padding: "0.3rem",
    paddingLeft: "24px",
    "& h2": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
});

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateClientSecret: ({ clientSecret, clientId }) => {
    dispatch(updateClientSecret({ clientSecret, clientId }));
  },
});

function SecretDialog({ classes, open, setOpen, selectedClient, auth, clients, updateClientSecret }) {
  const generateSecretText = () => {
    return generator.generate({ length: 12, numbers: true, uppercase: true });
  };

  const [secret, setSecret] = useState(generateSecretText);
  const [updating, setUpdating] = useState(false);

  const generateSecret = () => {
    setSecret(generateSecretText);
  };

  const submitSecret = () => {
    setUpdating(true);
    updateClientSecret({
      clientSecret: secret,
      clientId: selectedClient ? selectedClient.id : auth.user?.clientDetails.id,
    });
  };

  const closeSnack = () => {
    setUpdating(false);

    if (clients.updateClientSecretSuccess) {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
      <DialogTitle className={classes.title}>
        {texts.generateClientSecret}
        <IconButton onClick={() => setOpen(false)} color="primary" variant="outlined">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography>{texts.newClientSecret}</Typography>
        <div className={classes.clientSecret}>
          <label>{secret}</label>
          <IconButton onClick={generateSecret} color="primary" variant="outlined">
            <AutorenewIcon />
          </IconButton>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={() => setOpen(false)} className={classes.button} color="secondary" variant="outlined">
          {texts.cancel}
        </Button>
        <Button onClick={submitSecret} className={classes.button} color="secondary" variant="contained">
          {texts.confirm}
        </Button>
      </DialogActions>
      <CustomSnackbar
        open={updating && (clients.updateClientSecretSuccess || clients.updateClientSecretErrorMessage)}
        message={clients.updateClientSecretSuccess ? texts.clientSecretUpdateSuccess : texts.clientSecretUpdateError}
        handleClose={closeSnack}
        type={clients.updateClientSecretSuccess ? 0 : 1}
      />
    </Dialog>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SecretDialog));

import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { useEffect } from "react";
import { subscriptorsService } from "../../services/subscriptors.service";
import SubscriptionRow from "./SubscriptionRow";
import Loading from "../common/animations/Loading";
import { texts } from "../../data/texts";

function SubscriptorDialog({ open, setOpen, data }) {
  const [userData, setUserData] = useState(null);
  const [subscriptionOpened, setSubscriptionOpened] = useState(null);
  const [gettingSubscriptor, setGettingSubscriptor] = useState(false);

  useEffect(() => {
    if (open) {
      setGettingSubscriptor(true);
      subscriptorsService.getSubscriptor(data.externalId).then(
        (response) => {
          setUserData(response);
          setGettingSubscriptor(false);
        },
        (error) => {
          console.log(error);
          setGettingSubscriptor(false);
        }
      );
    }
  }, [data, open]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={() => setOpen(false)}>
      <DialogTitle>{`Suscriptor ${data.externalId} - Suscripciones`}</DialogTitle>
      <DialogContent classes={{ root: "subscriptor-modal" }}>
        {userData && userData.subscriptions && userData.subscriptions.length > 0 ? (
          userData.subscriptions.map((s) => {
            return (
              <SubscriptionRow
                key={s.id}
                subscription={s}
                userData={userData}
                subscriptionOpened={subscriptionOpened}
                setSubscriptionOpened={setSubscriptionOpened}
              />
            );
          })
        ) : gettingSubscriptor ? (
          <Loading size={100} />
        ) : (
          <div className="empty-list">{texts.noSubscriptionsForSubscriptor}</div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default SubscriptorDialog;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../common/animations/Loading";
import { fetchIssuers } from "../../store/action_creators/issuers.actions";
import { Select, InputLabel, FormControl, MenuItem, OutlinedInput } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { adminTypes } from "../../data/adminTypes";
import { texts } from "../../data/texts";

const styles = (theme) => ({
  formControl: {
    marginTop: 8,
    marginBottom: 4,
  },
});

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    commerces: state.commerces,
    issuers: state.issuers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchIssuers: (clientId) => {
    dispatch(fetchIssuers(clientId));
  },
});

function MarkControl({ classes, auth, commerces, issuers, fetchIssuers, issuer, setIssuer, selectedCommerce }) {
  const [selectedIssuer, setSelectedIssuer] = useState(0);
  const [availableIssuers, setAvailableIssuers] = useState([]);

  useEffect(() => {
    const clientId =
      auth.user.userType === adminTypes.CLIENTADMIN && auth.user.clientDetails ? auth.user.clientDetails.id : null;

    if (!issuers.issuers) {
      fetchIssuers(clientId);
    }
  }, [issuers.issuers, fetchIssuers, auth.user]);

  useEffect(() => {
    if (issuers.issuers) {
      const filteredIssuers = issuers.issuers.filter((issuer) => {
        if (commerces.commerces) {
          return commerces.commerces.filter((c) => c.issuer.name === issuer.name).length === 0;
        }
        if (issuers.pfIssuers) {
          return issuers.pfIssuers.filter((pf) => pf.issuer.name === issuer.name).length === 0;
        }
        return true;
      });

      setAvailableIssuers(selectedCommerce ? issuers.issuers : filteredIssuers);

      let selected = selectedCommerce
        ? issuers.issuers.filter((i) => Number(i.plexoId) === Number(selectedCommerce.issuer.plexoId))[0]
        : filteredIssuers[0];

      if (selected) {
        setIssuer(selected);
        setSelectedIssuer(selected.plexoId);
      }
    }
  }, [selectedCommerce, issuers.issuers, issuers.pfIssuers, setIssuer, setSelectedIssuer, commerces.commerces]);

  const issuerChange = (e) => {
    let selected = issuers.issuers.filter((i) => Number(i.plexoId) === Number(e.target.value))[0];

    setIssuer(selected);
  };

  if (!issuers.issuers) {
    return <Loading size={40} />;
  } else {
    return (
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <InputLabel htmlFor="issuer-selector">{texts.paymentMethod}</InputLabel>
        <Select
          value={selectedCommerce ? selectedIssuer : issuer ? issuer.plexoId : 0}
          onChange={issuerChange}
          input={<OutlinedInput labelWidth={100} id="issuer-selector" variant="outlined" />}
          disabled={selectedCommerce !== null}
        >
          {availableIssuers &&
            availableIssuers.map((opt, index) => {
              return (
                <MenuItem key={index} value={opt.plexoId}>
                  {opt.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MarkControl));

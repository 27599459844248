import React, { useState } from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { cancelSubscription } from "../../store/action_creators/subscriptors.actions";
import CustomSnackbar from "../common/CustomSnackbar";
import { status } from "../../data/status";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => ({
  subscriptors: state.subscriptors,
});

const mapDispatchToProps = (dispatch) => ({
  cancelSubscription: ({ subscriptionToken, externalId }) => {
    dispatch(cancelSubscription({ subscriptionToken, externalId }));
  },
});

function CancelSubscription({ subscriptors, cancelSubscription, subscription, userData }) {
  const [open, setOpen] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  const cancelOk = subscriptors.cancelSubscriptionSuccess;

  const submitCancelSubscription = () => {
    setCancelling(true);

    cancelSubscription({
      subscriptionToken: subscription.token,
      externalId: userData.externalId,
    });
  };

  const closeSnack = () => {
    setCancelling(false);
    setOpen(false);
  };

  return (
    <>
      {subscription.subscriptionStatus === status.OK ? (
        <span className="blue-link" onClick={() => setOpen(true)}>
          {texts.cancelRenew}
        </span>
      ) : null}
      <Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
        <DialogTitle>{`Cancelar suscripción ${subscription.subscriptionType?.name}`}</DialogTitle>
        <DialogContent>{`¿Seguro que deseas cancelar la suscripción ${subscription.subscriptionType?.name} para el suscriptor ${userData.fullName}?`}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{
              margin: "0 15px 15px 0",
              backgroundColor: "#c54343",
              color: "#FFF",
            }}
            onClick={submitCancelSubscription}
          >
            {texts.cancel}
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={cancelling && (subscriptors.cancelSubscriptionSuccess || subscriptors.cancelSubscriptionErrorMessage)}
        message={cancelOk ? texts.cancelSubscriptionSuccess : texts.cancelSubscriptionError}
        handleClose={closeSnack}
        type={cancelOk ? 0 : 1}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelSubscription);

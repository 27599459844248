import moment from "moment";
import { status } from "../data/status";
import { statusHelper } from "./statusHelper";

export const xlsHelper = {
  getTransactionsXLS,
  getSubscriptorsXLS,
};

function getTransactionsXLS(transactions) {
  if (transactions) {
    let newData = transactions.map((t) => {
      return {
        Fecha: moment(t.createdAt).format("DD/MM/YYYY HH:mm"),
        Suscripción: t.subscription && t.subscription.subscriptionType ? t.subscription.subscriptionType.name : "",
        Sello: t.instrument ? t.instrument.issuerName : "",
        Instrumento: t.instrument ? t.instrument.name : "",
        "Nombre suscriptor": t.subscriptor ? t.subscriptor.fullName : "",
        "Id externo suscriptor": t.subscriptor ? t.subscriptor.externalId : "",
        Importe: t.amount,

        Estado: `${statusHelper.getStatusName(t.state)} ${
          t.state === status.FAILED && t.errorText !== null ? `(${t.errorText})` : ""
        }`,
        "No. de factura": t.electronicBill !== null ? t.electronicBill.internalId : "-",
        "Plexo ID": t.plexoTransactionId,
        "Código autorización": t.issuerAuthCode,
      };
    });

    return newData;
  } else {
    return [];
  }
}

function getSubscriptorsXLS(subscriptors) {
  if (subscriptors) {
    let newData = subscriptors.map((s) => {
      return {
        "Id externo": s.externalId,
        "Fecha de alta": moment(s.firstSubscribed).format("DD/MM/YYYY"),
        "Nombre completo": s.fullName,
        Email: s.email,
      };
    });

    return newData;
  } else {
    return [];
  }
}

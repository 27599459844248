import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { payOldSubscriptions } from "../../store/action_creators/auth.actions";
import CustomSnackbar from "../common/CustomSnackbar";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  payOldSubscriptions: () => {
    dispatch(payOldSubscriptions());
  },
});

function ConfigurationSuperAdmin({ payOldSubscriptions, auth }) {
  const [payingOldSubscriptions, setPayingOldSubscriptions] = useState(false);

  const oldSubscriptionsPayedOk = auth.payOldSubscriptionsSuccess;
  const oldSubscriptionsTriedToPay = auth.payOldSubscriptionsSuccessResponse?.tryedPayments;
  const oldSubscriptionsSucceededToPayed = auth.payOldSubscriptionsSuccessResponse?.payedSubscriptions;

  const closeSnack = () => {
    setPayingOldSubscriptions(false);
  };

  return (
    <div className="superadmin-configuration">
      <div className="expired-subscriptions-title">
        <h4>{texts.expiredSubscriptionsManagement}</h4>
      </div>
      <div className="expired-subscriptions-content">
        <Button
          onClick={() => {
            setPayingOldSubscriptions(true);
            payOldSubscriptions();
          }}
          variant="contained"
          color="secondary"
        >
          {texts.retryPayments}
        </Button>
        <CustomSnackbar
          open={payingOldSubscriptions && (auth.payOldSubscriptionsSuccess || auth.payOldSubscriptionsErrorMessage)}
          message={
            oldSubscriptionsPayedOk
              ? oldSubscriptionsTriedToPay > 0
                ? `Se intentaron realizar ${oldSubscriptionsSucceededToPayed} pagos, de los cuales se completaron ${oldSubscriptionsTriedToPay}.`
                : texts.noPendingPayments
              : texts.expiredSubscriptionsPaymentError
          }
          type={oldSubscriptionsPayedOk ? 0 : 1}
          handleClose={closeSnack}
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationSuperAdmin);

import * as ActionTypes from "../config/ActionTypes.js";

export const initialState = {
  loggedIn: false,
  loggingIn: false,
  errorMessage: null,
  gettingAccount: false,
  getAccountErrorMessage: null,
  user: null,
  changingPassword: false,
  changePasswordSuccess: false,
  changePasswordErrorMessage: null,
  payingOldSubscriptions: false,
  payOldSubscriptionsSuccess: false,
  payOldSubscriptionsSuccessResponse: null,
  payOldSubscriptionsErrorMessage: null,
  loadingConfigurations: false,
  configurations: null,
  configurationsSuccess: false,
  configurationsErrorMessage: null,
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: false,
        loggingIn: true,
        errorMessage: null,
      };

    case ActionTypes.LOGIN:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        errorMessage: null,
        user: action.payload.user ? action.payload.user : action.payload,
      };

    case ActionTypes.LOGIN_FAILED:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        errorMessage: action.payload,
      };

    case ActionTypes.GETTING_ACCOUNT:
      return {
        ...state,
        loggedIn: false,
        gettingAccount: true,
        getAccountErrorMessage: null,
      };

    case ActionTypes.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        gettingAccount: false,
        getAccountErrorMessage: null,
        user: action.payload,
      };

    case ActionTypes.GET_ACCOUNT_FAILED:
      return {
        ...state,
        gettingAccount: false,
        getAccountErrorMessage: action.payload,
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    case ActionTypes.CHANGING_PASSWORD:
      return {
        ...state,
        changingPassword: true,
        changePasswordSuccess: false,
        changePasswordErrorMessage: null,
      };

    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: true,
        changePasswordErrorMessage: null,
      };

    case ActionTypes.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: false,
        changePasswordErrorMessage: action.payload,
      };

    case ActionTypes.PAYING_OLD_SUBSCRIPTORS:
      return {
        ...state,
        payingOldSubscriptions: true,
        payOldSubscriptionsSuccess: false,
        payOldSubscriptionsSuccessResponse: null,
        payOldSubscriptionsErrorMessage: null,
      };

    case ActionTypes.SUBSCRIPTORS_PAY_OLD_SUCCESS:
      return {
        ...state,
        payOldSubscriptionsSuccess: true,
        payOldSubscriptionsSuccessResponse: action.payload,
        payOldSubscriptionsErrorMessage: null,
        payingOldSubscriptions: false,
      };

    case ActionTypes.SUBSCRIPTORS_PAY_OLD_FAILED:
      return {
        ...state,
        payOldSubscriptionsSuccess: false,
        payOldSubscriptionsSuccessResponse: null,
        payOldSubscriptionsErrorMessage: action.payload,
        payingOldSubscriptions: false,
      };

    case ActionTypes.LOADING_CONFIGURATIONS:
      return {
        ...state,
        loadingConfigurations: true,
        configurationsSuccess: false,
        configurationsErrorMessage: null,
      };

    case ActionTypes.CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        loadingConfigurations: false,
        configurations: action.payload,
        configurationsSuccess: true,
        configurationsErrorMessage: null,
      };

    case ActionTypes.CONFIGURATIONS_FAILED:
      return {
        ...state,
        loadingConfigurations: false,
        configurationsSuccess: false,
        configurationsErrorMessage: action.payload,
      };

    default:
      return state;
  }
};

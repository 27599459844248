import { httpVerbs, URL } from "./config";
import { baseService } from "./base.service";

export const commercesService = {
  fetchCommerces,
  createCommerce,
  updateCommerce,
  deleteCommerce,
};

async function fetchCommerces(clientId) {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  if (clientId) {
    requestOptions.headers.append("clientId", clientId);
  }

  return await fetch(`${URL}/commerces`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function createCommerce(commerceRequest, clientId) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(commerceRequest),
  };

  if (clientId) {
    requestOptions.headers.append("clientId", clientId);
  }

  return await fetch(`${URL}/commerces`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function updateCommerce(clientId, commerceId, commerceRequest) {
  const requestOptions = {
    method: httpVerbs.Put,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(commerceRequest),
  };

  return await fetch(`${URL}/commerces/${commerceId}?clientId=${clientId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function deleteCommerce(issuerId, clientId) {
  const requestOptions = {
    method: httpVerbs.Delete,
    headers: baseService.getHeaders(true),
  };

  if (clientId) {
    requestOptions.headers.append("clientId", clientId);
  }

  return await fetch(`${URL}/commerces/${issuerId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

export const numberHelper = {
	getNumberWithDots,
	getNumberWithDotsAndComma,
	getAmountWithoutIVA,
	getIvaFromAmount,
	addIVAToAmount,
	getFullInvoiceNumber,
};

function getNumberWithDots(number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function getNumberWithDotsAndComma(number) {
	number = number.toString().replace(".", ",");
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function toFixedComma(number, amount) {
	return number.toFixed(amount).replace(".", ",");
}

function getAmountWithoutIVA(amount, iva) {
	return getNumberWithDots(toFixedComma(amount / (1 + iva / 100), 2));
}

function getIvaFromAmount(amount, iva) {
	return getNumberWithDots(toFixedComma(amount - amount / (1 + iva / 100), 2));
}

function addIVAToAmount(amount, iva) {
	return getNumberWithDots(toFixedComma(amount + amount * (iva / 100), 2));
}

function getFullInvoiceNumber(invoiceNumber) {
	return padZeroes(invoiceNumber, 7);
}

function padZeroes(number, length) {
	var ret = "" + number;
	while (ret.length < length) {
		ret = "0" + ret;
	}

	return ret;
}

import * as ActionTypes from "../config/ActionTypes";

export const initialState = {
  isLoading: false,
  errorMessage: null,
  paymentFacilitators: null,
  creatingPaymentFacilitator: false,
  createPaymentFacilitatorErrorMessage: null,
  createPaymentFacilitatorSuccess: false,
  updatingPaymentFacilitator: false,
  updatePaymentFacilitatorErrorMessage: null,
  updatePaymentFacilitatorSuccess: false,
  deletingPaymentFacilitator: false,
  deletePaymentFacilitatorErrorMessage: null,
  deletePaymentFacilitatorSuccess: false,
};

export const PaymentFacilitatorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_PAYMENT_FACILITATORS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        paymentFacilitators: action.payload,
      };

    case ActionTypes.PAYMENT_FACILITATORS_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };

    case ActionTypes.PAYMENT_FACILITATORS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
        paymentFacilitators: null,
      };

    case ActionTypes.ADD_PAYMENT_FACILITATOR:
      let newPaymentFacilitators = state.paymentFacilitators || [];
      newPaymentFacilitators.push(action.payload);

      return {
        ...state,
        creatingPaymentFacilitator: false,
        createPaymentFacilitatorErrorMessage: null,
        createPaymentFacilitatorSuccess: true,
        paymentFacilitators: newPaymentFacilitators,
      };

    case ActionTypes.CREATING_PAYMENT_FACILITATOR:
      return {
        ...state,
        creatingPaymentFacilitator: true,
        createPaymentFacilitatorErrorMessage: null,
        createPaymentFacilitatorSuccess: false,
      };

    case ActionTypes.CREATE_PAYMENT_FACILITATOR_FAILED:
      return {
        ...state,
        creatingPaymentFacilitator: false,
        createPaymentFacilitatorErrorMessage: action.payload,
        createPaymentFacilitatorSuccess: false,
      };

    case ActionTypes.UPDATE_PAYMENT_FACILITATOR:
      let updatedPaymentFacilitators = state.paymentFacilitators.map((item, index) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        return {
          ...action.payload,
        };
      });

      return {
        ...state,
        updatingPaymentFacilitator: false,
        updatePaymentFacilitatorErrorMessage: null,
        updatePaymentFacilitatorSuccess: true,
        paymentFacilitators: updatedPaymentFacilitators,
      };

    case ActionTypes.UPDATING_PAYMENT_FACILITATOR:
      return {
        ...state,
        updatingPaymentFacilitator: true,
        updatePaymentFacilitatorErrorMessage: null,
        updatePaymentFacilitatorSuccess: false,
      };

    case ActionTypes.UPDATE_PAYMENT_FACILITATOR_FAILED:
      return {
        ...state,
        updatingPaymentFacilitator: false,
        updatePaymentFacilitatorErrorMessage: action.payload,
        updatePaymentFacilitatorSuccess: false,
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

import Cookies from "universal-cookie";

export const cookieHelper = {
	setCookies,
	removeCookies,
	getAllCookies,
};

function setCookies(user) {
	let cookieService = new Cookies();

	cookieService.set("userType", user.userType, {
		domain: window.location.hostname,
		path: process.env.REACT_APP_BASENAME,
		secure: process.env.NODE_ENV === "production",
	});
	cookieService.set("userId", user.userId, {
		domain: window.location.hostname,
		path: process.env.REACT_APP_BASENAME,
		secure: process.env.NODE_ENV === "production",
	});
	cookieService.set("accessToken", user.accessToken, {
		domain: window.location.hostname,
		path: process.env.REACT_APP_BASENAME,
		secure: process.env.NODE_ENV === "production",
	});
}

function removeCookies() {
	let cookieService = new Cookies();

	if (process.env.NODE_ENV === "development") {
		cookieService.remove("accessToken");
		cookieService.remove("userId");
		cookieService.remove("userType");
	} else {
		cookieService.remove("accessToken", {
			domain: window.location.hostname,
			path: process.env.REACT_APP_BASENAME,
			secure: true,
		});
		cookieService.remove("userId", {
			domain: window.location.hostname,
			path: process.env.REACT_APP_BASENAME,
			secure: true,
		});
		cookieService.remove("userType", {
			domain: window.location.hostname,
			path: process.env.REACT_APP_BASENAME,
			secure: true,
		});
	}
}

function getAllCookies() {
	let cookieService = new Cookies();

	const cookies = cookieService.getAll();

	return cookies;
}

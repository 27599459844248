import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchTransactions } from '../../store/action_creators/transactions.actions'
import Loading from '../common/animations/Loading'
import Error from '../common/animations/Error'
import TransactionRow from './TransactionRow'
import TransactionsFilters from './TransactionsFilters'
import { constants } from '../../data/constants'
import ExportTransactionsCSV from '../common/ExportTransactionsCSV'
import TransactionsSummary from './TransactionsSummary'
import InfiniteScroll from 'react-infinite-scroll-component'
import { texts } from '../../data/texts'
import { adminTypes } from '../../data/adminTypes'

const mapStateToProps = (state) => {
  return {
    transactions: state.transactions,
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTransactions: ({ page, limit, filters, reset, clientId }) => {
    dispatch(fetchTransactions({ page, limit, filters, reset, clientId }))
  },
})

function Transactions({ transactions, auth, fetchTransactions, clientId }) {
  const hasMore = transactions.currentPage < transactions.pageCount
  const isSuperAdmin = auth.user.userType === adminTypes.SUPERADMIN

  useEffect(() => {
    if (
      (!transactions.transactions &&
        !transactions.isLoading &&
        !transactions.errorMessage) ||
      transactions.transactionsClientId !== clientId
    ) {
      fetchTransactions({
        page: 1,
        limit: constants.transactionsLimit,
        filters: transactions.filters,
        reset: true,
        clientId,
      })
    }
  }, [
    transactions.transactions,
    transactions.isLoading,
    transactions.errorMessage,
    transactions.transactionsClientId,
    transactions.filters,
    fetchTransactions,
    clientId,
  ])

  const loadMore = () => {
    if (hasMore) {
      fetchTransactions({
        page: transactions.currentPage + 1,
        limit: constants.transactionsLimit,
        filters: transactions.filters,
        clientId,
      })
    }
  }

  return (
    <div className="transactions">
      {!clientId && (
        <div className="transactions-title">
          <h3>{texts.transactions}</h3>
          <ExportTransactionsCSV
            fileName={texts.transactions}
            buttonText={texts.exportTransactions}
          />
        </div>
      )}
      <TransactionsFilters
        transactions={transactions}
        fetchTransactions={fetchTransactions}
        clientId={clientId}
      />
      <TransactionsSummary />
      {(!transactions.transactions && !transactions.errorMessage) ||
      (transactions.isLoading && transactions.transactions.length === 0) ? (
        <Loading />
      ) : transactions.errorMessage ? (
        <Error
          message="Ocurrió un error al obtener los suscriptores"
          retry={fetchTransactions}
        />
      ) : transactions.transactions.length === 0 ? (
        <div className="empty-list">{texts.noTransactionsForFilter}</div>
      ) : (
        <InfiniteScroll
          hasMore={hasMore}
          next={loadMore}
          dataLength={
            transactions.transactions ? transactions.transactions.length : 0
          }
          loader={transactions.isLoading && <Loading />}
        >
          <div className="transactions-list">
            <div className="transaction-title">
              <div className="column">{texts.date}</div>
              <div className="column">{texts.subscription}</div>
              <div className="column">{texts.instrument}</div>
              <div className="column">{texts.subscriptorName}</div>
              <div className="column">{texts.subscriptorId}</div>
              <div className="column">{texts.amount}</div>
              <div className="column">{texts.status}</div>
              {auth.user &&
                ((auth.user.clientDetails &&
                  auth.user.clientDetails.electronicBillingEnabled) ||
                  isSuperAdmin) && (
                  <div className="column">{texts.billNumber}</div>
                )}
            </div>
            {transactions.transactions &&
              transactions.transactions.map((transaction, index) => {
                return (
                  <TransactionRow
                    key={index}
                    position={index}
                    data={transaction}
                    limit={constants.transactionsLimit}
                    billing={
                      auth.user &&
                      ((auth.user.clientDetails &&
                        auth.user.clientDetails.electronicBillingEnabled) ||
                        isSuperAdmin)
                    }
                  />
                )
              })}
          </div>
        </InfiniteScroll>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)

import { Accordion, AccordionDetails, AccordionSummary, Typography, withStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { texts } from "../../../data/texts";

const styles = (theme) => ({
  typeColor: {
    color: "#babb0c",
  },
  nameColor: {
    color: "#256FB5",
  },
});

function PayloadAccordion({ classes }) {
  const payloadTipText = (
    <div>
      <p>{texts.payloadExplanationText}</p>

      <code>
        &#123;
        <br />
        <div style={{ paddingLeft: "15px", margin: "0" }}>
          <span className={classes.nameColor}>BillId</span>: <span className={classes.typeColor}>string</span>,
          <br />
          <span className={classes.nameColor}>Instrument</span>: &#123;
          <br />
          <p style={{ paddingLeft: "15px", margin: "0" }}>
            <span className={classes.nameColor}>Expiration</span>: <span className={classes.typeColor}>DateTime</span>,
            <br />
            <span className={classes.nameColor}>InstrumentId</span>: <span className={classes.typeColor}>string</span>,
            <br />
            <span className={classes.nameColor}>IssuerImageUrl</span>: <span className={classes.typeColor}>string</span>
            ,
            <br />
            <span className={classes.nameColor}>IssuerName</span>: <span className={classes.typeColor}>string</span>,
            <br />
            <span className={classes.nameColor}>Name</span>: <span className={classes.typeColor}>string</span>
          </p>
          &#125;,
          <br />
          <span className={classes.nameColor}>SubscriberName</span>: <span className={classes.typeColor}>string</span>,
          <br />
          <span className={classes.nameColor}>Amount</span>: <span className={classes.typeColor}>decimal</span>,
          <br />
          <span className={classes.nameColor}>PercentageTax</span>: <span className={classes.typeColor}>string</span>,
          <br />
          <span className={classes.nameColor}>Currency</span>: <span className={classes.typeColor}>Currency</span>,
          <br />
          <span className={classes.nameColor}>PaymentDate</span>: <span className={classes.typeColor}>DateTime</span>,
          <br />
          <span className={classes.nameColor}>ExternalId</span>: <span className={classes.typeColor}>string</span>,
          <br />
          <span className={classes.nameColor}>SubscriptionTypeId</span>: <span className={classes.typeColor}>string</span>,
          <br />
          <span className={classes.nameColor}>SubscriptionTypeName</span>: <span className={classes.typeColor}>string</span>,
          <br />
          <span className={classes.nameColor}>Authorization</span>: <span className={classes.typeColor}>string</span>,
          <br />
          <span className={classes.nameColor}>Ticket</span>: <span className={classes.typeColor}>string</span>,
          <br />
          <span className={classes.nameColor}>Success</span>: <span className={classes.typeColor}>bool</span>,
          <br />
          <span className={classes.nameColor}>ErrorString</span>: <span className={classes.typeColor}>string</span>
          <br />
        </div>
        &#125;
        <br />
      </code>
    </div>
  );

  return (
    <Accordion elevation={0} classes={{ root: "expansion-root-override" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: "expansion-summary-root-override" }}>
        <Typography component="p" variant="caption">
          {texts.payloadQuestion}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{payloadTipText}</AccordionDetails>
    </Accordion>
  );
}

export default withStyles(styles)(PayloadAccordion);

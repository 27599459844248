import { httpVerbs, URL } from "./config";
import { baseService } from "./base.service";

export const subscriptionsService = {
  fetchSubscriptionTypes,
  createSubscriptionType,
  updateSubscriptionType,
  deleteSubscriptionType,
};

async function fetchSubscriptionTypes(clientId) {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  if (clientId) {
    requestOptions.headers.append("clientId", clientId);
  }

  return await fetch(`${URL}/subscriptionTypes`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function createSubscriptionType(subscriptionTypeRequest) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(subscriptionTypeRequest),
  };

  return await fetch(`${URL}/subscriptionTypes`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function updateSubscriptionType(subscriptionTypeId, subscriptionTypeRequest) {
  const requestOptions = {
    method: httpVerbs.Put,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(subscriptionTypeRequest),
  };
  return await fetch(`${URL}/subscriptionTypes/${subscriptionTypeId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function deleteSubscriptionType(subscriptionTypeId) {
  const requestOptions = {
    method: httpVerbs.Delete,
    headers: baseService.getHeaders(true),
  };

  return await fetch(`${URL}/subscriptionTypes/${subscriptionTypeId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

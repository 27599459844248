import React from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { subscriptorsService } from "../../services/subscriptors.service";
import { xlsHelper } from "../../helpers/xlsHelper";
import { filteringHelper } from "../../helpers/filteringHelper";
import GetAppIcon from "@material-ui/icons/GetApp";

const mapStateToProps = (state) => ({
  subscriptors: state.subscriptors,
});

function ExportSubscriptorsCSV({ subscriptors, fileName, buttonText }) {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const fetchAndExport = () => {
    subscriptorsService
      .fetchSubscriptors(1, subscriptors.rowCount, filteringHelper.applySubscriptorsFilters(subscriptors.filters))
      .then(
        (subscriptors) => {
          exportToCSV(xlsHelper.getSubscriptorsXLS(subscriptors.results), fileName);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <Button
      onClick={fetchAndExport}
      color="secondary"
      variant="outlined"
      startIcon={<GetAppIcon />}
      disabled={subscriptors.rowCount === 0}
    >
      {buttonText}
    </Button>
  );
}

export default connect(mapStateToProps, null)(ExportSubscriptorsCSV);

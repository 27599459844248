import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Grow } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeCourtesySubscriptor } from "../../store/action_creators/courtesySubscriptors.actions";
import DeleteSubscriptorDialog from "./DeleteSubscriptorDialog";
import SubscriptorDialog from "./SubscriptorDialog";

const mapStateToProps = (state) => ({
  subscriptors: state.subscriptors,
});

const mapDispatchToProps = (dispatch) => ({
  removeCourtesySubscriptor: ({ externalId, subscriptionTypeId }) => {
    dispatch(removeCourtesySubscriptor({ externalId, subscriptionTypeId }));
  },
});

function SubscriptorRow({ subscriptors, removeCourtesySubscriptor, position, data, limit, isCourtesy }) {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteSubscriptor = () => {
    removeCourtesySubscriptor({
      externalId: data.externalId,
      subscriptionTypeId: data.subscriptions[0].subscriptionType.id,
    });
    setDeleting(true);
  };

  useEffect(() => {
    if (subscriptors.deleteCourtesySubscriptorSuccess && deleting) {
      setDeleting(false);
      setDeleteOpen(false);
    }
  }, [subscriptors.deleteCourtesySubscriptorSuccess, deleting, setDeleting, setDeleteOpen]);

  const getValidUntil = (data) => {
    let validUntil = "";

    if (data.subscriptions[0]) {
      validUntil = data.subscriptions[0].validUntil;
    } else {
      return "";
    }

    if (validUntil.substring(0, 4) === "9999") {
      return "Sin límite";
    }

    return moment(validUntil).format("DD/MM/YYYY");
  };

  const openSubscriptorDialog = () => {
    if (!isCourtesy) {
      setOpen(true);
    }
  };

  return (
    <>
      <Grow in={true} direction="left" timeout={100 * (position % limit)}>
        <div className="subscriptor-row" onClick={openSubscriptorDialog}>
          <div className="column">{data.externalId}</div>
          <div className="column">
            {data.subscriptions && data.subscriptions.length > 0
              ? moment(data.subscriptions[0].subscriptionDate).format("DD/MM/YYYY")
              : ""}
          </div>
          <div className="column">{data.fullName}</div>
          <div className="column">{data.email}</div>
          {isCourtesy ? <div className="column">{getValidUntil(data)}</div> : null}
          {isCourtesy ? (
            <div className="column">
              <FontAwesomeIcon icon="trash" className="delete-icon" onClick={() => setDeleteOpen(true)} />
            </div>
          ) : null}
        </div>
      </Grow>
      <SubscriptorDialog open={open} setOpen={setOpen} data={data} />
      <DeleteSubscriptorDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        data={data}
        deleteSubscriptor={deleteSubscriptor}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptorRow);

const colors = {
  notBlack: "#545454",
  darkGrey: "#c4c4c4",
  grey: "#dedede",
  lightGrey: "#f2f2f2",
  white: "#ffffff",
  darkBlue: "#1e385a",
  primary: "#4877b4",
  lightPrimary: "#5e91da",
  red: "#c54343",
  errorRed: "#f44336",
  purple: "#B45DB7",
  green: "#4caf50",
  orange: "#e59c2e",
  yellow: "#e5aa51",
  lightYellow: "#ffeb3b"
};

export { colors };

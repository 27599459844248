import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { numberHelper } from "../../helpers/numberHelper";
import ColoredCardWithTotals from "./ColoredCardWithTotals";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => ({
  transactions: state.transactions,
});

const styles = (theme) => ({
  summary: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 16,
    marginBottom: 16,
  },
  separator: {
    width: "15px",
  },
});

function TransactionsSummary({ classes, transactions }) {
  return (
    <div className={classes.summary}>
      <ColoredCardWithTotals
        color="success"
        description={texts.successfulPayments}
        pesosAmount={numberHelper.getNumberWithDotsAndComma(transactions.totals)}
        usdAmount={numberHelper.getNumberWithDotsAndComma(transactions.totalsDollar)}
      />
      <div className={classes.separator} />
      <ColoredCardWithTotals
        color="error"
        description={texts.failedPayments}
        pesosAmount={numberHelper.getNumberWithDotsAndComma(transactions.totalsFailed)}
        usdAmount={numberHelper.getNumberWithDotsAndComma(transactions.totalsDollarFailed)}
      />
    </div>
  );
}

export default connect(mapStateToProps, null)(withStyles(styles)(TransactionsSummary));

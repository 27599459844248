import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import FormTextField from "../common/forms/FormTextField";
import {
  createSubscriptionType,
  updateSubscriptionType,
  deleteSubscriptionType,
} from "../../store/action_creators/subscriptions.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteDialog from "../common/dialogs/DeleteDialog";
import FormSelect from "../common/forms/FormSelect";
import schemas from "../../data/schemas";
import { frequences } from "../../data/frequences";
import { frequencyHelper } from "../../helpers/frequencyHelper";
import { currencies } from "../../data/currencies";
import { currencyHelper } from "../../helpers/currencyHelper";
import FormCheckboxField from "../common/forms/FormCheckboxField";
import { texts } from "../../data/texts";

const mapDispatchToProps = (dispatch) => ({
  createSubscriptionType: ({ name, description, frequence, currency, subscriptionCost, regularType, recurrence }) => {
    dispatch(
      createSubscriptionType({
        name,
        description,
        frequence,
        currency,
        subscriptionCost,
        regularType,
        recurrence,
      })
    );
  },
  updateSubscriptionType: ({
    subscriptionTypeId,
    name,
    description,
    frequence,
    currency,
    subscriptionCost,
    regularType,
    recurrence,
  }) => {
    dispatch(
      updateSubscriptionType({
        subscriptionTypeId,
        name,
        description,
        frequence,
        currency,
        subscriptionCost,
        regularType,
        recurrence,
      })
    );
  },
  deleteSubscriptionType: ({ subscriptionTypeId }) => {
    dispatch(deleteSubscriptionType({ subscriptionTypeId }));
  },
});

function SubscriptionForm({
  open,
  setOpen,
  selectedSubscription,
  createSubscriptionType,
  updateSubscriptionType,
  deleteSubscriptionType,
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedFrequence, setSelectedFrequence] = useState(
    selectedSubscription ? selectedSubscription.frequence : frequences.MONTHLY
  );
  const [selectedCurrency, setSelectedCurrency] = useState(
    selectedSubscription ? selectedSubscription.currency : currencies.PESO
  );
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && selectedSubscription) {
      setSelectedFrequence(selectedSubscription.frequence);
      setSelectedCurrency(selectedSubscription.currency);
      setLoaded(true);
    }
  }, [loaded, selectedSubscription, setSelectedFrequence, setSelectedCurrency]);

  const submitDelete = () => {
    deleteSubscriptionType({ subscriptionTypeId: selectedSubscription.id });
    setDeleteOpen(false);
    setOpen(false);
  };

  console.log(selectedSubscription);

  const submitSubscription = (values, { setSubmitting }) => {
    if (!selectedSubscription) {
      createSubscriptionType({
        name: values.name,
        description: values.description,
        frequence: selectedFrequence,
        currency: selectedCurrency,
        subscriptionCost: values.subscriptionCost,
        regularType: !values.isCourtesy, //A regular type subscription is a non-courtesy subscription
        recurrence: values.recurrence,
      });
    } else {
      updateSubscriptionType({
        subscriptionTypeId: selectedSubscription.id,
        name: values.name,
        description: values.description,
        frequence: selectedFrequence,
        currency: selectedCurrency,
        subscriptionCost: values.subscriptionCost,
        regularType: !values.isCourtesy, //A regular type subscription is a non-courtesy subscription
        recurrence: values.recurrence,
      });
    }

    setSubmitting(false);
    setOpen(false);
  };

  const frequencesOptions = Object.values(frequences).map((f) => {
    return { id: f, value: frequencyHelper.getFrequencyName(f) };
  });

  const currenciesOptions = Object.values(currencies).map((c) => {
    return { id: c, value: currencyHelper.getCurrencyToken(c) };
  });

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <Formik
        initialValues={{
          name: selectedSubscription ? selectedSubscription.name : "",
          description: selectedSubscription ? selectedSubscription.description : "",
          frequence: selectedSubscription ? selectedSubscription.frequence : "",
          currency: selectedSubscription ? selectedSubscription.currency : "",
          subscriptionCost: selectedSubscription ? selectedSubscription.subscriptionBaseCost : "",
          recurrence: selectedSubscription ? selectedSubscription.recurrence : 0,
          isCourtesy: selectedSubscription ? !selectedSubscription.regularType : false,
        }}
        validationSchema={schemas.SubscriptionTypeSchema}
        onSubmit={submitSubscription}
      >
        {({ isSubmitting, values }) => (
          <Form className="form">
            <DialogTitle classes={{ root: "title" }}>
              <span>
                {selectedSubscription ? (
                  <>
                    <p>{`Editar ${selectedSubscription.name}`}</p>
                    {!selectedSubscription.regularType && (
                      <Typography component="p" variant="subtitle2">
                        {texts.courtesySubscription}
                      </Typography>
                    )}
                  </>
                ) : (
                  `${texts.newSubscriptionType}`
                )}
              </span>
              {selectedSubscription ? (
                <IconButton onClick={() => setDeleteOpen(true)}>
                  <FontAwesomeIcon icon="trash" className="delete-icon" />
                </IconButton>
              ) : null}
            </DialogTitle>
            <DialogContent>
              <Field label={texts.name} name="name" component={FormTextField} />
              <Field label={texts.description} name="description" component={FormTextField} />
              <Field
                label={texts.frequency}
                name="frequence"
                component={FormSelect}
                disabled={selectedSubscription !== null || values.isCourtesy}
                options={frequencesOptions}
                labelWidth={80}
                value={selectedFrequence}
                setValue={(e) => setSelectedFrequence(e.target.value)}
              />
              <Field
                label={texts.currency}
                name="currency"
                component={FormSelect}
                disabled={selectedSubscription !== null || values.isCourtesy}
                options={currenciesOptions}
                labelWidth={60}
                value={selectedCurrency}
                setValue={(e) => setSelectedCurrency(e.target.value)}
              />
              <Field
                label={texts.cost}
                name="subscriptionCost"
                component={FormTextField}
                disabled={values.isCourtesy}
              />
              <p className="form-inline-text">Este valor deberá reflejar el costo de la suscripción sin incluir los impuestos</p>
              <Field
                label={texts.recurrence}
                name="recurrence"
                component={FormTextField}
                type="number"
                disabled={selectedSubscription !== null || values.isCourtesy}
              />
              {selectedSubscription === null && (
                <Field
                  component={FormCheckboxField}
                  label={texts.courtesySubscription}
                  name="isCourtesy"
                  onClick={() => {
                    values.subscriptionCost = values.isCourtesy ? "" : "0";
                    values.recurrence = values.isCourtesy ? "" : "0";
                  }}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button className="save" variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                {texts.save}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
      <DeleteDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        text={`¿Seguro que deseas borrar el tipo de suscripción ${
          selectedSubscription ? selectedSubscription.name : ""
        }? ${selectedSubscription?.regularType ? "" : texts.courtesySubscriptionTypeDeletionWarning}`}
        submit={submitDelete}
      />
    </Dialog>
  );
}

export default connect(null, mapDispatchToProps)(SubscriptionForm);

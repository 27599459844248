import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  IconButton,
  Chip,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import FormTextField from "../common/forms/FormTextField";
import schemas from "../../data/schemas";
import {
  updatePaymentFacilitator,
  registerPaymentFacilitator,
} from "../../store/action_creators/paymentFacilitators.actions";
import ColorPicker from "./ColorPicker";
import AddIcon from "@material-ui/icons/Add";
import { adminTypes } from "../../data/adminTypes";
import CustomSnackbar from "../common/CustomSnackbar";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => ({
  paymentFacilitators: state.paymentFacilitators,
});

const mapDispatchToProps = (dispatch) => ({
  registerPaymentFacilitator: (paymentFacilitatorRequest, userRequest) =>
    dispatch(registerPaymentFacilitator(paymentFacilitatorRequest, userRequest)),
  updatePaymentFacilitator: (paymentFacilitatorRequest) =>
    dispatch(updatePaymentFacilitator(paymentFacilitatorRequest)),
});

function PaymentFacilitatorForm({
  open,
  setOpen,
  selectedPaymentFacilitator,
  paymentFacilitators,
  registerPaymentFacilitator,
  updatePaymentFacilitator,
}) {
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(
    selectedPaymentFacilitator ? selectedPaymentFacilitator.style.primaryColor : "#666666"
  );
  const [secondaryColor, setSecondaryColor] = useState(
    selectedPaymentFacilitator ? selectedPaymentFacilitator.style.secondaryColor : "#DEDEDE"
  );
  const [commerceId, setCommerceId] = useState("");
  const [list, setList] = useState(
    selectedPaymentFacilitator ? selectedPaymentFacilitator.paymentFacilitatorCommerceIds : []
  );

  const isNew = selectedPaymentFacilitator === undefined;

  const addCommerceId = () => {
    if (commerceId !== "" && Number.parseInt(commerceId) && Number.parseInt(commerceId) > 0) {
      const newList = list;
      newList.push({ id: 0, value: Number(commerceId) });
      setList([...newList]);
      setCommerceId("");
    }
  };

  const deleteCommerceId = (commerceId) => {
    const nonDeletedCommerces = list.filter((c) => c.value.toString() !== commerceId.toString());
    setList([...nonDeletedCommerces]);
  };

  const submitPaymentFacilitator = (values, { setSubmitting }) => {
    const style = {
      primaryColor,
      secondaryColor,
    };

    const admin = {
      username: values.username,
      password: values.password,
      userType: adminTypes.PFADMIN,
    };

    const request = {
      id: isNew ? "" : selectedPaymentFacilitator.id,
      pFadmin: isNew ? null : selectedPaymentFacilitator.pfAdmin,
      name: values.name,
      style,
      adminUserId: isNew ? "" : selectedPaymentFacilitator.pFadmin ? selectedPaymentFacilitator.pFadmin.userId : "",
      pfCommerceIds: list,
      paymentFacilitatorPlexoId: values.paymentFacilitatorId,
    };

    if (isNew) {
      registerPaymentFacilitator(request, admin);
      setCreating(true);
    } else {
      request.id = selectedPaymentFacilitator.id;
      request.pFadmin = selectedPaymentFacilitator.pFadmin;
      updatePaymentFacilitator(request);
      setUpdating(true);
    }

    setSubmitting(false);
  };

  const closeSnack = () => {
    setOpen(false);
    setCreating(false);
    setUpdating(false);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <Formik
        enableReinitialize
        initialValues={{
          name: selectedPaymentFacilitator ? selectedPaymentFacilitator.name : "",
          paymentFacilitatorId: selectedPaymentFacilitator ? selectedPaymentFacilitator.paymentFacilitatorPlexoId : "",
          username: selectedPaymentFacilitator
            ? selectedPaymentFacilitator.pFadmin
              ? selectedPaymentFacilitator.pFadmin.userName
              : ""
            : "",
          password: selectedPaymentFacilitator ? "Placeholder1234" : "",
        }}
        validationSchema={schemas.PaymentFacilitatorSchema}
        onSubmit={submitPaymentFacilitator}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <DialogTitle>
              {selectedPaymentFacilitator ? `Editar ${selectedPaymentFacilitator.name}` : texts.newPaymentFacilitator}
            </DialogTitle>
            <DialogContent>
              <Field label={texts.name} name="name" component={FormTextField} />
              <Field label={texts.paymentFacilitatorId} name="paymentFacilitatorId" component={FormTextField} />
              <Typography style={{ marginTop: 12 }} variant="subtitle1">
                {texts.styles}
              </Typography>
              <div className="styles">
                <div className="color">
                  <span>{texts.primaryColor}</span>
                  <ColorPicker color={primaryColor} setColor={setPrimaryColor} />
                </div>
                <div className="color" style={{ marginLeft: 12 }}>
                  <span>{texts.secondaryColor}</span>
                  <ColorPicker color={secondaryColor} setColor={setSecondaryColor} />
                </div>
              </div>
              {isNew && (
                <Typography style={{ marginTop: 12 }} variant="subtitle1">
                  {texts.adminData}
                </Typography>
              )}
              <Field
                label={isNew ? texts.user : texts.admin}
                name="username"
                component={FormTextField}
                disabled={!isNew}
              />
              {isNew && <Field label={texts.password} name="password" type="password" component={FormTextField} />}
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                value={commerceId}
                onChange={(e) => setCommerceId(e.target.value)}
                label={texts.commerceId}
                type="number"
                InputProps={{
                  inputProps: {
                    min: "1",
                  },
                  endAdornment: (
                    <IconButton onClick={() => addCommerceId()}>
                      <AddIcon />
                    </IconButton>
                  ),
                }}
              />
              {list.map((obj, index) => {
                return (
                  <Chip
                    key={`${obj.value}-${index}`}
                    label={obj.value}
                    style={{ marginRight: 8 }}
                    onDelete={() => deleteCommerceId(obj.value)}
                  />
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button className="save" variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                {texts.save}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
      <CustomSnackbar
        open={
          creating &&
          (paymentFacilitators.createPaymentFacilitatorSuccess ||
            paymentFacilitators.createPaymentFacilitatorErrorMessage)
        }
        message={
          paymentFacilitators.createPaymentFacilitatorSuccess
            ? texts.paymentFacilitatorCreationSuccess
            : texts.paymentFacilitatorCreationError
        }
        type={paymentFacilitators.createPaymentFacilitatorSuccess ? 0 : 1}
        handleClose={closeSnack}
      />
      <CustomSnackbar
        open={
          updating &&
          (paymentFacilitators.updatePaymentFacilitatorSuccess ||
            paymentFacilitators.updatePaymentFacilitatorErrorMessage)
        }
        message={
          paymentFacilitators.updatePaymentFacilitatorSuccess
            ? texts.paymentFacilitatorUpdateSuccess
            : texts.paymentFacilitatorUpdateError
        }
        type={paymentFacilitators.updatePaymentFacilitatorSuccess ? 0 : 1}
        handleClose={closeSnack}
      />
    </Dialog>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFacilitatorForm);

const URL = process.env.REACT_APP_API_URL;

const httpVerbs = {
  Post: "POST",
  Put: "PUT",
  Get: "GET",
  Delete: "DELETE",
};

//"http://209.133.212.155/subscriptionstestv2/api";
//"http://suscripciones.handsoft.com.uy/subscriptions/api";

export { URL, httpVerbs };

import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { texts } from "../../data/texts";
import { fetchPaymentFacilitators } from "../../store/action_creators/paymentFacilitators.actions";
import PaymentFacilitator from "./PaymentFacilitator";
import PaymentFacilitatorForm from "./PaymentFacilitatorForm";

const mapStateToProps = (state) => ({
  paymentFacilitators: state.paymentFacilitators,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentFacilitators: () => dispatch(fetchPaymentFacilitators()),
});

function PaymentFacilitators({ paymentFacilitators, fetchPaymentFacilitators }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      paymentFacilitators.paymentFacilitators === null &&
      !paymentFacilitators.isLoading &&
      paymentFacilitators.errorMessage === null
    ) {
      fetchPaymentFacilitators();
    }
  });

  return (
    <div className="payment-facilitators">
      <div className="title">
        <h3>{texts.paymentFacilitators}</h3>
        <Button color="secondary" variant="contained" onClick={() => setOpen(true)}>
          {texts.newPaymentFacilitator}
        </Button>
      </div>
      <div className="payment-facilitators-list">
        {paymentFacilitators.paymentFacilitators &&
          paymentFacilitators.paymentFacilitators.map((pf, index) => {
            return <PaymentFacilitator key={index} position={index} data={pf} />;
          })}
      </div>
      <PaymentFacilitatorForm open={open} setOpen={setOpen} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFacilitators);

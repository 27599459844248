import React, { useState } from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field } from "formik";
import FormTextField from "../common/forms/FormTextField";
import { createCommerce, deleteCommerce } from "../../store/action_creators/commerces.actions";
import DeleteDialog from "../common/dialogs/DeleteDialog";
import MarkControl from "./MarkControl";
import MarkFields from "./MarkFields";
import { adminTypes } from "../../data/adminTypes";
import CustomSnackbar from "../common/CustomSnackbar";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    commerces: state.commerces,
    issuers: state.issuers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createCommerce: ({ commerceName, commerceNumber, commerceTerminalNumber, issuerId, clientId }) => {
    dispatch(createCommerce({ commerceName, commerceNumber, commerceTerminalNumber, issuerId, clientId }));
  },
  deleteCommerce: ({ issuerId, clientId }) => {
    dispatch(deleteCommerce({ issuerId, clientId }));
  },
});

function CommerceForm({
  open,
  setOpen,
  selectedCommerce,
  clientId,
  auth,
  commerces,
  issuers,
  createCommerce,
  deleteCommerce,
}) {
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [issuer, setIssuer] = useState(null);
  const [commerceNumber, setCommerceNumber] = useState("");
  const [terminalNumber, setTerminalNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const isPFClient =
    auth.user && auth.user.userType === adminTypes.CLIENTADMIN && auth.user.paymentFacilitatorDetails !== null;
  const isPF = auth.user && auth.user.paymentFacilitatorDetails !== null;
  const isPFIssuer =
    isPF && issuer && issuers.pfIssuers && issuers.pfIssuers.find((pfi) => pfi.issuer.id === issuer.id);

  const submitDelete = () => {
    deleteCommerce({ issuerId: selectedCommerce.id, clientId });
    setDeleting(true);
  };

  const submitCommerce = (values, { setSubmitting }) => {
    setSubmitted(true);

    let isValid = issuer !== null;

    if (isValid) {
      setSubmitted(false);

      createCommerce({
        commerceName: values.commerceName === "" ? issuer.name : values.commerceName,
        commerceNumber: commerceNumber === "" ? "plexo_uy" : commerceNumber,
        commerceTerminalNumber: terminalNumber === "" && isPFIssuer ? "1234" : terminalNumber,
        issuerId: issuer.id,
        clientId,
      });

      setCreating(true);
    }

    setSubmitting(false);
  };

  const getPlexoFieldData = (type) => {
    //Provider commerce number: 2049
    //Terminal number: 2051
    switch (type) {
      case 2049:
        return {
          name: texts.commerceNumber,
          value: commerceNumber,
          setValue: setCommerceNumber,
        };

      case 2051:
        return {
          name: texts.terminalNumber,
          value: terminalNumber,
          setValue: setTerminalNumber,
        };

      default:
        return { name: "", value: "", setValue: null };
    }
  };

  const closeSnack = () => {
    setCreating(false);
    setDeleting(false);

    if (commerces.deleteCommerceSuccess) {
      setDeleteOpen(false);
      setCommerceNumber("");
      setTerminalNumber("");
      setOpen(false);
    }

    if (commerces.createCommerceSuccess) {
      setCommerceNumber("");
      setTerminalNumber("");
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <Formik
        initialValues={{
          commerceName: selectedCommerce ? selectedCommerce.name : "",
        }}
        onSubmit={submitCommerce}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <DialogTitle classes={{ root: "title" }}>
              <span>{selectedCommerce ? `Comercio ${selectedCommerce.issuer.name}` : texts.newCommerce}</span>
              {selectedCommerce && !isPFClient ? (
                <IconButton onClick={() => setDeleteOpen(true)}>
                  <FontAwesomeIcon icon="trash" className="delete-icon" />
                </IconButton>
              ) : null}
            </DialogTitle>
            <DialogContent>
              {!isPFIssuer && (
                <Field
                  disabled={selectedCommerce !== null}
                  label={texts.name}
                  name="commerceName"
                  component={FormTextField}
                />
              )}
              <MarkControl selectedCommerce={selectedCommerce} issuer={issuer} setIssuer={setIssuer} />
              {issuer && !isPFIssuer ? (
                <MarkFields
                  submitted={submitted}
                  issuer={issuer}
                  getPlexoFieldData={getPlexoFieldData}
                  selectedCommerce={selectedCommerce}
                />
              ) : null}
            </DialogContent>
            <DialogActions>
              {selectedCommerce ? null : (
                <Button className="save" variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                  {texts.save}
                </Button>
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
      <CustomSnackbar
        open={creating && (commerces.createCommerceSuccess || commerces.createCommerceErrorMessage !== null)}
        message={commerces.createCommerceSuccess ? texts.commerceCreationSuccess : texts.commerceCreationError}
        handleClose={closeSnack}
        type={commerces.createCommerceSuccess ? 0 : 1}
      />
      <CustomSnackbar
        open={deleting && (commerces.deleteCommerceSuccess || commerces.deleteCommerceErrorMessage !== null)}
        message={commerces.deleteCommerceSuccess ? texts.commerceDeletionSuccess : texts.commerceDeletionError}
        handleClose={closeSnack}
        type={commerces.deleteCommerceSuccess ? 0 : 1}
      />
      <DeleteDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        text={texts.commerceDeletionQuestion}
        submit={submitDelete}
      />
    </Dialog>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CommerceForm);

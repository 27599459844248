import * as ActionTypes from "../config/ActionTypes";

export const initialState = {
  isLoading: false,
  errorMessage: null,
  currentPage: 1,
  pageCount: 0,
  rowCount: 0,
  subscriptors: null,
  courtesySubscriptorsLoading: false,
  courtesySubscriptorsErrorMessage: null,
  courtesySubscriptors: null,
  addingCourtesySubscriptor: false,
  addCourtesySubscriptorSuccess: false,
  addCourtesySubscriptorErrorMessage: null,
  deletingCourtesySubscriptor: false,
  deleteCourtesySubscriptorSuccess: false,
  deleteCourtesySubscriptorErrorMessage: null,
  cancellingSubscription: false,
  cancelSubscriptionSuccess: false,
  cancelSubscriptionErrorMessage: null,
  filters: {
    state: 1,
    subscriptionTypes: [],
  },
  search: "",
};

export const SubscriptorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_SUBSCRIPTORS:
      let newSubscriptors = state.subscriptors
        ? state.subscriptors.concat(action.payload.results)
        : action.payload.results;

      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        currentPage: action.payload.currentPage,
        pageCount: action.payload.pageCount,
        rowCount: action.payload.rowCount,
        subscriptors: newSubscriptors,
      };

    case ActionTypes.SUBSCRIPTORS_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };

    case ActionTypes.SUBSCRIPTORS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };

    case ActionTypes.APPLY_SUBSCRIPTORS_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
        search: action.payload.search,
        subscriptors: [],
      };

    case ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancellingSubscription: false,
        cancelSubscriptionSuccess: true,
        cancelSubscriptionErrorMessage: null,
      };

    case ActionTypes.CANCELLING_SUBSCRIPTION:
      return {
        ...state,
        cancellingSubscription: true,
        cancelSubscriptionSuccess: false,
        cancelSubscriptionErrorMessage: null,
      };

    case ActionTypes.CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        cancellingSubscription: false,
        cancelSubscriptionSuccess: false,
        cancelSubscriptionErrorMessage: action.payload,
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

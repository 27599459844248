import React from "react";
import Lottie from "react-lottie";
import loadingAnimation from "../../../assets/loadAnimation.json";

function Loading({ size }) {
  return (
    <div>
      <Lottie
        height={size ? size : 300}
        width={size ? size : 300}
        isClickToPauseDisabled={true}
        options={{ animationData: loadingAnimation, loop: true }}
      />
    </div>
  );
}

export default Loading;

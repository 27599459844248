import React from "react";
import { createStyles, makeStyles, Typography, Paper, Divider } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { numberHelper } from "./../../helpers/numberHelper";
import { useTheme } from "@material-ui/core/styles";
import { texts } from "../../data/texts";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px",
      flexGrow: 1,
    },
    flexRowDescription: {
      [theme.breakpoints.up("sm")]: {
        justifyContent: "space-between",
      },
      display: "flex",
      alignItems: "center",
      "& *": {
        paddingRight: "5px",
      },
    },
    flexRowAmounts: {
      [theme.breakpoints.up("sm")]: {
        justifyContent: "space-between",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
      display: "flex",
      alignItems: "center",
    },
    flexRowBaseline: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      "& *": {
        paddingRight: "5px",
      },
    },
    divider: {
      [theme.breakpoints.up("sm")]: {
        visibility: "hidden",
      },
      margin: "0.4rem 1rem",
    },
  })
);

function ColoredCardWithTotals({ description, pesosAmount, usdAmount, color }) {
  const theme = useTheme();
  const classes = useStyles();

  const paletteColor = theme.palette[color];
  const txtColor = color === "warning" ? theme.palette.text.primary : paletteColor.dark;

  pesosAmount = pesosAmount ?? 0;
  usdAmount = usdAmount ?? 0;

  const pesos = numberHelper.getNumberWithDots(pesosAmount);
  const usd = numberHelper.getNumberWithDots(usdAmount);

  return (
    <Paper variant="outlined" elevation={0} className={classes.paper} style={{ backgroundColor: paletteColor?.light }}>
      <div className={classes.flexRowDescription}>
        <FiberManualRecordIcon style={{ fontSize: "20px", color: paletteColor?.dark }} />
        <Typography>{description}</Typography>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.flexRowAmounts}>
        <div className={classes.flexRowBaseline}>
          <Typography variant="h5" style={{ color: txtColor }}>
            {texts.pesosToken}
          </Typography>
          <Typography variant="h3" style={{ color: txtColor }}>
            {pesos}
          </Typography>
        </div>
        <div style={{ width: "20px" }} />
        <div className={classes.flexRowBaseline}>
          <Typography variant="h5" style={{ color: txtColor }}>
            {texts.dollarsToken}
          </Typography>
          <Typography variant="h3" style={{ color: txtColor }}>
            {usd}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

export default ColoredCardWithTotals;

import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { texts } from "../../data/texts";

function DeleteSubscriptorDialog({ open, setOpen, data, deleteSubscriptor }) {
  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
      <DialogTitle>{`Borrar suscriptor ${data.externalId}`}</DialogTitle>
      <DialogContent>{texts.deleteSubscriptorQuestion}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          style={{
            margin: "0 15px 15px 0",
            backgroundColor: "#c54343",
            color: "#FFF",
          }}
          onClick={deleteSubscriptor}
        >
          {texts.delete}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteSubscriptorDialog;

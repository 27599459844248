import * as ActionTypes from "../config/ActionTypes";
import moment from "moment";

export const initialState = {
  isLoading: false,
  errorMessage: null,
  dashboard: null,
  dashboardClientId: null,
};

export const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_DASHBOARD:
      let data = action.payload.historicTotalSubscriptors;

      data = data.sort((a, b) => {
        if (moment().diff(a.item1, "days") > moment().diff(b.item1, "days")) {
          return -1;
        }

        return 1;
      });

      if (data.length > 6) {
        data.slice(data.length - 6, data.length);
      }

      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        dashboard: action.payload,
      };

    case ActionTypes.DASHBOARD_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        dashboard: null,
        dashboardClientId: action.payload,
      };

    case ActionTypes.DASHBOARD_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
        dashboard: null,
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

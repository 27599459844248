import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchSubscriptions } from "../../store/action_creators/subscriptions.actions";
import { Button } from "@material-ui/core";
import Subscription from "./Subscription";
import SubscriptionForm from "./SubscriptionForm";
import Loading from "../common/animations/Loading";
import Error from "../common/animations/Error";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    subscriptions: state.subscriptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptions: () => {
    dispatch(fetchSubscriptions());
  },
});

function Subscriptions({ subscriptions, fetchSubscriptions }) {
  const [open, setOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  useEffect(() => {
    if (!subscriptions.subscriptionTypes) {
      fetchSubscriptions();
    }
  }, [subscriptions.subscriptionTypes, fetchSubscriptions]);

  const openNewSubscription = () => {
    setSelectedSubscription(null);
    setOpen(true);
  };

  const openSubscription = (event, subscription) => {
    setSelectedSubscription(subscription);
    setOpen(true);
  };

  if ((!subscriptions.subscriptionTypes && !subscriptions.errorMessage) || subscriptions.isLoading) {
    return <Loading />;
  } else if (subscriptions.errorMessage) {
    return <Error message={texts.subscriptionTypesError} retry={fetchSubscriptions} />;
  } else {
    return (
      <div className="subscriptions">
        <div className="subscriptions-title">
          <h3>{texts.subscriptionTypes}</h3>
          <Button color="secondary" variant="contained" onClick={openNewSubscription}>
            {texts.newSubscriptionType}
          </Button>
        </div>
        <h4 className="subscription-list-title">{texts.paidSubscriptions}</h4>
        <div className="subscriptions-list">
          {subscriptions.subscriptionTypes.filter((s) => s.regularType).length === 0 ? (
            <div className="empty-list">{texts.noSubscriptionTypesText}</div>
          ) : (
            subscriptions.subscriptionTypes
              .filter((s) => s.regularType)
              .map((subscription, index) => {
                return (
                  <Subscription key={index} position={index} data={subscription} openSubscription={openSubscription} />
                );
              })
          )}
        </div>
        <h4 className="subscription-list-title">{texts.courtesySubscriptions}</h4>
        <div className="subscriptions-list">
          {subscriptions.subscriptionTypes.filter((s) => !s.regularType).length === 0 ? (
            <div className="empty-list">{texts.noCourtesySubscriptionTypesText}</div>
          ) : (
            subscriptions.subscriptionTypes
              .filter((s) => !s.regularType)
              .map((subscription, index) => {
                return (
                  <Subscription key={index} position={index} data={subscription} openSubscription={openSubscription} />
                );
              })
          )}
        </div>
        <SubscriptionForm open={open} setOpen={setOpen} selectedSubscription={selectedSubscription} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);

import * as ActionTypes from "../config/ActionTypes";

export const initialState = {
  isLoading: false,
  errorMessage: null,
  subscriptionTypes: null,
  subscriptionTypesClientId: null,
  creatingSubscriptionType: false,
  createSubscriptionTypeErrorMessage: null,
  createSubscriptionTypeSuccess: false,
  updatingSubscriptionType: false,
  updateSubscriptionTypeErrorMessage: null,
  updateSubscriptionTypeSuccess: false,
  deletingSubscriptionType: false,
  deleteSubscriptionTypeErrorMessage: null,
  deleteSubscriptionTypeSuccess: false,
};

export const SubscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_SUBSCRIPTION_TYPES:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        subscriptionTypes: action.payload,
      };

    case ActionTypes.SUBSCRIPTION_TYPES_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        subscriptionTypesClientId: action.payload,
      };

    case ActionTypes.SUBSCRIPTION_TYPES_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
        subscriptionTypes: null,
      };

    case ActionTypes.ADD_SUBSCRIPTION_TYPE:
      let newSubscriptionTypes = state.subscriptionTypes || [];
      newSubscriptionTypes.push(action.payload);

      return {
        ...state,
        creatingSubscriptionType: false,
        createSubscriptionTypeErrorMessage: null,
        createSubscriptionTypeSuccess: true,
        subscriptionTypes: newSubscriptionTypes,
      };

    case ActionTypes.CREATING_SUBSCRIPTION_TYPE:
      return {
        ...state,
        creatingSubscriptionType: true,
        createSubscriptionTypeErrorMessage: null,
        createSubscriptionTypeSuccess: false,
      };

    case ActionTypes.CREATE_SUBSCRIPTION_TYPE_FAILED:
      return {
        ...state,
        creatingSubscriptionType: false,
        createSubscriptionTypeErrorMessage: action.payload,
        createSubscriptionTypeSuccess: false,
      };

    case ActionTypes.UPDATE_SUBSCRIPTION_TYPE:
      let updatedSubscriptionTypes = state.subscriptionTypes.map((item, index) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        return {
          ...action.payload,
        };
      });

      return {
        ...state,
        updatingSubscriptionType: false,
        updateSubscriptionTypeErrorMessage: null,
        updateSubscriptionTypeSuccess: true,
        subscriptionTypes: updatedSubscriptionTypes,
      };

    case ActionTypes.UPDATING_SUBSCRIPTION_TYPE:
      return {
        ...state,
        updatingSubscriptionType: true,
        updateSubscriptionTypeErrorMessage: null,
        updateSubscriptionTypeSuccess: false,
      };

    case ActionTypes.UPDATE_SUBSCRIPTION_TYPE_FAILED:
      return {
        ...state,
        updatingSubscriptionType: false,
        updateSubscriptionTypeErrorMessage: action.payload,
        updateSubscriptionTypeSuccess: false,
      };

    case ActionTypes.DELETE_SUBSCRIPTION_TYPE:
      let nonDeletedSubscriptionTypes = state.subscriptionTypes.filter((item, index) => {
        if (item.id !== action.payload) {
          return item;
        }
        return null;
      });

      return {
        ...state,
        deletingSubscriptionType: false,
        deleteSubscriptionTypeErrorMessage: null,
        deleteSubscriptionTypeSuccess: true,
        subscriptionTypes: nonDeletedSubscriptionTypes,
      };

    case ActionTypes.DELETING_SUBSCRIPTION_TYPE:
      return {
        ...state,
        deletingSubscriptionType: true,
        deleteSubscriptionTypeErrorMessage: null,
        deleteSubscriptionTypeSuccess: false,
      };

    case ActionTypes.DELETE_SUBSCRIPTION_TYPE_FAILED:
      return {
        ...state,
        deletingSubscriptionType: false,
        deleteSubscriptionTypeErrorMessage: action.payload,
        deleteSubscriptionTypeSuccess: false,
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

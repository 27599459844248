import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchConfigurations, updateConfigurations } from "../../../store/action_creators/auth.actions";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import CustomSnackbar from "../../common/CustomSnackbar";
import Loading from "../../common/animations/Loading";
import Notifications from "./Notifications";
import { texts } from "../../../data/texts";

const retriesEnum = {
  Traditional: 0,
  Weekly: 1,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  fetchConfigurations: ({ clientId }) => {
    dispatch(fetchConfigurations({ clientId }));
  },
  updateConfigurations: ({ clientId, configurations }) => {
    dispatch(updateConfigurations({ clientId, configurations }));
  },
});

function ConfigurationAdmin({ auth, fetchConfigurations, updateConfigurations }) {
  const [loaded, setLoaded] = useState(false);
  const [updatingConfiguration, setUpdatingConfiguration] = useState(false);
  const [value, setValue] = useState(retriesEnum.Traditional);

  useEffect(() => {
    if (!auth.configurations && !auth.loadingConfigurations && auth.configurationsErrorMessage === null) {
      fetchConfigurations({ clientId: auth.user.clientDetails.id });
    }
    if (auth.configurations && !loaded) {
      setLoaded(true);
      setValue(auth.configurations.retriesConfiguration);
    }
  }, [
    auth.paymentsCallbackUrl,
    fetchConfigurations,
    auth.user.clientDetails.id,
    auth.loadingConfigurations,
    auth.configurationsErrorMessage,
    auth.configurations,
    loaded,
    setLoaded,
  ]);

  const closeSnack = () => {
    setUpdatingConfiguration(false);
  };

  const changeRetries = (event) => {
    setUpdatingConfiguration(true);

    const newScheme = Number(event.target.value);

    const newConfig = {
      ...auth.configurations,
      retriesConfiguration: newScheme,
    };

    updateConfigurations({ clientId: auth.user.clientDetails.id, configurations: newConfig });
    setValue(newScheme);
  };

  if (!loaded) {
    return <Loading />;
  }

  return (
    <div className="admin-configuration">
      <div className="payments-callback-configuration">
        <div className="retries-configuration">
          <h4>{texts.retrySchema}</h4>
          <RadioGroup value={value} onChange={changeRetries}>
            <FormControlLabel value={retriesEnum.Traditional} control={<Radio />} label={texts.traditionalSchema} />
            <FormControlLabel value={retriesEnum.Weekly} control={<Radio />} label={texts.weeklySchema} />
          </RadioGroup>
        </div>
        <Notifications
          auth={auth}
          updateConfigurations={updateConfigurations}
          setUpdatingConfiguration={setUpdatingConfiguration}
        />
      </div>
      <CustomSnackbar
        open={updatingConfiguration && (auth.configurationsSuccess || auth.configurationsErrorMessage)}
        message={auth.configurationsSuccess ? texts.configurationsUpdateSuccess : texts.configurationsUpdateError}
        type={auth.configurationsSuccess ? 0 : 1}
        handleClose={closeSnack}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationAdmin);

import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import FormTextField from "../../common/forms/FormTextField";
import PayloadAccordion from "./PayloadAccordion";
import { texts } from "../../../data/texts";

function Notifications({ auth, updateConfigurations, setUpdatingConfiguration }) {
  const [loaded, setLoaded] = useState(false);
  const [formValues, setFormValues] = useState({
    recurringPaymentCallback: "",
  });
  const [notifications, setNotifications] = useState(false);

  useEffect(() => {
    if (auth.configurations && !loaded) {
      setLoaded(true);
      setNotifications(auth.configurations.paymentSuccess);
      setFormValues({
        recurringPaymentCallback: auth.configurations.callbackUrl,
      });
    }
  }, [auth.configurations, loaded, setNotifications, setFormValues]);

  const submitNewCallbackUrl = (values, { setSubmitting }) => {
    setUpdatingConfiguration(true);
    const newConfig = {
      ...auth.configurations,
      callbackUrl: values.callbackUrl,
    };

    updateConfigurations({
      clientId: auth.user.clientDetails ? auth.user.clientDetails.id : "",
      configurations: newConfig,
    });
    setSubmitting(false);
  };

  const changeNotifications = () => {
    setUpdatingConfiguration(true);

    const newValue = !notifications;

    const newConfig = {
      ...auth.configurations,
      paymentFailed: newValue,
      paymentSuccess: newValue,
      subscriptionCancellation: newValue,
      subscriptorFields: newValue,
      transactionCancellation: newValue,
    };

    updateConfigurations({
      clientId: auth.user.clientDetails ? auth.user.clientDetails.id : "",
      configurations: newConfig,
    });
    setNotifications(newValue);
  };

  return (
    <div>
      <div className="payments-callback-configuration-title">
        <h4>{texts.notificationManagement}</h4>
      </div>
      <FormControlLabel
        control={<Checkbox checked={notifications} onChange={changeNotifications} name="checkedA" />}
        label={texts.notificationsActive}
      />
      <Formik
        enableReinitialize
        onSubmit={submitNewCallbackUrl}
        initialValues={{
          callbackUrl: formValues.recurringPaymentCallback !== null ? formValues.recurringPaymentCallback : "",
        }}
      >
        {({ isSubmitting }) => (
          <Form className="Form">
            <div style={{ display: "flex" }}>
              <Field
                type="text"
                label={texts.enterUrl}
                name="callbackUrl"
                component={FormTextField}
                id="sth"
                style={{ width: "50%" }}
                noFullWidth
              />

              <Button
                style={{ margin: "auto 20px" }}
                className="save"
                color="secondary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                {texts.update}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <PayloadAccordion />
    </div>
  );
}

export default Notifications;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import FormTextField from "../common/forms/FormTextField";
import schemas from "../../data/schemas";
import { createCourtesySubscriptor } from "../../store/action_creators/courtesySubscriptors.actions";
import FormSubscriptionTypeSelector from "../common/forms/FormSubscriptionTypeSelector";
import FormSelect from "../common/forms/FormSelect";
import moment from "moment";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => ({
  subscriptors: state.subscriptors,
});

const mapDispatchToProps = (dispatch) => ({
  createCourtesySubscriptor: ({ validToDate, subscriptionTypeId, externalId, email, name, fullName }) => {
    dispatch(
      createCourtesySubscriptor({
        validToDate,
        subscriptionTypeId,
        externalId,
        email,
        name,
        fullName,
      })
    );
  },
});

const monthsOptions = [
  { id: 0, value: texts.limitless, months: 0 },
  { id: 1, value: texts.oneMonth, months: 1 },
  { id: 2, value: texts.twoMonths, months: 2 },
  { id: 3, value: texts.threeMonths, months: 3 },
  { id: 4, value: texts.fourMonths, months: 4 },
  { id: 5, value: texts.sixMonths, months: 6 },
  { id: 6, value: texts.twelveMonths, months: 12 },
];

function CourtesySubscriptorForm({ open, setOpen, subscriptors, createCourtesySubscriptor }) {
  const [creating, setCreating] = useState(false);
  const [selectedMonths, setSelectedMonths] = useState(0);

  const submitCourtesySubscriptor = (values, { setSubmitting }) => {
    var maxDate = new Date(9998, 12, 31, 23, 59, 59, 9999999);

    let selectedMonthOption = monthsOptions.filter((m) => m.id === selectedMonths)[0];

    let validToDate =
      selectedMonthOption.id === 0
        ? moment(maxDate).add(-1, "months").toISOString()
        : moment().add(selectedMonthOption.months, "months").toISOString();

    createCourtesySubscriptor({
      validToDate,
      subscriptionTypeId: values.subscriptionType.subscriptionTypeId,
      externalId: values.externalId,
      email: values.email,
      name: values.name,
      fullName: values.fullName,
    });

    setCreating(true);
    setSubmitting(false);
    setOpen(false);
  };

  useEffect(() => {
    if (subscriptors.addCourtesySubscriptorSuccess && creating) {
      setCreating(false);
      setOpen(false);
    }
  }, [subscriptors.addCourtesySubscriptorSuccess, creating, setCreating, setOpen]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <Formik
        initialValues={{
          externalId: "",
          email: "",
          name: "",
          fullName: "",
          months: "",
          subscriptionType: { subscriptionTypeId: "" },
        }}
        validationSchema={schemas.CourtesySubscriptorSchema}
        onSubmit={submitCourtesySubscriptor}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <DialogTitle classes={{ root: "title" }}>
              <span>{texts.newCourtesySubscriptor}</span>
            </DialogTitle>
            <DialogContent>
              <Field
                label={texts.subscriptionType}
                name="subscriptionType.subscriptionTypeId"
                component={FormSubscriptionTypeSelector}
              />
              <Field label={texts.externalId} name="externalId" component={FormTextField} />
              <Field label={texts.email} name="email" component={FormTextField} />
              <Field label={texts.name} name="name" component={FormTextField} />
              <Field label={texts.fullName} name="fullName" component={FormTextField} />
              <Field
                label={texts.monthsAmount}
                name="months"
                component={FormSelect}
                options={monthsOptions}
                labelWidth={145}
                value={selectedMonths}
                setValue={(e) => setSelectedMonths(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button className="save" variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                {texts.save}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CourtesySubscriptorForm);

import React, { useState } from "react";
import { connect } from "react-redux";
import Image from "material-ui-image";
import { AppBar, Toolbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { measures } from "../../data/measures";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logout } from "../../store/action_creators/auth.actions";
import LogoutDialog from "./dialogs/LogoutDialog";
import logo from "../../assets/logo.png";
import { texts } from "../../data/texts";

const styles = (theme) => ({
  root: {
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    alignItems: "center",
  },
  title: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    height: measures.headerHeight,
    width: 100,
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    height: measures.headerHeight,
  },
  speech: {
    marginLeft: 12,
    marginBottom: 7,
    fontSize: 14,
    fontStyle: "italic",
    color: "#CCC",
  },
  rightSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  clientData: {
    marginRight: 15,
  },
  logout: {
    cursor: "pointer",
  },
  icon: {
    marginRight: 10,
  },
});

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
});

function Header({ classes, auth, logout }) {
  const [open, setOpen] = useState(false);

  const submitLogout = () => {
    logout();
  };

  return (
    <div className={classes.root}>
      <AppBar color="primary" position="fixed">
        <Toolbar classes={{ root: classes.toolbar }}>
          <div className={classes.logo}>
            <Image
              imageStyle={{ position: "inherit", height: 46, width: "auto" }}
              style={{
                paddingTop: 0,
                background: "transparent",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: measures.headerHeight,
              }}
              src={logo}
              alt="logo"
            />
            <span className={classes.speech}>{texts.subscriptionsModule}</span>
          </div>
          <div className={classes.grow} />
          <div onClick={() => setOpen(true)} className={classes.logout}>
            <FontAwesomeIcon icon="sign-out-alt" className={classes.icon} />
            <span>{texts.exit}</span>
          </div>
        </Toolbar>
      </AppBar>
      <LogoutDialog open={open} setOpen={setOpen} submit={submitLogout} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));

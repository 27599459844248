import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { fetchSubscriptions } from "../../../store/action_creators/subscriptions.actions";
import { colors } from "../../../data/colors";
import { Select, InputLabel, FormControl, MenuItem, OutlinedInput } from "@material-ui/core";
import Loading from "../animations/Loading";
import { texts } from "../../../data/texts";

const styles = (theme) => ({
  error: {
    color: colors["errorRed"],
    fontSize: 14,
    marginBottom: 5,
  },
  formControl: {
    marginTop: 8,
    marginBottom: 4,
  },
  select: {
    padding: "3px 30px 7px 0",
  },
});

const mapStateToProps = (state) => {
  return {
    subscriptions: state.subscriptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptions: () => {
    dispatch(fetchSubscriptions());
  },
});

function FormSubscriptionTypeSelector({
  subscriptions,
  fetchSubscriptions,
  field,
  form: { touched, errors },
  classes,
  ...props
}) {
  const hasError =
    errors &&
    errors.subscriptionType &&
    errors.subscriptionType.subscriptionTypeId &&
    touched &&
    touched.subscriptionType &&
    touched.subscriptionType.subscriptionTypeId;

  useEffect(() => {
    if (!subscriptions.subscriptionTypes) {
      fetchSubscriptions();
    }
  }, [subscriptions.subscriptionTypes, fetchSubscriptions]);

  if (!subscriptions.subscriptionTypes) {
    return <Loading size={40} />;
  } else {
    return (
      <>
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <InputLabel htmlFor="app-helper">{texts.subscriptionType}</InputLabel>
          <Select
            name={texts.subscriptionType}
            input={<OutlinedInput labelWidth={145} id="app-helper" variant="outlined" />}
            error={hasError}
            {...field}
            {...props}
          >
            {subscriptions.subscriptionTypes.map((s) => {
              if (s.subscriptionBaseCost === 0) {
                return (
                  <MenuItem value={s.id} key={s.id}>
                    {s.name}
                  </MenuItem>
                );
              }

              return null;
            })}
          </Select>
        </FormControl>
        {hasError ? <div className={classes.error}>{errors.subscriptionType.subscriptionTypeId}</div> : null}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FormSubscriptionTypeSelector));

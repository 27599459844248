const frequences = {
	DIARY: 0,
	WEEKLY: 1,
	MONTHLY: 2,
	BIMONTHLY: 3,
	QUATERLY: 4,
	BIANNUAL: 5,
	ANNUAL: 6,
};

export { frequences };

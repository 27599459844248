import { status } from "../data/status";

export const filteringHelper = {
  applySubscriptorsFilters,
  applyTransactionsFilters,
};

function applySubscriptorsFilters(filters) {
  let ret = "";

  const { state, subscriptionTypes } = filters;

  if (state) {
    ret = ret.concat(getStateFilter(state));
  }

  if (subscriptionTypes && subscriptionTypes.length > 0) {
    ret = ret.concat(getSubscriptionTypesFilter(subscriptionTypes));
  }

  return ret;
}

function applyTransactionsFilters(filters) {
  if (!filters) {
    return "";
  }

  let ret = "";
  let { from, to, minAmount, maxAmount, subscriptionTypes, transactionState } = filters;

  if (from && to) {
    ret = ret.concat(getFromToFilter(from, to));
  }

  minAmount = minAmount < 0 || (minAmount === undefined) | (minAmount === null) ? 0 : minAmount;
  maxAmount = maxAmount === undefined ? null : maxAmount;

  if (maxAmount !== null) {
    ret = ret.concat(getMinMaxFilter(minAmount, maxAmount));
  }

  if (subscriptionTypes && subscriptionTypes.length > 0) {
    ret = ret.concat(getSubscriptionTypesFilter(subscriptionTypes));
  }

  if (transactionState !== undefined && transactionState !== null && transactionState !== status.ALL) {
    ret = ret.concat(getTransactionStateFilter(transactionState));
  }

  return ret;
}

function getFromToFilter(from, to) {
  return `&from=${from.format("YYYY-MM-DD").toString()}&to=${to.format("YYYY-MM-DD").toString()}`;
}

function getMinMaxFilter(min, max) {
  return `&minAmount=${min}&maxAmount=${max}`;
}

function getStateFilter(state) {
  return `&subscriptorsState=${state}`;
}

function getSubscriptionTypesFilter(subscriptionTypes) {
  let subscriptionTypesSearch = "&subscriptionTypes=";

  subscriptionTypes.forEach((st) => {
    subscriptionTypesSearch = subscriptionTypesSearch + st.id + ",";
  });

  return subscriptionTypesSearch.substring(0, subscriptionTypesSearch.length - 1);
}

function getTransactionStateFilter(transactionState) {
  return `&transactionState=${transactionState}`;
}

import { baseService } from "./base.service";
import { httpVerbs, URL } from "./config";

export const clientsService = {
  fetchClients,
  createClient,
  updateClient,
  deleteClient,
  updateClientSecret,
  updateClientNotifications,
};

async function fetchClients() {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  return await fetch(`${URL}/clients`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function createClient(clientRequest) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(clientRequest),
  };

  return await fetch(`${URL}/clients`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function updateClient(clientId, updateClientRequest) {
  const requestOptions = {
    method: httpVerbs.Put,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(updateClientRequest),
  };

  return await fetch(`${URL}/clients/${clientId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function deleteClient(clientId) {
  const requestOptions = {
    method: httpVerbs.Delete,
    headers: baseService.getHeaders(true),
  };

  return await fetch(`${URL}/clients/${clientId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function updateClientSecret(clientSecret, clientId) {
  const requestOptions = {
    method: httpVerbs.Put,
    headers: baseService.getHeaders(true),
  };

  requestOptions.headers.append("clientSecret", clientSecret);
  requestOptions.headers.append("clientId", clientId);

  return await fetch(`${URL}/clients`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function updateClientNotifications(clientId, value) {
  const request = {
    id: clientId,
    transactionCancellation: value,
    subscriptionCancellation: value,
    subscriptorFields: value,
    paymentSuccess: value,
    paymentFailed: value,
  };

  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(request),
  };

  return await fetch(`${URL}/clients/setNotificationPermissions`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { colors } from "../../../data/colors";

const styles = (theme) => ({
  error: {
    color: colors["errorRed"],
    fontSize: 15,
    marginBottom: 5,
  },
  errorIcon: {
    color: colors["errorRed"],
    fontSize: 15,
    cursor: "pointer",
  },
  tooltip: {
    backgroundColor: colors["errorRed"],
    fontSize: 15,
    padding: "5px 15px",
  },
  input: {
    fontSize: 15,
    color: colors["notBlack"],
  },
});

function FormCheckboxField({ field, classes, ...props }) {
  return (
    <FormControlLabel
      control={<Checkbox checked={field.value} color="primary" {...field} {...props} />}
      label={props.label}
    />
  );
}

export default withStyles(styles)(FormCheckboxField);

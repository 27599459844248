import * as ActionTypes from "../config/ActionTypes";
import { paymentFacilitatorsService } from "../../services/paymentFacilitators.service";
import { authService } from "../../services/auth.service";
import { registeringUser, addUser, registerUserFailed } from "./auth.actions";
import { deleteUser } from "./users.actions";

//#region Fetch payment facilitators

export const fetchPaymentFacilitators = () => (dispatch) => {
  dispatch(paymentFacilitatorsLoading());

  return paymentFacilitatorsService.fetchPaymentFacilitators().then(
    (paymentFacilitators) => {
      dispatch(addPaymentFacilitators(paymentFacilitators));
    },
    (error) => {
      dispatch(paymentFacilitatorsFailed(error));
    }
  );
};

const addPaymentFacilitators = (paymentFacilitators) => ({
  type: ActionTypes.ADD_PAYMENT_FACILITATORS,
  payload: paymentFacilitators,
});

const paymentFacilitatorsLoading = () => ({
  type: ActionTypes.PAYMENT_FACILITATORS_LOADING,
});

const paymentFacilitatorsFailed = (errorMessage) => ({
  type: ActionTypes.PAYMENT_FACILITATORS_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Create payment facilitator

export const registerPaymentFacilitator = (paymentFacilitatorRequest, userRequest) => (dispatch) => {
  dispatch(registeringUser());

  return authService.register(userRequest).then(
    (user) => {
      dispatch(addUser(user));

      paymentFacilitatorRequest.adminUserId = user.userId;
      dispatch(createPaymentFacilitator(paymentFacilitatorRequest, user));
    },
    (error) => {
      dispatch(registerUserFailed(error));
    }
  );
};

const createPaymentFacilitator = (paymentFacilitatorRequest, pfAdmin) => (dispatch) => {
  dispatch(creatingPaymentFacilitator());

  return paymentFacilitatorsService.createPaymentFacilitator(paymentFacilitatorRequest).then(
    (paymentFacilitator) => {
      pfAdmin.userName = pfAdmin.username;
      paymentFacilitator.pFadmin = pfAdmin;
      dispatch(addPaymentFacilitator(paymentFacilitator));
    },
    (error) => {
      dispatch(createPaymentFacilitatorFailed(error));

      dispatch(deleteUser({ userId: pfAdmin.id }));
    }
  );
};

const addPaymentFacilitator = (paymentFacilitator) => ({
  type: ActionTypes.ADD_PAYMENT_FACILITATOR,
  payload: paymentFacilitator,
});

const creatingPaymentFacilitator = () => ({
  type: ActionTypes.CREATING_PAYMENT_FACILITATOR,
});

const createPaymentFacilitatorFailed = (errorMessage) => ({
  type: ActionTypes.CREATE_PAYMENT_FACILITATOR_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Update payment facilitator

export const updatePaymentFacilitator = (paymentFacilitatorRequest) => (dispatch) => {
  dispatch(updatingPaymentFacilitator());

  return paymentFacilitatorsService
    .updatePaymentFacilitator(paymentFacilitatorRequest.id, paymentFacilitatorRequest)
    .then(
      (paymentFacilitator) => {
        dispatch(refreshPaymentFacilitator(paymentFacilitatorRequest));
      },
      (error) => {
        dispatch(updatePaymentFacilitatorFailed(error));
      }
    );
};

const refreshPaymentFacilitator = (paymentFacilitator) => ({
  type: ActionTypes.UPDATE_PAYMENT_FACILITATOR,
  payload: paymentFacilitator,
});

const updatingPaymentFacilitator = () => ({
  type: ActionTypes.UPDATING_PAYMENT_FACILITATOR,
});

const updatePaymentFacilitatorFailed = (errorMessage) => ({
  type: ActionTypes.UPDATE_PAYMENT_FACILITATOR_FAILED,
  payload: errorMessage,
});

//#endregion

const status = {
  OK: 0,
  ONHOLD: 1,
  CANCELED: 2,
  CANCELLATIONFAILED: 3,
  FAILED: 4,
  ALL: 5,
};

export { status };

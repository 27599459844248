import * as Yup from "yup";
import { texts } from "./texts";

const requiredString = texts.requiredField;
const passwordsString = texts.passwordsDoNotMatch;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
const passwordFormatString = texts.passwordRequirements;

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg || `${ref.path} must be the same as ${ref}`,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, "equalTo", equalTo);

const LoginSchema = Yup.object().shape({
  username: Yup.string().required(requiredString),
  password: Yup.string().required(requiredString),
});

const RegisterSchema = Yup.object().shape({
  username: Yup.string().required(requiredString),
  password: Yup.string().required(requiredString),
});

const NewClientSchema = Yup.object().shape({
  name: Yup.string().required(requiredString),
  username: Yup.string().required(requiredString),
  password: Yup.string()
    .required(requiredString)
    .min(6, texts.passwordLength)
    .matches(passwordRegex, passwordFormatString),
  clientLegalId: Yup.string().length(12, texts.rutLength).required(requiredString),
  clientLegalName: Yup.string().required(requiredString),
});

const UpdateClientSchema = Yup.object().shape({
  name: Yup.string().required(requiredString),
  username: Yup.string().required(requiredString),
  clientLegalId: Yup.string().length(12, texts.rutLength).required(requiredString),
  clientLegalName: Yup.string().required(requiredString),
});

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string().required(requiredString),
  newPassword: Yup.string().required(requiredString),
  repeatNewPassword: Yup.string().equalTo(Yup.ref("newPassword"), passwordsString).required(requiredString),
});

const IssuerSchema = Yup.object().shape({
  name: Yup.string().required(requiredString),
  plexoId: Yup.number().required(requiredString),
});

const SubscriptionTypeSchema = Yup.object().shape({
  name: Yup.string().required(requiredString),
  description: Yup.string().required(requiredString),
  subscriptionCost: Yup.string().when("regularType", {
    is: false,
    then: Yup.string().required(requiredString),
    otherwise: Yup.string(),
  }),
});

const CommerceSchema = Yup.object().shape({
  commerceName: Yup.string().required(requiredString),
});

const CourtesySubscriptorSchema = Yup.object().shape({
  subscriptionType: Yup.object().shape({
    subscriptionTypeId: Yup.string().required(requiredString),
  }),
  externalId: Yup.string().required(requiredString),
  email: Yup.string().required(requiredString),
  name: Yup.string().required(requiredString),
  fullName: Yup.string().required(requiredString),
});

const PaymentFacilitatorSchema = Yup.object().shape({
  name: Yup.string().required(requiredString),
  paymentFacilitatorId: Yup.string().required(requiredString),
  username: Yup.string().required(requiredString),
  password: Yup.string()
    .required(requiredString)
    .min(6, texts.passwordLength)
    .matches(passwordRegex, passwordFormatString),
});

const schemas = {
  LoginSchema,
  RegisterSchema,
  NewClientSchema,
  UpdateClientSchema,
  ChangePasswordSchema,
  IssuerSchema,
  SubscriptionTypeSchema,
  CommerceSchema,
  CourtesySubscriptorSchema,
  PaymentFacilitatorSchema,
};

export default schemas;

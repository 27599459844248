import { frequences } from "../data/frequences";
import { texts } from "../data/texts";

export const frequencyHelper = {
  getFrequencyName,
};

function getFrequencyName(frequency) {
  switch (frequency) {
    case frequences.DIARY:
      return texts.diary;

    case frequences.WEEKLY:
      return texts.weekly;

    case frequences.MONTHLY:
      return texts.monthly;

    case frequences.BIMONTHLY:
      return texts.bimonthly;

    case frequences.QUATERLY:
      return texts.quaterly;

    case frequences.BIANNUAL:
      return texts.biannual;

    case frequences.ANNUAL:
      return texts.annual;

    default:
      return texts.diary;
  }
}

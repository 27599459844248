import React, { useState } from "react";
import { withStyles, Input, InputAdornment } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../data/colors";
import { texts } from "../../data/texts";

const styles = (theme) => ({
  search: {
    padding: "5px 15px",
    borderRadius: 30,
    border: `1px solid ${colors["grey"]}`,
    color: colors["notBlack"],
    minWidth: 400,
    fontSize: 16,
    fontWeight: 300,
    outline: "none",
  },
  icon: {
    color: colors["darkBlue"],
    fontSize: 16,
  },
});

function Search({ classes, value, setValue, searchFunction }) {
  const [typingTimer, setTypingTimer] = useState(null);
  const doneTypingInterval = 1000;

  const search = () => {
    searchFunction && searchFunction(value);
  };

  const searchChange = (e) => {
    setValue(e.target.value);
  };

  const searchKeyUp = () => {
    clearTimeout(typingTimer);
    setTypingTimer(setTimeout(search, doneTypingInterval));
  };

  return (
    <Input
      className={classes.search}
      placeholder={texts.search}
      value={value}
      onChange={searchChange}
      onKeyUp={searchKeyUp}
      disableUnderline
      endAdornment={
        <InputAdornment>
          <FontAwesomeIcon icon="search" className={classes.icon} />
        </InputAdornment>
      }
    />
  );
}

export default withStyles(styles)(Search);

import * as ActionTypes from "../config/ActionTypes";

export const initialState = {
  isLoading: false,
  errorMessage: null,
  commerces: null,
  commercesClientId: null,
  creatingCommerce: false,
  createCommerceErrorMessage: null,
  createCommerceSuccess: false,
  updatingCommerce: false,
  updateCommerceErrorMessage: null,
  updateCommerceSuccess: false,
  deletingCommerce: false,
  deleteCommerceErrorMessage: null,
  deleteCommerceSuccess: false,
};

export const CommercesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_COMMERCES:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        commerces: action.payload,
      };

    case ActionTypes.COMMERCES_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        commercesClientId: action.payload,
      };

    case ActionTypes.COMMERCES_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
        commerces: null,
      };

    case ActionTypes.ADD_COMMERCE:
      let newCommerces = state.commerces || [];
      newCommerces.push(action.payload);

      return {
        ...state,
        creatingCommerce: false,
        createCommerceErrorMessage: null,
        createCommerceSuccess: true,
        commerces: newCommerces,
      };

    case ActionTypes.CREATING_COMMERCE:
      return {
        ...state,
        creatingCommerce: true,
        createCommerceErrorMessage: null,
        createCommerceSuccess: false,
      };

    case ActionTypes.CREATE_COMMERCE_FAILED:
      return {
        ...state,
        creatingCommerce: false,
        createCommerceErrorMessage: action.payload,
        createCommerceSuccess: false,
      };

    case ActionTypes.UPDATE_COMMERCE:
      let updatedCommerces = state.commerces.map((item, index) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        return {
          ...action.payload,
        };
      });

      return {
        ...state,
        updatingCommerce: false,
        updateCommerceErrorMessage: null,
        updateCommerceSuccess: true,
        commerces: updatedCommerces,
      };

    case ActionTypes.UPDATING_COMMERCE:
      return {
        ...state,
        updatingCommerce: true,
        updateCommerceErrorMessage: null,
        updateCommerceSuccess: false,
      };

    case ActionTypes.UPDATE_COMMERCE_FAILED:
      return {
        ...state,
        updatingCommerce: false,
        updateCommerceErrorMessage: action.payload,
        updateCommerceSuccess: false,
      };

    case ActionTypes.DELETE_COMMERCE:
      let nonDeletedCommerces = state.commerces.filter((item, index) => {
        if (item.id !== action.payload) {
          return item;
        }
        return null;
      });

      return {
        ...state,
        deletingCommerce: false,
        deleteCommerceErrorMessage: null,
        deleteCommerceSuccess: true,
        commerces: nonDeletedCommerces,
      };

    case ActionTypes.DELETING_COMMERCE:
      return {
        ...state,
        deletingCommerce: true,
        deleteCommerceErrorMessage: null,
        deleteCommerceSuccess: false,
      };

    case ActionTypes.DELETE_COMMERCE_FAILED:
      return {
        ...state,
        deletingCommerce: false,
        deleteCommerceErrorMessage: action.payload,
        deleteCommerceSuccess: false,
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, withStyles } from "@material-ui/core";
import { constants } from "../../data/constants";
import { fetchSubscriptions } from "../../store/action_creators/subscriptions.actions";
import { status } from "../../data/status";
import { texts } from "../../data/texts";

const styles = (theme) => ({
  control: {
    minWidth: 160,
    marginRight: 15,
  },
  bigControl: {
    minWidth: 260,
    marginRight: 15,
  },
});

const mapStateToProps = (state) => {
  return {
    subscriptions: state.subscriptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptions: (clientId) => {
    dispatch(fetchSubscriptions(clientId));
  },
});

function TransactionsFilters({
  subscriptions,
  fetchSubscriptions,
  transactions,
  fetchTransactions,
  classes,
  clientId,
}) {
  const [from, setFrom] = useState(transactions.filters.from);
  const [to, setTo] = useState(transactions.filters.to);
  const [minAmount, setMinAmount] = useState(transactions.filters.minAmount);
  const [maxAmount, setMaxAmount] = useState(transactions.filters.maxAmount);
  const [subscriptionTypes, setSubscriptionTypes] = useState(
    transactions.filters.subscriptionTypes.map((st) => {
      return st.id;
    })
  );
  const [transactionState, setTransactionState] = useState(status.ALL);

  const changeFrom = (date) => {
    setFrom(date);
  };

  const changeTo = (date) => {
    setTo(date);
  };

  const applyFilters = () => {
    let objectSubscriptionTypes = subscriptionTypes.map((st) => {
      return subscriptions.subscriptionTypes.filter((s) => s.id === st)[0];
    });

    const filters = {
      from,
      to,
      minAmount,
      maxAmount,
      subscriptionTypes: objectSubscriptionTypes,
      transactionState,
    };

    fetchTransactions({ page: 1, limit: constants.transactionsLimit, filters, reset: true, clientId });
  };

  useEffect(() => {
    if (
      (!subscriptions.subscriptionTypes && !subscriptions.isLoading && !subscriptions.errorMessage) ||
      subscriptions.subscriptionTypesClientId !== clientId
    ) {
      fetchSubscriptions(clientId);
    }
  }, [
    subscriptions.subscriptionTypes,
    subscriptions.isLoading,
    subscriptions.errorMessage,
    subscriptions.subscriptionTypesClientId,
    fetchSubscriptions,
    clientId,
  ]);

  return (
    <div className="transactions-filters">
      <KeyboardDatePicker
        value={from}
        onChange={changeFrom}
        onAccept={changeFrom}
        label={texts.from}
        inputVariant="outlined"
        format="DD/MM/YYYY"
        disableFuture={true}
        className="date-picker"
      />
      <KeyboardDatePicker
        value={to}
        onChange={changeTo}
        onAccept={changeTo}
        label={texts.to}
        inputVariant="outlined"
        format="DD/MM/YYYY"
        disableFuture={true}
        className="date-picker"
      />
      <TextField
        variant="outlined"
        type="number"
        label={texts.minAmount}
        className="number-picker"
        value={minAmount}
        onChange={(e) => setMinAmount(Number(e.target.value))}
      />
      <TextField
        variant="outlined"
        type="number"
        label={texts.maxAmount}
        className="number-picker"
        value={maxAmount}
        onChange={(e) => setMaxAmount(Number(e.target.value))}
      />
      {subscriptions.subscriptionTypes ? (
        <FormControl classes={{ root: classes.bigControl }} variant="outlined">
          <InputLabel id="subscription-type-label">{texts.subscriptionType}</InputLabel>
          <Select
            variant="outlined"
            labelId="subscription-type-label"
            id="subscription-type-label"
            multiple
            value={subscriptionTypes}
            onChange={(e) => setSubscriptionTypes(e.target.value)}
            labelWidth={145}
          >
            {subscriptions.subscriptionTypes.map((st) => {
              return (
                <MenuItem key={st.id} value={st.id}>
                  {st.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
      <FormControl classes={{ root: classes.bigControl }} variant="outlined">
        <InputLabel id="status">{texts.status}</InputLabel>
        <Select
          variant="outlined"
          labelId="status"
          id="status"
          value={transactionState}
          onChange={(e) => setTransactionState(e.target.value)}
          labelWidth={60}
        >
          <MenuItem key={status.ALL} value={status.ALL}>
            {texts.all}
          </MenuItem>
          <MenuItem key={status.OK} value={status.OK}>
            {texts.successful}
          </MenuItem>
          <MenuItem key={status.FAILED} value={status.FAILED}>
            {texts.failed}
          </MenuItem>
        </Select>
      </FormControl>
      <div className="button">
        <Button color="secondary" variant="contained" onClick={applyFilters}>
          {texts.apply}
        </Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TransactionsFilters));

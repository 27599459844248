import React from "react";
import { Snackbar, SnackbarContent, withStyles, Slide } from "@material-ui/core";
import { colors } from "../../data/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = (theme) => ({
  snackbar: {
    maxWidth: 600,
  },
  icon: {
    height: 15,
    width: 15,
    marginRight: 15,
  },
});

//Types
//0 - Success
//1 - Error

const types = [
  {
    id: 0,
    color: colors["green"],
    icon: "check-circle",
  },
  {
    id: 1,
    color: colors["red"],
    icon: "exclamation-circle",
  },
];

function CustomSnackbar({ classes, open, message, autoHide, handleClose, type }) {
  let selectedType = types.filter((t) => {
    if (t.id === type) {
      return t;
    }
    return null;
  })[0];

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide ? autoHide : 3000}
      TransitionComponent={Slide}
      onClose={handleClose}
      classes={{ root: classes.snackbar }}
    >
      <SnackbarContent
        style={{ backgroundColor: selectedType.color }}
        message={
          <span>
            <FontAwesomeIcon icon={selectedType.icon} className={classes.icon} />
            {message}
          </span>
        }
      />
    </Snackbar>
  );
}

export default withStyles(styles)(CustomSnackbar);

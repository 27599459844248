//#region Auth

export const LOGIN = "LOGIN";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const GETTING_ACCOUNT = "GETTING_ACCOUNT";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAILED = "GET_ACCOUNT_FAILED";

export const LOGOUT = "LOGOUT";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGING_PASSWORD = "CHANGING_PASSWORD";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const SUBSCRIPTORS_PAY_OLD_SUCCESS = "SUBSCRIPTORS_PAY_OLD_SUCCESS";
export const PAYING_OLD_SUBSCRIPTORS = "PAYING_OLD_SUBSCRIPTORS";
export const SUBSCRIPTORS_PAY_OLD_FAILED = "SUBSCRIPTORS_PAY_OLD_FAILED";

export const LOADING_CONFIGURATIONS = "LOADING_CONFIGURATIONS";
export const CONFIGURATIONS_SUCCESS = "CONFIGURATIONS_SUCCESS";
export const CONFIGURATIONS_FAILED = "CONFIGURATIONS_FAILED";

//#endregion

//#region Issuers

export const ADD_ISSUERS = "ADD_ISSUERS";
export const ISSUERS_LOADING = "ISSUERS_LOADING";
export const ISSUERS_FAILED = "ISSUERS_FAILED";

export const ADD_PAYMENT_FACILITATOR_ISSUERS = "ADD_PAYMENT_FACILITATOR_ISSUERS";
export const PAYMENT_FACILITATOR_ISSUERS_LOADING = "PAYMENT_FACILITATOR_ISSUERS_LOADING";
export const PAYMENT_FACILITATOR_ISSUERS_FAILED = "PAYMENT_FACILITATOR_ISSUERS_FAILED";

export const ADD_ISSUER = "ADD_ISSUER";
export const CREATING_ISSUER = "CREATING_ISSUER";
export const CREATE_ISSUER_FAILED = "CREATE_ISSUER_FAILED";

export const ADD_PAYMENT_FACILITATOR_ISSUER = "ADD_PAYMENT_FACILITATOR_ISSUER";
export const CREATING_PAYMENT_FACILITATOR_ISSUER = "CREATING_PAYMENT_FACILITATOR_ISSUER";
export const CREATE_PAYMENT_FACILITATOR_ISSUER_FAILED = "CREATE_PAYMENT_FACILITATOR_ISSUER_FAILED";

export const UPDATE_ISSUER = "UPDATE_ISSUER";
export const UPDATING_ISSUER = "UPDATING_ISSUER";
export const UPDATE_ISSUER_FAILED = "UPDATE_ISSUER_FAILED";

export const DELETE_ISSUER = "DELETE_ISSUER";
export const DELETING_ISSUER = "DELETING_ISSUER";
export const DELETE_ISSUER_FAILED = "DELETE_ISSUER_FAILED";

export const DELETE_PAYMENT_FACILITATOR_ISSUER = "DELETE_PAYMENT_FACILITATOR_ISSUER";
export const DELETING_PAYMENT_FACILITATOR_ISSUER = "DELETING_PAYMENT_FACILITATOR_ISSUER";
export const DELETE_PAYMENT_FACILITATOR_ISSUER_FAILED = "DELETE_PAYMENT_FACILITATOR_ISSUER_FAILED";

//#endregion

//#region Payment Facilitators

export const ADD_PAYMENT_FACILITATORS = "ADD_PAYMENT_FACILITATORS";
export const PAYMENT_FACILITATORS_LOADING = "PAYMENT_FACILITATORS_LOADING";
export const PAYMENT_FACILITATORS_FAILED = "PAYMENT_FACILITATORS_FAILED";

export const ADD_PAYMENT_FACILITATOR = "ADD_PAYMENT_FACILITATOR";
export const CREATING_PAYMENT_FACILITATOR = "CREATING_PAYMENT_FACILITATOR";
export const CREATE_PAYMENT_FACILITATOR_FAILED = "CREATE_PAYMENT_FACILITATOR_FAILED";

export const UPDATE_PAYMENT_FACILITATOR = "UPDATE_PAYMENT_FACILITATOR";
export const UPDATING_PAYMENT_FACILITATOR = "UPDATING_PAYMENT_FACILITATOR";
export const UPDATE_PAYMENT_FACILITATOR_FAILED = "UPDATE_PAYMENT_FACILITATOR_FAILED";

//#endregion

//#region Clients

export const ADD_CLIENTS = "ADD_CLIENTS";
export const CLIENTS_LOADING = "CLIENTS_LOADING";
export const CLIENTS_FAILED = "CLIENTS_FAILED";

export const ADD_CLIENT = "ADD_CLIENT";
export const CREATING_CLIENT = "CREATING_CLIENT";
export const CREATE_CLIENT_FAILED = "CREATE_CLIENT_FAILED";

export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATING_CLIENT = "UPDATING_CLIENT";
export const UPDATE_CLIENT_FAILED = "UPDATE_CLIENT_FAILED";

export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETING_CLIENT = "DELETING_CLIENT";
export const DELETE_CLIENT_FAILED = "DELETE_CLIENT_FAILED";

export const UPDATE_CLIENT_SECRET_SUCCESS = "UPDATE_CLIENT_SECRET_SUCCESS";
export const UPDATING_CLIENT_SECRET = "UPDATING_CLIENT_SECRET";
export const UPDATE_CLIENT_SECRET_FAILED = "UPDATE_CLIENT_SECRET_FAILED";

export const UPDATING_CLIENT_NOTIFICATIONS = "UPDATING_CLIENT_NOTIFICATIONS";
export const UPDATE_CLIENT_NOTIFICATIONS_SUCCESS = "UPDATE_CLIENT_NOTIFICATIONS_SUCCESS";
export const UPDATE_CLIENT_NOTIFICATIONS_FAILED = "UPDATE_CLIENT_NOTIFICATIONS_FAILED";

//#endregion

//#region Users

export const ADD_USERS = "ADD_USERS";
export const USERS_LOADING = "USERS_LOADING";
export const USERS_FAILED = "USERS_FAILED";

export const ADD_USER = "ADD_USER";
export const ADDING_USER = "ADDING_USER";
export const ADD_USER_FAILED = "ADD_USER_FAILED";

export const DELETE_USER = "DELETE_USER";
export const DELETING_USER = "DELETING_USER";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

//#endregion

//#region Subscription types

export const ADD_SUBSCRIPTION_TYPES = "ADD_SUBSCRIPTION_TYPES";
export const SUBSCRIPTION_TYPES_LOADING = "SUBSCRIPTION_TYPES_LOADING";
export const SUBSCRIPTION_TYPES_FAILED = "SUBSCRIPTION_TYPES_FAILED";

export const ADD_SUBSCRIPTION_TYPE = "ADD_SUBSCRIPTION_TYPE";
export const CREATING_SUBSCRIPTION_TYPE = "CREATING_SUBSCRIPTION_TYPE";
export const CREATE_SUBSCRIPTION_TYPE_FAILED = "CREATE_SUBSCRIPTION_TYPE_FAILED";

export const UPDATE_SUBSCRIPTION_TYPE = "UPDATE_SUBSCRIPTION_TYPE";
export const UPDATING_SUBSCRIPTION_TYPE = "UPDATING_SUBSCRIPTION_TYPE";
export const UPDATE_SUBSCRIPTION_TYPE_FAILED = "UPDATE_SUBSCRIPTION_TYPE_FAILED";

export const DELETE_SUBSCRIPTION_TYPE = "DELETE_SUBSCRIPTION_TYPE";
export const DELETING_SUBSCRIPTION_TYPE = "DELETING_SUBSCRIPTION_TYPE";
export const DELETE_SUBSCRIPTION_TYPE_FAILED = "DELETE_SUBSCRIPTION_TYPE_FAILED";

//#endregion

//#region Commerces

export const ADD_COMMERCES = "ADD_COMMERCES";
export const COMMERCES_LOADING = "COMMERCES_LOADING";
export const COMMERCES_FAILED = "COMMERCES_FAILED";

export const ADD_COMMERCE = "ADD_COMMERCE";
export const CREATING_COMMERCE = "CREATING_COMMERCE";
export const CREATE_COMMERCE_FAILED = "CREATE_COMMERCE_FAILED";

export const UPDATE_COMMERCE = "UPDATE_COMMERCE";
export const UPDATING_COMMERCE = "UPDATING_COMMERCE";
export const UPDATE_COMMERCE_FAILED = "UPDATE_COMMERCE_FAILED";

export const DELETE_COMMERCE = "DELETE_COMMERCE";
export const DELETING_COMMERCE = "DELETING_COMMERCE";
export const DELETE_COMMERCE_FAILED = "DELETE_COMMERCE_FAILED";

//#endregion

//#region Subscriptors

export const ADD_SUBSCRIPTORS = "ADD_SUBSCRIPTORS";
export const SUBSCRIPTORS_LOADING = "SUBSCRIPTORS_LOADING";
export const SUBSCRIPTORS_FAILED = "SUBSCRIPTORS_FAILED";
export const APPLY_SUBSCRIPTORS_FILTERS = "APPLY_SUBSCRIPTORS_FILTERS";
export const APPLY_COURTESY_SUBSCRIPTORS_FILTERS = "APPLY_COURTESY_SUBSCRIPTORS_FILTERS";

export const ADD_SUBSCRIPTOR = "ADD_SUBSCRIPTOR";
export const SUBSCRIPTOR_LOADING = "SUBSCRIPTOR_LOADING";
export const ADD_SUBSCRIPTOR_FAILED = "ADD_SUBSCRIPTOR_FAILED";

export const ADD_COURTESY_SUBSCRIPTORS = "ADD_COURTESY_SUBSCRIPTORS";
export const COURTESY_SUBSCRIPTORS_LOADING = "COURTESY_SUBSCRIPTORS_LOADING";
export const COURTESY_SUBSCRIPTORS_FAILED = "COURTESY_SUBSCRIPTORS_FAILED";

export const ADD_COURTESY_SUBSCRIPTOR = "ADD_COURTESY_SUBSCRIPTOR";
export const ADDING_COURTESY_SUBSCRIPTOR = "ADDING_COURTESY_SUBSCRIPTOR";
export const ADD_COURTESY_SUBSCRIPTOR_FAILED = "ADD_COURTESY_SUBSCRIPTOR_FAILED";

export const DELETE_COURTESY_SUBSCRIPTOR = "DELETE_COURTESY_SUBSCRIPTOR";
export const DELETING_COURTESY_SUBSCRIPTOR = "DELETING_COURTESY_SUBSCRIPTOR";
export const DELETE_COURTESY_SUBSCRIPTOR_FAILED = "DELETE_COURTESY_SUBSCRIPTOR_FAILED";

export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCELLING_SUBSCRIPTION = "CANCELLING_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_FAILED = "CANCEL_SUBSCRIPTION_FAILED";

//#endregion

//#region Transactions

export const ADD_TRANSACTIONS = "ADD_TRANSACTIONS";
export const TRANSACTIONS_LOADING = "TRANSACTIONS_LOADING";
export const TRANSACTIONS_FAILED = "TRANSACTIONS_FAILED";

export const APPLY_FILTERS = "APPLY_FILTERS";

//#endregion

//#region Reports

export const ADD_DASHBOARD = "ADD_DASHBOARD";
export const DASHBOARD_LOADING = "DASHBOARD_LOADING";
export const DASHBOARD_FAILED = "DASHBOARD_FAILED";

//#endregion

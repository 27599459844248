import * as ActionTypes from "../config/ActionTypes";
import { subscriptionsService } from "../../services/subscriptions.service";

//#region Fetch subscription types

export const fetchSubscriptions = (clientId) => (dispatch) => {
  dispatch(subscriptionTypesLoading(clientId));

  return subscriptionsService.fetchSubscriptionTypes(clientId).then(
    (subscriptionTypes) => {
      dispatch(addSubscriptionTypes(subscriptionTypes));
    },
    (error) => {
      dispatch(subscriptionTypesFailed(error));
    }
  );
};

const addSubscriptionTypes = (subscriptionTypes) => ({
  type: ActionTypes.ADD_SUBSCRIPTION_TYPES,
  payload: subscriptionTypes,
});

const subscriptionTypesLoading = (clientId) => ({
  type: ActionTypes.SUBSCRIPTION_TYPES_LOADING,
  payload: clientId,
});

const subscriptionTypesFailed = (errorMessage) => ({
  type: ActionTypes.SUBSCRIPTION_TYPES_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Create subscription

export const createSubscriptionType =
  ({ name, description, frequence, currency, subscriptionCost, regularType, recurrence }) =>
  (dispatch) => {
    const subscriptionRequest = {
      name,
      description,
      frequence,
      currency,
      subscriptionCost,
      regularType,
      recurrence,
    };

    dispatch(creatingSubscriptionType());

    return subscriptionsService.createSubscriptionType(subscriptionRequest).then(
      (subscription) => {
        dispatch(addSubscriptionType(subscription));
      },
      (error) => {
        dispatch(createSubscriptionTypeFailed(error));
      }
    );
  };

const addSubscriptionType = (subscription) => ({
  type: ActionTypes.ADD_SUBSCRIPTION_TYPE,
  payload: subscription,
});

const creatingSubscriptionType = () => ({
  type: ActionTypes.CREATING_SUBSCRIPTION_TYPE,
});

const createSubscriptionTypeFailed = (errorMessage) => ({
  type: ActionTypes.CREATE_SUBSCRIPTION_TYPE_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Update subscription

export const updateSubscriptionType =
  ({ subscriptionTypeId, name, description, frequence, currency, subscriptionCost, regularType, recurrence }) =>
  (dispatch) => {
    const subscriptionRequest = {
      name,
      description,
      frequence,
      currency,
      subscriptionCost,
      regularType,
      recurrence,
    };

    dispatch(updatingSubscriptionType());

    return subscriptionsService.updateSubscriptionType(subscriptionTypeId, subscriptionRequest).then(
      (subscription) => {
        dispatch(refreshSubscriptionType(subscription));
      },
      (error) => {
        dispatch(updateSubscriptionTypeFailed(error));
      }
    );
  };

const refreshSubscriptionType = (subscription) => ({
  type: ActionTypes.UPDATE_SUBSCRIPTION_TYPE,
  payload: subscription,
});

const updatingSubscriptionType = () => ({
  type: ActionTypes.UPDATING_SUBSCRIPTION_TYPE,
});

const updateSubscriptionTypeFailed = (errorMessage) => ({
  type: ActionTypes.UPDATE_SUBSCRIPTION_TYPE_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Delete subscription

export const deleteSubscriptionType =
  ({ subscriptionTypeId }) =>
  (dispatch) => {
    dispatch(deletingSubscriptionType());

    return subscriptionsService.deleteSubscriptionType(subscriptionTypeId).then(
      (response) => {
        dispatch(removeSubscriptionType(subscriptionTypeId));
      },
      (error) => {
        dispatch(deleteSubscriptionTypeFailed(error));
      }
    );
  };

const removeSubscriptionType = (subscriptionId) => ({
  type: ActionTypes.DELETE_SUBSCRIPTION_TYPE,
  payload: subscriptionId,
});

const deletingSubscriptionType = () => ({
  type: ActionTypes.DELETING_SUBSCRIPTION_TYPE,
});

const deleteSubscriptionTypeFailed = (errorMessage) => ({
  type: ActionTypes.DELETE_SUBSCRIPTION_TYPE_FAILED,
  payload: errorMessage,
});

//#endregion

import * as ActionTypes from "../config/ActionTypes";
import { clientsService } from "../../services/clients.service";
import { deleteUser } from "./users.actions";

//#region Fetch clients

export const fetchClients = () => (dispatch) => {
  dispatch(clientsLoading());

  return clientsService.fetchClients().then(
    (clients) => {
      dispatch(addClients(clients));
    },
    (error) => {
      dispatch(clientsFailed(error));
    }
  );
};

const addClients = (clients) => ({
  type: ActionTypes.ADD_CLIENTS,
  payload: clients,
});

const clientsLoading = () => ({
  type: ActionTypes.CLIENTS_LOADING,
});

const clientsFailed = (errorMessage) => ({
  type: ActionTypes.CLIENTS_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Create client

export const createClient = (clientRequest) => (dispatch) => {
  dispatch(creatingClient());

  return clientsService.createClient(clientRequest).then(
    (client) => {
      dispatch(addClient(client));
    },
    (error) => {
      dispatch(createClientFailed(error));

      dispatch(deleteUser({ userId: clientRequest.adminUserId }));
    }
  );
};

const addClient = (client) => ({
  type: ActionTypes.ADD_CLIENT,
  payload: client,
});

const creatingClient = () => ({
  type: ActionTypes.CREATING_CLIENT,
});

const createClientFailed = (errorMessage) => ({
  type: ActionTypes.CREATE_CLIENT_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Update client

export const updateClient = (updateClientRequest) => (dispatch) => {
  dispatch(updatingClient());

  return clientsService.updateClient(updateClientRequest.clientId, updateClientRequest).then(
    (client) => {
      dispatch(refreshClient(client));
    },
    (error) => {
      dispatch(updateClientFailed(error));
    }
  );
};

const refreshClient = (client) => ({
  type: ActionTypes.UPDATE_CLIENT,
  payload: client,
});

const updatingClient = () => ({
  type: ActionTypes.UPDATING_CLIENT,
});

const updateClientFailed = (errorMessage) => ({
  type: ActionTypes.UPDATE_CLIENT_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Delete client

export const deleteClient =
  ({ clientId, userId }) =>
  (dispatch) => {
    dispatch(deletingClient());

    return clientsService.deleteClient(clientId).then(
      (response) => {
        dispatch(removeClient(clientId));

        dispatch(deleteUser({ userId }));
      },
      (error) => {
        dispatch(deleteClientFailed(error));
      }
    );
  };

const removeClient = (clientId) => ({
  type: ActionTypes.DELETE_CLIENT,
  payload: clientId,
});

const deletingClient = () => ({
  type: ActionTypes.DELETING_CLIENT,
});

const deleteClientFailed = (errorMessage) => ({
  type: ActionTypes.DELETE_CLIENT_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Update client secret

export const updateClientSecret =
  ({ clientSecret, clientId }) =>
  (dispatch) => {
    dispatch(updatingClientSecret());

    return clientsService.updateClientSecret(clientSecret, clientId).then(
      (client) => {
        dispatch(updateClientSecretSuccess(client));
      },
      (error) => {
        dispatch(updateClientSecretFailed(error));
      }
    );
  };

const updateClientSecretSuccess = (client) => ({
  type: ActionTypes.UPDATE_CLIENT_SECRET_SUCCESS,
  payload: client,
});

const updatingClientSecret = () => ({
  type: ActionTypes.UPDATING_CLIENT_SECRET,
});

const updateClientSecretFailed = (errorMessage) => ({
  type: ActionTypes.UPDATE_CLIENT_SECRET_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Update client notifications

export const updateClientNotifications =
  ({ clientId, value }) =>
  (dispatch) => {
    dispatch(updatingClientNotifications());

    return clientsService.updateClientNotifications(clientId, value).then(
      (client) => {
        dispatch(updateClientNotificationsSuccess(client));
      },
      (error) => {
        dispatch(updateClientNotificationsFailed(error));
      }
    );
  };

const updatingClientNotifications = () => ({
  type: ActionTypes.UPDATING_CLIENT_NOTIFICATIONS,
});

const updateClientNotificationsSuccess = () => ({
  type: ActionTypes.UPDATE_CLIENT_NOTIFICATIONS_SUCCESS,
});

const updateClientNotificationsFailed = (errorMessage) => ({
  type: ActionTypes.UPDATE_CLIENT_NOTIFICATIONS_FAILED,
  payload: errorMessage,
});

//#endregion

import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import FormTextField from "../common/forms/FormTextField";
import { login } from "../../store/action_creators/auth.actions";
import { Button, withStyles, Typography } from "@material-ui/core";
import schemas from "../../data/schemas";
import { Redirect, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import logo from "../../assets/logo.png";
import { texts } from "../../data/texts";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  form: {
    padding: 0,
    width: 500,
    border: "1px solid #CCC",
    borderRadius: 10,
    overflow: "hidden",
  },
  title: {
    fontWeight: 400,
    margin: 0,
    marginBottom: 15,
  },
  button: {
    marginTop: 15,
    color: "#FFF",
  },
  error: {
    marginTop: 30,
    color: "red",
  },
  formHeader: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: "15px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "50px",
    },
    "& p": {
      marginTop: 7,
      fontSize: 14,
      fontStyle: "italic",
      color: "#CCC",
    },
  },
  formContent: {
    padding: "30px",
  },
});

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  login: ({ username, password }) => {
    dispatch(login({ username, password }));
  },
});

function Login({ classes, auth, login, cookies }) {
  const submitLogin = (values, { setSubmitting }) => {
    login({
      username: values.username.trim(),
      password: values.password.trim(),
    });

    setSubmitting(false);
  };
  if (auth.loggedIn || cookies.cookies.accessToken) {
    return <Redirect to={auth.user?.type !== "1" ? "/clients" : "/dashboard"} />;
  } else {
    return (
      <div className={classes.container}>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={schemas.LoginSchema}
          onSubmit={submitLogin}
        >
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              <div className={classes.formHeader}>
                <img src={logo} alt={texts.plexoLogo} />
                <Typography>{texts.subscriptionsModule}</Typography>
              </div>
              <div className={classes.formContent}>
                <Field label="Usuario" name="username" component={FormTextField} />
                <Field label="Contraseña" name="password" type="password" component={FormTextField} />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {texts.loginButtonText}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {auth.errorMessage ? <div className={classes.error}>{texts.loginFailed}</div> : null}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withCookies(Login))));

import React, { useState } from "react";
import { Grow, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentFacilitatorForm from "./PaymentFacilitatorForm";

function PaymentFacilitator({ position, data }) {
  const [open, setOpen] = useState(false);

  return (
    <Grow in={true} timeout={300 * position}>
      <div className="payment-facilitator-container">
        <div className="payment-facilitator">
          <div className="data">
            <span className="name">{`${data.paymentFacilitatorPlexoId} - ${data.name}`}</span>
          </div>
          <div className="actions">
            <IconButton onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon="edit" className="edit-icon" />
            </IconButton>
          </div>
        </div>
        <PaymentFacilitatorForm open={open} setOpen={setOpen} selectedPaymentFacilitator={data} />
      </div>
    </Grow>
  );
}

export default PaymentFacilitator;

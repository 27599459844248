import * as ActionTypes from "../config/ActionTypes";
import { usersService } from "../../services/users.service";

//#region Fetch users

export const fetchUsers = () => dispatch => {
  dispatch(usersLoading());

  return usersService.fetchUsers().then(
    users => {
      dispatch(addUsers(users));
    },
    error => {
      dispatch(usersFailed(error));
    }
  );
};

const addUsers = users => ({
  type: ActionTypes.ADD_USERS,
  payload: users
});

const usersLoading = () => ({
  type: ActionTypes.USERS_LOADING
});

const usersFailed = errorMessage => ({
  type: ActionTypes.USERS_FAILED,
  payload: errorMessage
});

//#endregion

//#region Delete user

export const deleteUser = ({ userId }) => dispatch => {
  dispatch(deletingUser());

  return usersService.deleteUser(userId).then(
    response => {
      dispatch(removeUser(userId));
    },
    error => {
      dispatch(deleteUserFailed(error));
    }
  );
};

const removeUser = userId => ({
  type: ActionTypes.DELETE_USER,
  payload: userId
});

const deletingUser = () => ({
  type: ActionTypes.DELETING_USER
});

const deleteUserFailed = errorMessage => ({
  type: ActionTypes.DELETE_USER_FAILED,
  payload: errorMessage
});

//#endregion

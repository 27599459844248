import React from "react";
import { connect } from "react-redux";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import Loading from "../common/animations/Loading";
import moment from "moment";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => ({
  reports: state.reports,
});

function TotalSubscriptionsChart({ reports }) {
  if (!reports.dashboard) {
    return <Loading />;
  } else if (reports.dashboard.historicTotalSubscriptors.length === 0) {
    return <p>{texts.noDataForReport}</p>;
  } else {
    return (
      <AreaChart
        width={800}
        height={400}
        data={reports.dashboard.historicTotalSubscriptors.map((t) => {
          return {
            Mes: moment(t.item1)
              .subtract(1, "month")
              .format("MMMM YYYY")
              .replace(/^\w/, function (chr) {
                return chr.toUpperCase();
              }),
            Suscriptores: t.item2,
          };
        })}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={"#256FB5"} stopOpacity={0.8} />
            <stop offset="100%" stopColor={"#256FB5"} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey={texts.month} dy={5} />
        <YAxis dataKey={texts.subscriptors} dx={-5} />
        <CartesianGrid strokeDasharray="5 5" />
        <Tooltip cursor={false} itemStyle={{ textTransform: "capitalize" }} itemSorter={() => 1} />
        <Area
          type="monotone"
          dataKey={texts.subscriptors}
          stroke={"#256FB5"}
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </AreaChart>
    );
  }
}

export default connect(mapStateToProps, null)(TotalSubscriptionsChart);

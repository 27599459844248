import React from 'react'
import { Grow, Tooltip } from '@material-ui/core'
import moment from 'moment'
import { statusHelper } from '../../helpers/statusHelper'
import { stringHelper } from '../../helpers/stringHelper'
import { status } from '../../data/status'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

function TransactionRow({ position, data, limit, billing }) {
  return (
    <Grow in={true} direction="left" timeout={100 * (position % limit)}>
      <div className="transaction-row">
        <div className="column">
          {moment(data.createdAt).format('DD/MM/YYYY HH:mm')}
        </div>
        <div className="column">{data.subscription.subscriptionType?.name}</div>
        <div className="column">
          <img src={data.instrument?.issuerImageUrl} alt="issuer" />
          {stringHelper.getMaskedInstrument(data.instrument?.name)}
        </div>
        <div className="column">{data.subscriptor?.fullName}</div>
        <div className="column">{data.subscriptor?.externalId}</div>
        <div className="column">{data.amount}</div>
        <div className="column">
          {`${statusHelper.getStatusName(data.state)}`}{' '}
          {data.state === status.FAILED && data.errorText !== null && (
            <Tooltip style={{ marginLeft: '4px' }} title={data.errorText}>
              <InfoOutlinedIcon color="primary" fontSize="small" />
            </Tooltip>
          )}
        </div>
        {billing && (
          <div className="column">
            {data.electronicBill !== null
              ? data.electronicBill.internalId
              : '-'}
          </div>
        )}
      </div>
    </Grow>
  )
}

export default TransactionRow

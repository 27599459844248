import React, { useState } from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import FormTextField from "../forms/FormTextField";
import { Formik, Form, Field } from "formik";
import schemas from "../../../data/schemas";
import { changePassword } from "../../../store/action_creators/auth.actions";
import CustomSnackbar from "../CustomSnackbar";
import { texts } from "../../../data/texts";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: ({ password, newPassword, repeatNewPassword }) => {
    dispatch(changePassword({ password, newPassword, repeatNewPassword }));
  },
});

function ChangePasswordDialog({ open, setOpen, auth, changePassword }) {
  const [changing, setChanging] = useState(false);

  const changePasswordOk = auth.changePasswordSuccess;

  const submitChangePassword = (values, { setSubmitting }) => {
    changePassword({
      password: values.password,
      newPassword: values.newPassword,
      repeatNewPassword: values.repeatNewPassword,
    });

    setChanging(true);
    setSubmitting(false);
  };

  const closeSnack = () => {
    setChanging(false);

    if (changePasswordOk) {
      setOpen(false);
    }
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
        <Formik
          initialValues={{ password: "", newPassword: "", repeatNewPassword: "" }}
          validationSchema={schemas.ChangePasswordSchema}
          onSubmit={submitChangePassword}
        >
          {({ isSubmitting }) => (
            <Form className="form">
              <DialogTitle>{texts.changePassword}</DialogTitle>
              <DialogContent>
                <Field type="password" label={texts.actualPassword} name="password" component={FormTextField} />
                <Field type="password" label={texts.newPassword} name="newPassword" component={FormTextField} />
                <Field
                  type="password"
                  label={texts.repeatNewPassword}
                  name="repeatNewPassword"
                  component={FormTextField}
                />
                <p style={{ fontSize: "14px" }}>
                  La nueva contraseña deberá tener al menos una letra mayúscula, una letra minúscula y un número
                </p>
              </DialogContent>
              <DialogActions>
                <Button
                  className="save"
                  color="secondary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    margin: "0 15px 15px 0",
                    fontSize: 16,
                  }}
                >
                  {texts.changePassword}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <CustomSnackbar
        open={changing && (auth.changePasswordSuccess || auth.changePasswordErrorMessage)}
        message={changePasswordOk ? texts.changePasswordSuccess : texts.changePasswordError}
        handleClose={closeSnack}
        type={changePasswordOk ? 0 : 1}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordDialog);

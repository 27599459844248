import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment, Tooltip, IconButton } from "@material-ui/core";
import { colors } from "../../../data/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const styles = (theme) => ({
  error: {
    color: colors["errorRed"],
    fontSize: 15,
    marginBottom: 5,
  },
  errorIcon: {
    color: colors["errorRed"],
    fontSize: 15,
    cursor: "pointer",
  },
  tooltip: {
    backgroundColor: colors["errorRed"],
    fontSize: 15,
    padding: "5px 15px",
  },
  input: {
    fontSize: 15,
    color: colors["notBlack"],
  },
});

function FormTextField({ field, form: { touched, errors }, classes, noFullWidth, ...props }) {
  const hasError = touched[field.name] && errors[field.name];
  const isPassword = props["type"] === "password";
  const [type, setType] = useState(props.type);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setType(!showPassword ? "text" : "password");
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const errorAdornment = (
    <Tooltip title={errors[field.name]} classes={{ tooltip: classes.tooltip }}>
      <InputAdornment position="end">
        <FontAwesomeIcon icon="exclamation-circle" className={classes.errorIcon} />
      </InputAdornment>
    </Tooltip>
  );

  const passwordAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  let adornments = [];
  if (isPassword) {
    adornments.push(passwordAdornment);
  }

  if (hasError) {
    adornments.push(errorAdornment);
  }

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth={!noFullWidth}
      error={hasError ? true : false}
      InputProps={{
        classes: {
          input: classes.input,
        },
        endAdornment: (
          <>
            {adornments.map((adornment) => {
              return adornment;
            })}
          </>
        ),
      }}
      {...field}
      {...props}
      type={type}
    />
  );
}

export default withStyles(styles)(FormTextField);

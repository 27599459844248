import { baseService } from "./base.service";
import { URL, httpVerbs } from "./config";

export const paymentFacilitatorsService = {
  fetchPaymentFacilitators,
  createPaymentFacilitator,
  updatePaymentFacilitator,
  addPFCommerceId,
  removePFCommerceId,
};

async function fetchPaymentFacilitators() {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  return await fetch(`${URL}/paymentFacilitators`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function createPaymentFacilitator(paymentFacilitatorRequest) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(paymentFacilitatorRequest),
  };

  return await fetch(`${URL}/paymentFacilitators`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function updatePaymentFacilitator(paymentFacilitatorId, paymentFacilitatorRequest) {
  const requestOptions = {
    method: httpVerbs.Put,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(paymentFacilitatorRequest),
  };

  return await fetch(`${URL}/paymentFacilitators/${paymentFacilitatorId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function addPFCommerceId(addPFCommerceRequest) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(addPFCommerceRequest),
  };

  return await fetch(`${URL}/paymentFacilitators/pfCommerces`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function removePFCommerceId(removePFCommerceRequest) {
  const requestOptions = {
    method: httpVerbs.Delete,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(removePFCommerceRequest),
  };

  return await fetch(`${URL}/paymentFacilitators/pfCommerces`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { measures } from "../data/measures";
import { Route, Switch, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import Issuers from "./issuers/Issuers";
import Clients from "./clients/Clients";
import Subscriptions from "./subscriptions/Subscriptions";
import Commerces from "./commerces/Commerces";
import Login from "./auth/Login";
import { loginUser, getAccount } from "../store/action_creators/auth.actions";
import PrivateRoute from "./common/PrivateRoute";
import Dashboard from "./dashboard/Dashboard";
import Transactions from "./transactions/Transactions";
import Subscriptors from "./subscriptors/Subscriptors";
import Configuration from "./configuration/Configuration";
import CourtesySubscriptors from "./courtesy/CourtesySubscriptors";
import { adminTypes } from "../data/adminTypes";
import Loading from "./common/animations/Loading";
import PaymentFacilitators from "./paymentFacilitators/PaymentFacilitators";
import PaymentFacilitatorIssuers from "./paymentFacilitatorIssuers/PaymentFacilitatorIssuers";
import ClientDetails from "./clients/ClientDetails";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    marginTop: measures["headerHeight"],
    padding: theme.spacing(3),
    marginLeft: measures["expandedWidth"],
  },
  loginForm: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginUser: ({ user }) => {
    dispatch(loginUser({ user }));
  },
  getAccount: () => {
    dispatch(getAccount());
  },
});

function Main({ classes, loginUser, getAccount, cookies, history, auth }) {
  const isLoggedIn = auth.loggedIn;

  useEffect(() => {
    if (!isLoggedIn && cookies.cookies.accessToken && !auth.gettingAccount && auth.getAccountErrorMessage === null) {
      getAccount();
    }
    if (isLoggedIn && (history.location.pathname === "/" || history.location.pathname === "/backoffice")) {
      if (auth.user.userType === "1") {
        history.push("/dashboard");
      } else {
        history.push("/clients");
      }
    }
  }, [
    isLoggedIn,
    history,
    cookies,
    loginUser,
    auth.user,
    auth.gettingAccount,
    auth.getAccountErrorMessage,
    getAccount,
  ]);

  return (
    <div className={classes.root}>
      {isLoggedIn ? <Header /> : null}
      {isLoggedIn ? <Sidebar /> : null}
      <main className={isLoggedIn ? classes.content : classes.loginForm}>
        {auth.gettingAccount ? (
          <Loading />
        ) : (
          <Switch>
            <PrivateRoute
              path="/clients/:clientId"
              roles={[adminTypes.SUPERADMIN, adminTypes.PFADMIN]}
              component={ClientDetails}
            />
            <PrivateRoute path="/clients" roles={[adminTypes.SUPERADMIN, adminTypes.PFADMIN]} component={Clients} />
            <PrivateRoute path="/issuers" roles={[adminTypes.SUPERADMIN]} component={Issuers} />
            <PrivateRoute path="/pfIssuers" roles={[adminTypes.PFADMIN]} component={PaymentFacilitatorIssuers} />
            <PrivateRoute path="/facilitators" roles={[adminTypes.SUPERADMIN]} component={PaymentFacilitators} />
            <PrivateRoute
              path="/configuration"
              roles={[adminTypes.SUPERADMIN, adminTypes.CLIENTADMIN, adminTypes.PFADMIN]}
              component={Configuration}
            />
            <PrivateRoute path="/subscriptions" roles={[adminTypes.CLIENTADMIN]} component={Subscriptions} />
            <PrivateRoute path="/commerces" roles={[adminTypes.CLIENTADMIN]} component={Commerces} />
            <PrivateRoute path="/transactions" roles={[adminTypes.CLIENTADMIN]} component={Transactions} />
            <PrivateRoute path="/subscriptors" roles={[adminTypes.CLIENTADMIN]} component={Subscriptors} />
            <PrivateRoute path="/dashboard" roles={[adminTypes.CLIENTADMIN]} component={Dashboard} />
            <PrivateRoute path="/courtesy" roles={[adminTypes.CLIENTADMIN]} component={CourtesySubscriptors} />
            <Route path="/login" component={Login} />
            <Route component={Login} />
          </Switch>
        )}
      </main>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withCookies(Main))));

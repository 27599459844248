import * as ActionTypes from "../config/ActionTypes";

export const initialState = {
  isLoading: false,
  errorMessage: null,
  currentPage: 1,
  pageCount: 0,
  courtesySubscriptorsLoading: false,
  courtesySubscriptorsErrorMessage: null,
  courtesySubscriptors: null,
  addingCourtesySubscriptor: false,
  addCourtesySubscriptorSuccess: false,
  addCourtesySubscriptorErrorMessage: null,
  deletingCourtesySubscriptor: false,
  deleteCourtesySubscriptorSuccess: false,
  deleteCourtesySubscriptorErrorMessage: null,
  filters: {
    state: 1,
    subscriptionTypes: [],
  },
  search: "",
};

export const CourtesySubscriptorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.APPLY_COURTESY_SUBSCRIPTORS_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
        search: action.payload.search,
        courtesySubscriptors: [],
      };

    case ActionTypes.ADD_COURTESY_SUBSCRIPTORS:
      return {
        ...state,
        courtesySubscriptorsLoading: false,
        courtesySubscriptorsErrorMessage: null,
        courtesySubscriptors: action.payload.results,
      };

    case ActionTypes.COURTESY_SUBSCRIPTORS_LOADING:
      return {
        ...state,
        courtesySubscriptorsLoading: true,
        courtesySubscriptorsErrorMessage: null,
        courtesySubscriptors: null,
      };

    case ActionTypes.COURTESY_SUBSCRIPTORS_FAILED:
      return {
        ...state,
        courtesySubscriptorsLoading: false,
        courtesySubscriptorsErrorMessage: action.payload,
        courtesySubscriptors: null,
      };

    case ActionTypes.ADD_COURTESY_SUBSCRIPTOR:
      const newSubscriptor = {
        ...action.payload.subscriptorRequest.addSubscriptorRequest,
        id: action.payload.subscriptorResponse.subscriptorId,
        subscriptions: [
          {
            ...action.payload.subscriptorResponse.courtesySubscription,
            subscriptionType: { id: action.payload.subscriptorRequest.subscriptionTypeId },
          },
        ],
      };

      let theNewSubscriptors = state.subscriptors ? state.subscriptors : [];
      theNewSubscriptors.push(newSubscriptor);

      let newCourtesySubscriptors = state.courtesySubscriptors ? state.courtesySubscriptors : [];
      newCourtesySubscriptors.push(newSubscriptor);

      return {
        ...state,
        subscriptors: theNewSubscriptors,
        courtesySubscriptors: newCourtesySubscriptors,
        addingCourtesySubscriptor: false,
        addCourtesySubscriptorErrorMessage: null,
        addCourtesySubscriptorSuccess: true,
      };

    case ActionTypes.ADDING_COURTESY_SUBSCRIPTOR:
      return {
        ...state,
        addingCourtesySubscriptor: true,
        addCourtesySubscriptorErrorMessage: null,
        addCourtesySubscriptorSuccess: false,
      };

    case ActionTypes.ADD_COURTESY_SUBSCRIPTOR_FAILED:
      return {
        ...state,
        addingCourtesySubscriptor: false,
        addCourtesySubscriptorErrorMessage: action.payload,
        addCourtesySubscriptorSuccess: false,
      };

    case ActionTypes.DELETE_COURTESY_SUBSCRIPTOR:
      let nonDeletedSubscriptors = state.courtesySubscriptors.filter((item, index) => {
        if (item.externalId !== action.payload) {
          return item;
        }
        return null;
      });

      return {
        ...state,
        courtesySubscriptors: nonDeletedSubscriptors,
        deletingCourtesySubscriptor: false,
        deleteCourtesySubscriptorErrorMessage: null,
        deleteCourtesySubscriptorSuccess: true,
      };

    case ActionTypes.DELETING_COURTESY_SUBSCRIPTOR:
      return {
        ...state,
        deletingCourtesySubscriptor: true,
        deleteCourtesySubscriptorErrorMessage: null,
        deleteCourtesySubscriptorSuccess: false,
      };

    case ActionTypes.DELETE_COURTESY_SUBSCRIPTOR_FAILED:
      return {
        ...state,
        deletingCourtesySubscriptor: false,
        deleteCourtesySubscriptorErrorMessage: action.payload,
        deleteCourtesySubscriptorSuccess: false,
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

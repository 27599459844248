import React from "react";
import { texts } from "../../data/texts";

function SubscriptorTitle({ isCourtesy }) {
  return (
    <div className="subscriptor-title">
      <div className="column">{texts.externalId}</div>
      <div className="column">{texts.subscriptionDate}</div>
      <div className="column">{texts.fullName}</div>
      <div className="column">{texts.email}</div>
      {isCourtesy ? <div className="column">{texts.validUntil}</div> : null}
      {isCourtesy ? <div className="column">{texts.actions}</div> : null}
    </div>
  );
}

export default SubscriptorTitle;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Notifications from "./ConfigurationAdmin/Notifications";
import { fetchPFConfigurations, updatePFConfigurations } from "../../store/action_creators/auth.actions";
import CustomSnackbar from "../common/CustomSnackbar";
import { Chip, IconButton, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { paymentFacilitatorsService } from "../../services/paymentFacilitators.service";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPFConfigurations: ({ pfId }) => {
    dispatch(fetchPFConfigurations({ pfId }));
  },
  updatePFConfigurations: ({ paymentFacilitatorId, configurations }) => {
    dispatch(updatePFConfigurations({ paymentFacilitatorId, configurations }));
  },
});

function ConfigurationPFAdmin({ auth, fetchPFConfigurations, updatePFConfigurations }) {
  const [loaded, setLoaded] = useState(false);
  const [updatingConfiguration, setUpdatingConfiguration] = useState(false);
  const [commerceId, setCommerceId] = useState("");
  const [list, setList] = useState([]);

  const addCommerceId = () => {
    if (commerceId !== "" && Number.parseInt(commerceId) && Number.parseInt(commerceId) > 0) {
      const request = {
        pfId: auth.user.paymentFacilitatorDetails.id,
        pfCommerceId: commerceId,
      };

      paymentFacilitatorsService.addPFCommerceId(request).then(
        (response) => {
          const newList = list;
          newList.push(commerceId);
          setList([...newList]);
          setCommerceId("");
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const removeCommerceId = (commerceId) => {
    const request = {
      pfId: auth.user.paymentFacilitatorDetails.id,
      pfCommerceId: commerceId,
    };

    paymentFacilitatorsService.removePFCommerceId(request).then(
      (response) => {
        const nonDeletedItems = list.filter((c) => c !== commerceId);
        setList([...nonDeletedItems]);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    if (
      !auth.configurations &&
      !auth.loadingConfigurations &&
      auth.configurationsErrorMessage === null &&
      auth.user &&
      auth.user.paymentFacilitatorDetails
    ) {
      fetchPFConfigurations({ pfId: auth.user.paymentFacilitatorDetails.id });
    }

    if (auth.configurations && !loaded) {
      setLoaded(true);
      setList(auth.user.paymentFacilitatorDetails.paymentFacilitatorCommerceIds);
    }
  }, [
    auth.user,
    auth.paymentsCallbackUrl,
    fetchPFConfigurations,
    auth.loadingConfigurations,
    auth.configurationsErrorMessage,
    auth.configurations,
    loaded,
    setLoaded,
  ]);

  const closeSnack = () => {
    setUpdatingConfiguration(false);
  };

  const updateConfigurations = ({ clientId, configurations }) => {
    updatePFConfigurations({ paymentFacilitatorId: auth.user.paymentFacilitatorDetails.id, configurations });
  };

  return (
    <div className="admin-configuration">
      <Notifications
        auth={auth}
        updateConfigurations={updateConfigurations}
        setUpdatingConfiguration={setUpdatingConfiguration}
      />
      <div className="">
        <h4>{texts.commerceIds}</h4>
        <TextField
          margin="normal"
          variant="outlined"
          style={{ width: "50%" }}
          value={commerceId}
          onChange={(e) => setCommerceId(e.target.value)}
          label={texts.commerceId}
          type="number"
          InputProps={{
            inputProps: {
              min: "1",
            },
            endAdornment: (
              <IconButton onClick={() => addCommerceId()}>
                <AddIcon />
              </IconButton>
            ),
          }}
        />
        <br />
        {list.map((id, index) => {
          return (
            <Chip key={`${id}-${index}`} label={id} style={{ marginRight: 8 }} onDelete={() => removeCommerceId(id)} />
          );
        })}
      </div>
      <CustomSnackbar
        open={updatingConfiguration && (auth.configurationsSuccess || auth.configurationsErrorMessage)}
        message={auth.configurationsSuccess ? texts.configurationsUpdateSuccess : texts.configurationsUpdateError}
        type={auth.configurationsSuccess ? 0 : 1}
        handleClose={closeSnack}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationPFAdmin);

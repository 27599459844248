import { httpVerbs, URL } from "./config";
import { baseService } from "./base.service";

export const usersService = {
  fetchUsers,
  deleteUser
};

async function fetchUsers() {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true)
  };

  return await fetch(`${URL}/users`, requestOptions)
    .then(baseService.handleResponse)
    .then(response => {
      return response;
    });
}

async function deleteUser(userId) {
  const requestOptions = {
    method: httpVerbs.Delete,
    headers: baseService.getHeaders(true)
  };

  return await fetch(`${URL}/users/${userId}`, requestOptions)
    .then(baseService.handleResponse)
    .then(response => {
      return response;
    });
}

import Cookies from "universal-cookie";
import { cookieHelper } from "../helpers/cookieHelper";

export const baseService = {
  getHeaders,
  handleResponse,
};

function getHeaders(hasAuth, externalId) {
  let ret = new Headers({
    "Content-Type": "application/json",
  });

  let cookieService = new Cookies();

  if (hasAuth) {
    ret.append("Authorization", `Bearer ${cookieService.get("accessToken")}`);
  }

  if (externalId) {
    ret.append("externalId", externalId);
  }

  return ret;
}

function handleResponse(response) {
  return response.text().then((text) => {
    try {
      JSON.parse(text);
    } catch (error) {
      if (response.statusText === "OK" || response.status === 200) {
        return response;
      } else if (response.status === 204) {
        return [];
      } else if (response.status === 401) {
        cookieHelper.removeCookies();
        // alert("Tu sesión ha expirado");
        window.location.reload();
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401 && !cookieHelper.getAllCookies["accessToken"]) {
        cookieHelper.removeCookies();
        // alert("Tu sesión ha expirado");
        window.location.reload();
        return Promise.reject("error");
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { colors } from "../../../data/colors";
import { texts } from "../../../data/texts";

function DeleteDialog({ open, setOpen, text, submit }) {
  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={() => setOpen(false)}>
      <DialogTitle>{texts.delete}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Button
          className="save"
          variant="contained"
          onClick={submit}
          style={{
            backgroundColor: colors["red"],
            color: "#FFF",
            margin: "0 15px 15px 0",
            fontSize: 16,
          }}
        >
          {texts.delete}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;

import * as ActionTypes from "../config/ActionTypes";
import { commercesService } from "../../services/commerces.service";

//#region Fetch commerces

export const fetchCommerces = (clientId) => (dispatch) => {
  dispatch(commercesLoading(clientId));

  return commercesService.fetchCommerces(clientId).then(
    (commerces) => {
      dispatch(addCommerces(commerces));
    },
    (error) => {
      dispatch(commercesFailed(error));
    }
  );
};

const addCommerces = (commerces) => ({
  type: ActionTypes.ADD_COMMERCES,
  payload: commerces,
});

const commercesLoading = (clientId) => ({
  type: ActionTypes.COMMERCES_LOADING,
  payload: clientId,
});

const commercesFailed = (errorMessage) => ({
  type: ActionTypes.COMMERCES_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Create commerce

export const createCommerce =
  ({ commerceName, commerceNumber, commerceTerminalNumber, issuerId, clientId }) =>
  (dispatch) => {
    const commerceRequest = {
      commerceName,
      commerceNumber,
      commerceTerminalNumber,
      issuerId,
    };

    dispatch(creatingCommerce());

    return commercesService.createCommerce(commerceRequest, clientId).then(
      (commerce) => {
        dispatch(addCommerce(commerce));
      },
      (error) => {
        dispatch(createCommerceFailed(error));
      }
    );
  };

const addCommerce = (commerce) => ({
  type: ActionTypes.ADD_COMMERCE,
  payload: commerce,
});

const creatingCommerce = () => ({
  type: ActionTypes.CREATING_COMMERCE,
});

const createCommerceFailed = (errorMessage) => ({
  type: ActionTypes.CREATE_COMMERCE_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Update commerce

export const updateCommerce =
  ({ commerceId, name, normalizedName, pictureUri, plexoId, active }) =>
  (dispatch) => {
    const commerceRequest = {
      name,
      normalizedName,
      pictureUri,
      plexoId,
      active,
    };

    dispatch(updatingCommerce());

    return commercesService.updateCommerce(commerceId, commerceRequest).then(
      (commerce) => {
        dispatch(refreshCommerce(commerce));
      },
      (error) => {
        dispatch(updateCommerceFailed(error));
      }
    );
  };

const refreshCommerce = (commerce) => ({
  type: ActionTypes.UPDATE_COMMERCE,
  payload: commerce,
});

const updatingCommerce = () => ({
  type: ActionTypes.UPDATING_COMMERCE,
});

const updateCommerceFailed = (errorMessage) => ({
  type: ActionTypes.UPDATE_COMMERCE_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Delete commerce

export const deleteCommerce =
  ({ issuerId, clientId }) =>
  (dispatch) => {
    dispatch(deletingCommerce());

    return commercesService.deleteCommerce(issuerId, clientId).then(
      (response) => {
        dispatch(removeCommerce(issuerId));
      },
      (error) => {
        dispatch(deleteCommerceFailed(error));
      }
    );
  };

const removeCommerce = (issuerId) => ({
  type: ActionTypes.DELETE_COMMERCE,
  payload: issuerId,
});

const deletingCommerce = () => ({
  type: ActionTypes.DELETING_COMMERCE,
});

const deleteCommerceFailed = (errorMessage) => ({
  type: ActionTypes.DELETE_COMMERCE_FAILED,
  payload: errorMessage,
});

//#endregion

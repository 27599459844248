export const texts = {
  subscriptionsModule: "Módulo de suscripciones",
  loginButtonText: "Iniciar sesión",
  loginFailed: "No se pudo iniciar sesión con las credenciales provistas",
  plexoLogo: "Logo de Plexo",
  backToList: "Volver al listado",
  actionsOnClient: "Acciones sobre el cliente",
  configureClientSecret: "Configurar nuevo client secret",
  clientStatistics: "Estadísticas del cliente",
  clients: "Clientes",
  newClient: "Nuevo cliente",
  editClient: "Editar cliente",
  deleteClient: "Eliminar cliente",
  fetchClientsError: "Ocurrió un error al obtener los clientes",
  clientDeletionSuccess: "El cliente se eliminó correctamente",
  clientDeletionError: "Ocurrió un error al eliminar el cliente",
  clientCreationSuccess: "El cliente se creó correctamente",
  clientCreationError: "Ocurrió un error al crear el cliente: ",
  clientEditionSuccess: "El cliente se actualizó correctamente",
  clientEditionError: "Ocurrió un error al modificar el cliente: ",
  clientAdminCreationError: "Ocurrió un error al crear el administrador del cliente:",
  commerces: "Comercios",
  newCommerce: "Nuevo comercio",
  noCommercesText: "Aún no hay comercios agregados",
  commerceDeletionQuestion: "¿Seguro que deseas borrar el comercio?",
  commerceDeletionSuccess: "El comercio se eliminó correctamente",
  commerceDeletionError: "Ocurrió un error al eliminar el comercio",
  commerceCreationSuccess: "El comercio se creó correctamente",
  commerceCreationError: "Ocurrió un error al crear el comercio",
  commerceNumber: "Número de comercio",
  terminalNumber: "Número de terminal",
  transactions: "Transacciones",
  exportTransactions: "Exportar transacciones",
  noTransactionsForFilter: "No existen transacciones para el filtro aplicado",
  assignedCommerceId: "Id de comercio asignado",
  commerceIdError: "Debes elegir un id de comercio",
  allowAddCommerces: "Permitir agregar comercios:",
  clientSecret: "Client secret:",
  generateClientSecret: "Generar client secret",
  generateNewClientSecret: "Generar nuevo client secret",
  newClientSecret: "El nuevo client secret será",
  clientSecretUpdateSuccess: "Se actualizó el client secret correctamente",
  clientSecretUpdateError: "Ocurrió un error al cambiar el client secret",
  adminData: "Datos del administrador",
  activateElectronicBilling: "Activar facturación electrónica",
  user: "Usuario",
  admin: "Administrador",
  save: "Guardar",
  cancel: "Cancelar",
  confirm: "Confirmar",
  seeDetails: "Ver detalles",
  exit: "Salir",
  search: "Buscar",
  subscriptionType: "Tipo de suscripción",
  externalId: "Id externo",
  name: "Nombre",
  fullName: "Nombre completo",
  email: "Email",
  legalName: "Nombre legal",
  actions: "Acciones",
  paymentMethod: "Medio de pago",
  requiredField: "Campo requerido",
  retry: "Reintentar",
  configuration: "Configuración",
  passwordManagement: "Gestión de contraseña",
  changePassword: "Cambiar contraseña",
  actualPassword: "Contraseña actual",
  newPassword: "Nueva contraseña",
  repeatNewPassword: "Repetir nueva contraseña",
  changePasswordSuccess: "Se cambió la contraseña correctamente",
  changePasswordError: "Ocurrió un error al cambiar la contraseña",
  delete: "Borrar",
  logout: "Cerrar sesión",
  logoutQuestion: "¿Estás seguro de que deseas cerrar sesión?",
  retrySchema: "Esquema de reintentos",
  traditionalSchema: "Tradicional",
  weeklySchema: "Semanal",
  configurationsUpdateSuccess: "Se han actualizado las configuraciones",
  configurationsUpdateError: "Ocurrió un error al actualizar las configuraciones",
  notificationManagement: "Gestión de notificaciones",
  notificationsActive: "Notificaciones activadas",
  enterUrl: "Ingresar url",
  update: "Actualizar",
  apply: "Aplicar",
  payloadQuestion: "¿Para qué sirve este campo?",
  payloadExplanationText:
    "Si se completa este campo, se comunicará el éxito o fallo de los pagos recurrentes enviando un objeto del siguiente tipo mediante una petición POST a la url indicada:",
  commerceIds: "Ids de comercio",
  commerceId: "Id de comercio",
  expiredSubscriptionsManagement: "Gestión de suscripciones vencidas",
  retryPayments: "Reintentar pagos",
  noPendingPayments: "No se encontraron pagos pendientes.",
  expiredSubscriptionsPaymentError: "Ocurrió un error al pagar las suscripciones vencidas.",
  limitless: "Sin límite",
  oneMonth: "1 mes",
  twoMonths: "2 meses",
  threeMonths: "3 meses",
  fourMonths: "4 meses",
  sixMonths: "6 meses",
  twelveMonths: "12 meses",
  monthsAmount: "Cantidad de meses",
  courtesySubscriptors: "Suscriptores de cortesía",
  newCourtesySubscriptor: "Nuevo suscriptor de cortesía",
  courtesySubscriptorsError: "Ocurrió un error al obtener los suscriptores",
  noCourtesySubscriptorsText: "Aún no se han registrado suscripciones de cortesía.",
  noCourtesySubscriptorsForFilterText: "No existen suscripciones para el filtro aplicado",
  dashboard: "Dashboard",
  totalSubscriptors: "Total de suscritos",
  courtesySubscriptions: "Suscripciones de cortesía",
  expiredSubscriptions: "Suscripciones que vencen",
  expiredSubscriptionsExplanation: "Suscripciones que fueron canceladas pero que aún tienen validez",
  sixMonthsSubscriptors: "Cantidad de suscritos activos en los últimos 6 meses",
  errorTransactions: "Transacciones con error en las últimas 24 horas.",
  none: "Ninguna",
  seeFullReport: "Ver reporte completo",
  noDataForReport: "Aún no hay datos para generar el reporte",
  subscriptors: "Suscriptores",
  subscriptorsError: "Ocurrió un error al obtener los suscriptores",
  noSubscriptionsForFilterError: "No existen suscripciones para el filtro aplicado",
  exportSubscriptors: "Exportar suscriptores",
  month: "Mes",
  issuersError: "Ocurrió un error al obtener los issuers",
  issuers: "Issuers",
  newIssuer: "Nuevo issuer",
  plexoId: "Id de Plexo",
  paymentFacilitators: "Payment Facilitators",
  newPaymentFacilitator: "Nuevo payment facilitator",
  paymentFacilitatorId: "Id del payment facilitator",
  styles: "Estilos",
  primaryColor: "Color primario",
  secondaryColor: "Color secundario",
  password: "Contraseña",
  paymentFacilitatorCreationSuccess: "Se ha creado el payment facilitator correctamente",
  paymentFacilitatorCreationError: "Ocurrió un error al crear el payment facilitator",
  paymentFacilitatorUpdateSuccess: "Se actualizó el payment facilitator correctamente",
  paymentFacilitatorUpdateError: "Ocurrió un error al actualizar el payment facilitator",
  paymentFacilitatorIssuersError: "Ocurrió un error al obtener los issuers",
  paymentFacilitatorIssuers: "Issuers del payment facilitator",
  newPaymentFacilitatorIssuer: "Nuevo issuer",
  frequency: "Frecuencia",
  cost: "Costo",
  currency: "Moneda",
  recurrence: "Recurrencia",
  description: "Descripción",
  subscription: "Suscripción",
  instrument: "Instrumento",
  subscriptorName: "Nombre suscriptor",
  subscriptorId: "Id externo suscriptor",
  courtesySubscription: "Suscripción de cortesía",
  subscriptionTypesError: "Ocurrió un error al obtener los tipos de suscripción",
  subscriptionTypes: "Tipos de suscripción",
  newSubscriptionType: "Nuevo tipo de suscripción",
  courtesySubscriptionTypeDeletionWarning: "Se borrarán todos los suscriptores de cortesía asociados a este tipo.",
  paidSubscriptions: "Suscripciones pagas",
  noSubscriptionTypesText: "Aún no hay tipos de suscripciones agregados",
  noCourtesySubscriptionTypesText: "Aún no hay tipos de suscripciones agregados",
  cancelRenew: "Cancelar renovación",
  cancelSubscriptionSuccess: "Se canceló la suscripción correctamente",
  cancelSubscriptionError: "Ocurrió un error al cancelar la suscripción",
  deleteSubscriptorQuestion: "Seguro que deseas borrar el suscriptor?",
  lastPaymentMade: "Último pago realizado",
  validUntil: "Válido hasta",
  date: "Fecha",
  amount: "Importe",
  noSubscriptionsForSubscriptor: "No existen suscripciones para este suscriptor.",
  status: "Estado",
  all: "Todos",
  active: "Activos",
  inactive: "Inactivos",
  subscriptionDate: "Fecha de alta",
  pesosToken: "$",
  dollarsToken: "USD",
  mexicanPesosToken: "MXN",
  billNumber: "No. de factura",
  from: "Desde",
  to: "Hasta",
  minAmount: "Monto mínimo",
  maxAmount: "Monto máximo",
  successful: "Exitosas",
  failed: "Fallidas",
  successfulPayments: "Pagos aprobados",
  failedPayments: "Pagos fallidos",
  adminRegister: "Registro de administrador",
  usersError: "Ocurrió un error al obtener los usuarios",
  administrators: "Administradores",
  registerNewUser: "Registrar nuevo usuario",
  commerceCodes: "Códigos de comercio",
  passwordsDoNotMatch: "Las contraseñas no coinciden",
  passwordRequirements: "La contraseña debe tener al menos una mayúscula, una minúscula y un número",
  passwordLength: "La contraseña debe tener al menos 6 caracteres.",
  rutLength: "El RUT debe ser de 12 caracteres de largo",
  diary: "Diario",
  weekly: "Semanal",
  monthly: "Mensual",
  bimonthly: "Bimensual",
  quaterly: "Trimestral",
  biannual: "Bianual",
  annual: "Anual",
  ok: "OK",
  onHold: "En espera",
  canceled: "Cancelada",
  cancellationFailed: "Cancelación fallida",
  failedStatus: "Fallida",
  default: "Default",
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchIssuers } from "../../store/action_creators/issuers.actions";
import Issuer from "./Issuer";
import { Button } from "@material-ui/core";
import IssuerForm from "./IssuerForm";
import Loading from "../common/animations/Loading";
import Error from "../common/animations/Error";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    issuers: state.issuers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchIssuers: () => {
    dispatch(fetchIssuers());
  },
});

function Issuers({ issuers, fetchIssuers }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!issuers.issuers) {
      fetchIssuers();
    }
  }, [issuers.issuers, fetchIssuers]);

  if ((!issuers.issuers && !issuers.errorMessage) || issuers.isLoading) {
    return <Loading />;
  } else if (issuers.errorMessage) {
    return <Error message={texts.issuersError} retry={fetchIssuers} />;
  } else {
    return (
      <div className="issuers">
        <div className="issuers-title">
          <h3>{texts.issuers}</h3>
          <Button color="secondary" variant="contained" onClick={() => setOpen(true)}>
            {texts.newIssuer}
          </Button>
        </div>
        <div className="issuers-list">
          {issuers.issuers &&
            issuers.issuers.map((issuer, index) => {
              return <Issuer key={index} position={index} data={issuer} />;
            })}
        </div>
        <IssuerForm open={open} setOpen={setOpen} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Issuers);

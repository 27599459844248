import React from "react";
import Lottie from "react-lottie";
import errorAnimation from "../../../assets/error.json";
import { Button, withStyles } from "@material-ui/core";
import { colors } from "../../../data/colors.js";
import { texts } from "../../../data/texts";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    fontWeight: 300,
    fontSize: 20,
  },
  errorButton: {
    backgroundColor: colors["errorRed"],
    color: colors["white"],
  },
});

function Error({ classes, message, retry }) {
  return (
    <div className={classes.container}>
      <Lottie
        height={150}
        width={150}
        isClickToPauseDisabled={true}
        options={{ animationData: errorAnimation, loop: true }}
      />
      <p className={classes.message}>{message}</p>
      <Button className={classes.errorButton} variant="contained" onClick={retry}>
        {texts.retry}
      </Button>
    </div>
  );
}

export default withStyles(styles)(Error);

import { httpVerbs, URL } from "./config";
import { baseService } from "./base.service";

export const reportsService = {
  fetchDashboard,
};

async function fetchDashboard(clientId) {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  if (clientId) {
    requestOptions.headers.append("clientId", clientId);
  }

  return await fetch(`${URL}/home`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

import React, { useState } from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { createPFIssuer } from "../../store/action_creators/issuers.actions";
import MarkControl from "../commerces/MarkControl";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    issuers: state.issuers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createPFIssuer: ({ issuerId, name, pfId }) => {
    dispatch(createPFIssuer({ issuerId, name, pfId }));
  },
});

function PaymentFacilitatorIssuerForm({ open, setOpen, selectedIssuer, auth, issuers, createPFIssuer }) {
  const [issuer, setIssuer] = useState(null);

  const submitIssuer = () => {
    const isNew = selectedIssuer === undefined;

    if (isNew) {
      createPFIssuer({
        issuerId: issuer.id,
        name: issuer.name,
        pfId: auth.user.paymentFacilitatorDetails.id,
      });
    }

    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <div className="form">
        <DialogTitle>{selectedIssuer ? `Editar ${selectedIssuer.name}` : "Nuevo issuer"}</DialogTitle>
        <DialogContent>
          <MarkControl selectedCommerce={null} issuer={issuer} setIssuer={setIssuer} />
        </DialogContent>
        <DialogActions>
          <Button onClick={submitIssuer} className="save" variant="contained" color="secondary" type="submit">
            {texts.save}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFacilitatorIssuerForm);

import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import ChangePasswordDialog from "../common/dialogs/ChangePasswordDialog";
import ConfigurationSuperAdmin from "./ConfigurationSuperAdmin";
import ConfigurationAdmin from "./ConfigurationAdmin/ConfigurationAdmin";
import { adminTypes } from "../../data/adminTypes";
import SecretDialog from "../common/dialogs/SecretDialog";
import ConfigurationPFAdmin from "./ConfigurationPFAdmin";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

function Configuration({ auth }) {
  const [open, setOpen] = useState(false);
  const [secretOpen, setSecretOpen] = useState(false);
  const userType = auth.user?.userType;
  const isAdmin = userType === adminTypes.CLIENTADMIN;
  const isSuperAdmin = userType === adminTypes.SUPERADMIN;
  const isPaymentFacilitatorAdmin = userType === adminTypes.PFADMIN;

  return (
    <div className="configuration">
      <div className="configuration-title">
        <h3>{texts.configuration}</h3>
        {isAdmin && (
          <div className="right-side">
            <p>Client ID: {auth.user?.clientDetails.id}</p>
            <Button onClick={() => setSecretOpen(true)} variant="contained" color="secondary">
              {texts.generateNewClientSecret}
            </Button>
            <Button onClick={() => setOpen(true)} variant="contained" color="secondary">
              {texts.changePassword}
            </Button>
          </div>
        )}
      </div>
      {isSuperAdmin && (
        <>
          <div className="password-title">
            <h4>{texts.passwordManagement}</h4>
          </div>
          <div className="password-content">
            <Button onClick={() => setOpen(true)} variant="contained" color="secondary">
              {texts.changePassword}
            </Button>
          </div>
        </>
      )}
      {isAdmin && <ConfigurationAdmin />}
      {isSuperAdmin && <ConfigurationSuperAdmin />}
      {isPaymentFacilitatorAdmin && <ConfigurationPFAdmin />}
      <ChangePasswordDialog open={open} setOpen={setOpen} />
      <SecretDialog open={secretOpen} setOpen={setSecretOpen} />
    </div>
  );
}

export default connect(mapStateToProps)(Configuration);

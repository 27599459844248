import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from "@material-ui/core";
import ClientForm from "./ClientForm";
import DeleteDialog from "../common/dialogs/DeleteDialog";
import SecretDialog from "../common/dialogs/SecretDialog";
import { deleteClient } from "../../store/action_creators/clients.actions";
import Commerces from "../commerces/Commerces";
import Dashboard from "../dashboard/Dashboard";
import Transactions from "../transactions/Transactions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteClient: ({ clientId, userId }) => {
    dispatch(deleteClient({ clientId, userId }));
  },
});

function ClientDetails({ clients, deleteClient }) {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [secretOpen, setSecretOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    const selectedClientId = params.clientId;

    if (clients.clients) {
      setSelectedClient(clients.clients.find((c) => c.id === selectedClientId));
    }
  }, [params, clients.clients, setSelectedClient]);

  if (!clients.clients) {
    history.push("/clients");
  }

  const submitDelete = () => {
    deleteClient({ clientId: selectedClient.id, userId: selectedClient.admin.userId });
    setDeleteOpen(false);
    history.push("/clients");
  };

  return (
    <div className="clients client-details">
      <div className="clients-title">
        <h3>{`Cliente ${selectedClient && selectedClient.name}`}</h3>
        <span className="blue-link" onClick={() => history.push("/clients")}>
          {texts.backToList}
        </span>
      </div>
      {selectedClient && (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h4>{texts.actionsOnClient}</h4>
            </AccordionSummary>
            <AccordionDetails>
              <div className="actions">
                <div className="action">
                  <span className="action-title">{texts.configureClientSecret}</span>
                  <IconButton onClick={() => setSecretOpen(true)}>
                    <FontAwesomeIcon icon="key" className="key-icon" />
                  </IconButton>
                </div>
                <div className="action">
                  <span className="action-title">{texts.editClient}</span>
                  <IconButton onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon="edit" className="edit-icon" />
                  </IconButton>
                </div>
                <div className="action">
                  <span className="action-title">{texts.deleteClient}</span>
                  <IconButton onClick={() => setDeleteOpen(true)}>
                    <FontAwesomeIcon icon="trash" className="delete-icon" />
                  </IconButton>
                </div>
                <SecretDialog open={secretOpen} setOpen={setSecretOpen} selectedClient={selectedClient} />
                <ClientForm open={open} setOpen={setOpen} selectedClient={selectedClient} />
                <DeleteDialog
                  open={deleteOpen}
                  setOpen={setDeleteOpen}
                  text={`Seguro que deseas borrar el cliente ${selectedClient && selectedClient.name}`}
                  submit={submitDelete}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h4>{texts.commerces}</h4>
            </AccordionSummary>
            <AccordionDetails>
              <Commerces clientId={selectedClient.id} />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h4>{texts.clientStatistics}</h4>
            </AccordionSummary>
            <AccordionDetails>
              <Dashboard clientId={selectedClient.id} />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h4>{texts.transactions}</h4>
            </AccordionSummary>
            <AccordionDetails>
              <Transactions clientId={selectedClient.id} />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);

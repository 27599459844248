import { status } from "../data/status";
import { colors } from "../data/colors";
import moment from "moment";
import { texts } from "../data/texts";

export const statusHelper = {
  getStatusColor,
  getStatusName,
};

function getStatusColor(statusInput, expiration) {
  switch (statusInput) {
    case status.OK:
      return expiration && moment(expiration).isBefore(moment()) ? colors["red"] : colors["green"];

    case status.ONHOLD:
      return colors["yellow"];

    case status.CANCELED:
      return colors["red"];

    case status.CANCELLATIONFAILED:
      return colors["red"];

    case status.FAILED:
      return colors["red"];

    default:
      return colors["red"];
  }
}

function getStatusName(statusInput) {
  switch (statusInput) {
    case status.OK:
      return texts.ok;

    case status.ONHOLD:
      return texts.onHold;

    case status.CANCELED:
      return texts.canceled;

    case status.CANCELLATIONFAILED:
      return texts.cancellationFailed;

    case status.FAILED:
      return texts.failedStatus;

    default:
      return texts.default;
  }
}

import { httpVerbs, URL } from "./config";
import { baseService } from "./base.service";

export const paymentFacilitatorIssuersService = {
  fetchPaymentFacilitatorIssuers,
  createPaymentFacilitatorIssuer,
  deletePaymentFacilitatorIssuer,
};

async function fetchPaymentFacilitatorIssuers(pfId, clientId) {
  const requestOptions = {
    method: httpVerbs.Get,
    headers: baseService.getHeaders(true),
  };

  if (clientId) {
    requestOptions.headers.append("clientId", clientId);
  }

  return await fetch(`${URL}/pfIssuers/${pfId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function createPaymentFacilitatorIssuer(pfIssuerRequest) {
  const requestOptions = {
    method: httpVerbs.Post,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(pfIssuerRequest),
  };

  return await fetch(`${URL}/pfIssuers`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

async function deletePaymentFacilitatorIssuer(issuerId, pfId) {
  const request = {
    issuerId,
    pfId,
  };

  const requestOptions = {
    method: httpVerbs.Delete,
    headers: baseService.getHeaders(true),
    body: JSON.stringify(request),
  };

  return await fetch(`${URL}/pfIssuers/${issuerId}`, requestOptions)
    .then(baseService.handleResponse)
    .then((response) => {
      return response;
    });
}

import { currencies } from "../data/currencies";
import { texts } from "../data/texts";

export const currencyHelper = {
  getCurrencyToken,
};

function getCurrencyToken(currency) {
  switch (currency) {
    case currencies.PESO:
      return texts.pesosToken;

    case currencies.DOLAR:
      return texts.dollarsToken;

    case currencies.MXPESO:
      return texts.mexicanPesosToken;

    default:
      return texts.pesosToken;
  }
}

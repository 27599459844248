import * as ActionTypes from "../config/ActionTypes";
import { subscriptorsService } from "../../services/subscriptors.service";
import { filteringHelper } from "../../helpers/filteringHelper";

//#region Fetch subscriptors

export const fetchSubscriptors = ({ page, limit, filters, search, reset }) => (dispatch) => {
  dispatch(subscriptorsLoading());

  if (reset) {
    dispatch(applyFilters({ filters, search }));
  }

  let filtersString = filteringHelper.applySubscriptorsFilters(filters);

  if (search) {
    filtersString = filtersString.concat(`&subscriptorEmailOrNameOrId=${search}`);
  }


  return subscriptorsService.fetchSubscriptors(page, limit, filtersString).then(
    (response) => {
      dispatch(addSubscriptors(response));
    },
    (error) => {
      dispatch(subscriptorsFailed(error));
    }
  );
};

const applyFilters = ({ filters, search }) => ({
  type: ActionTypes.APPLY_SUBSCRIPTORS_FILTERS,
  payload: { filters, search },
});

const addSubscriptors = (subscriptors) => ({
  type: ActionTypes.ADD_SUBSCRIPTORS,
  payload: subscriptors,
});

const subscriptorsLoading = () => ({
  type: ActionTypes.SUBSCRIPTORS_LOADING,
});

const subscriptorsFailed = (errorMessage) => ({
  type: ActionTypes.SUBSCRIPTORS_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Get subscriptor

export const getSubscriptor = ({ externalId }) => (dispatch) => {
  dispatch(gettingSubscriptor());

  return subscriptorsService.getSubscriptor(externalId).then(
    (response) => {
      dispatch(addSubscriptor(response));
    },
    (error) => {
      dispatch(getSubscriptorFailed(error));
    }
  );
};

const addSubscriptor = (subscriptorData) => ({
  type: ActionTypes.ADD_SUBSCRIPTOR,
  payload: subscriptorData,
});

const gettingSubscriptor = () => ({
  type: ActionTypes.SUBSCRIPTOR_LOADING,
});

const getSubscriptorFailed = (errorMessage) => ({
  type: ActionTypes.ADD_SUBSCRIPTOR_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Cancel subscription

export const cancelSubscription = ({ subscriptionToken, externalId }) => (dispatch) => {
  dispatch(cancellingSubscription());

  return subscriptorsService.cancelSubscription(subscriptionToken, externalId).then(
    (response) => {
      dispatch(cancelSubscriptionSuccess(response));
    },
    (error) => {
      dispatch(cancelSubscriptionFailed(error));
    }
  );
};

const cancelSubscriptionSuccess = (response) => ({
  type: ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
  payload: response,
});

const cancellingSubscription = () => ({
  type: ActionTypes.CANCELLING_SUBSCRIPTION,
});

const cancelSubscriptionFailed = (errorMessage) => ({
  type: ActionTypes.CANCEL_SUBSCRIPTION_FAILED,
  payload: errorMessage,
});

//#endregion

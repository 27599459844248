import React from "react";
import { Select, withStyles, MenuItem, OutlinedInput, FormControl, InputLabel } from "@material-ui/core";
import { colors } from "../../../data/colors";

const styles = (theme) => ({
  input: {
    fontSize: 15,
    color: colors["notBlack"],
  },
  tooltip: {
    backgroundColor: colors["errorRed"],
    fontSize: 15,
    padding: "5px 15px",
  },
  errorIcon: {
    color: colors["errorRed"],
    fontSize: 15,
    cursor: "pointer",
  },
  formControl: {
    marginTop: 16,
    marginBottom: 8,
  },
});

function FormSelect({
  field,
  containerClassName,
  value,
  setValue,
  options,
  labelWidth,
  isLong,
  form: { touched, errors },
  classes,
  ...props
}) {
  return (
    <FormControl variant="outlined" fullWidth className={classes.formControl}>
      <InputLabel htmlFor={`${props.name}-helper`}>{props.label}</InputLabel>
      <Select
        fullWidth
        input={<OutlinedInput labelWidth={labelWidth} id={`${props.name}-helper`} variant="outlined" />}
        {...field}
        value={value}
        onChange={setValue}
        {...props}
      >
        {options &&
          options.map((opt, index) => (
            <MenuItem value={opt.id} key={index}>
              {opt.value}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default withStyles(styles)(FormSelect);

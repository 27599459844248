import * as ActionTypes from "../config/ActionTypes";
import { issuersService } from "../../services/issuers.service";
import { paymentFacilitatorIssuersService } from "../../services/paymentFacilitatorIssuers.service";

//#region Fetch issuers

export const fetchIssuers = (clientId) => (dispatch) => {
  dispatch(issuersLoading());

  return issuersService.fetchIssuers(clientId).then(
    (issuers) => {
      dispatch(addIssuers(issuers));
    },
    (error) => {
      dispatch(issuersFailed(error));
    }
  );
};

const addIssuers = (issuers) => ({
  type: ActionTypes.ADD_ISSUERS,
  payload: issuers,
});

const issuersLoading = () => ({
  type: ActionTypes.ISSUERS_LOADING,
});

const issuersFailed = (errorMessage) => ({
  type: ActionTypes.ISSUERS_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Fetch PF issuers

export const fetchPFIssuers = (pfId, clientId) => (dispatch) => {
  dispatch(pfIssuersLoading());

  return paymentFacilitatorIssuersService.fetchPaymentFacilitatorIssuers(pfId, clientId).then(
    (pfIssuers) => {
      dispatch(addPFIssuers(pfIssuers));
    },
    (error) => {
      dispatch(pfIssuersFailed(error));
    }
  );
};

const addPFIssuers = (pfIssuers) => ({
  type: ActionTypes.ADD_PAYMENT_FACILITATOR_ISSUERS,
  payload: pfIssuers,
});

const pfIssuersLoading = () => ({
  type: ActionTypes.PAYMENT_FACILITATOR_ISSUERS_LOADING,
});

const pfIssuersFailed = (errorMessage) => ({
  type: ActionTypes.PAYMENT_FACILITATORS_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Create issuer

export const createIssuer =
  ({ name, normalizedName, pictureUri, plexoId, active }) =>
  (dispatch) => {
    const issuerRequest = {
      name,
      normalizedName,
      pictureUri,
      plexoId,
      active,
    };

    dispatch(creatingIssuer());

    return issuersService.createIssuer(issuerRequest).then(
      (issuer) => {
        dispatch(addIssuer(issuer));
      },
      (error) => {
        dispatch(createIssuerFailed(error));
      }
    );
  };

const addIssuer = (issuer) => ({
  type: ActionTypes.ADD_ISSUER,
  payload: issuer,
});

const creatingIssuer = () => ({
  type: ActionTypes.CREATING_ISSUER,
});

const createIssuerFailed = (errorMessage) => ({
  type: ActionTypes.CREATE_ISSUER_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Create PF issuer

export const createPFIssuer =
  ({ issuerId, name, pfId }) =>
  (dispatch) => {
    const pfIssuerRequest = {
      issuerId,
      name,
      pfId,
    };

    dispatch(creatingPFIssuer());

    return paymentFacilitatorIssuersService.createPaymentFacilitatorIssuer(pfIssuerRequest).then(
      (pfIssuer) => {
        dispatch(addPFIssuer(pfIssuer));
      },
      (error) => {
        dispatch(createPFIssuerFailed(error));
      }
    );
  };

const addPFIssuer = (pfIssuer) => ({
  type: ActionTypes.ADD_PAYMENT_FACILITATOR_ISSUER,
  payload: pfIssuer,
});

const creatingPFIssuer = () => ({
  type: ActionTypes.CREATING_PAYMENT_FACILITATOR_ISSUER,
});

const createPFIssuerFailed = (errorMessage) => ({
  type: ActionTypes.CREATE_PAYMENT_FACILITATOR_ISSUER_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Update issuer

export const updateIssuer =
  ({ issuerId, name, normalizedName, pictureUri, plexoId, active }) =>
  (dispatch) => {
    const issuerRequest = {
      name,
      normalizedName,
      pictureUri,
      plexoId,
      active,
    };

    dispatch(updatingIssuer());

    return issuersService.updateIssuer(issuerId, issuerRequest).then(
      (issuer) => {
        dispatch(refreshIssuer(issuer));
      },
      (error) => {
        dispatch(updateIssuerFailed(error));
      }
    );
  };

const refreshIssuer = (issuer) => ({
  type: ActionTypes.UPDATE_ISSUER,
  payload: issuer,
});

const updatingIssuer = () => ({
  type: ActionTypes.UPDATING_ISSUER,
});

const updateIssuerFailed = (errorMessage) => ({
  type: ActionTypes.UPDATE_ISSUER_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Delete issuer

export const deleteIssuer =
  ({ issuerId }) =>
  (dispatch) => {
    dispatch(deletingIssuer());

    return issuersService.deleteIssuer(issuerId).then(
      (response) => {
        dispatch(removeIssuer(issuerId));
      },
      (error) => {
        dispatch(deleteIssuerFailed(error));
      }
    );
  };

const removeIssuer = (issuerId) => ({
  type: ActionTypes.DELETE_ISSUER,
  payload: issuerId,
});

const deletingIssuer = () => ({
  type: ActionTypes.DELETING_ISSUER,
});

const deleteIssuerFailed = (errorMessage) => ({
  type: ActionTypes.DELETE_ISSUER_FAILED,
  payload: errorMessage,
});

//#endregion

//#region Delete PF issuer

export const deletePFIssuer =
  ({ issuerId, pfId }) =>
  (dispatch) => {
    dispatch(deletingPFIssuer());

    return paymentFacilitatorIssuersService.deletePaymentFacilitatorIssuer(issuerId, pfId).then(
      (response) => {
        dispatch(removePFIssuer(issuerId));
      },
      (error) => {
        dispatch(deletePFIssuerFailed(error));
      }
    );
  };

const removePFIssuer = (issuerId) => ({
  type: ActionTypes.DELETE_PAYMENT_FACILITATOR_ISSUER,
  payload: issuerId,
});

const deletingPFIssuer = () => ({
  type: ActionTypes.DELETING_PAYMENT_FACILITATOR_ISSUER,
});

const deletePFIssuerFailed = (errorMessage) => ({
  type: ActionTypes.DELETE_PAYMENT_FACILITATOR_ISSUER_FAILED,
  payload: errorMessage,
});

//#endregion

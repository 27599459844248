import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
// import logger from "redux-logger";
import { AuthReducer } from "../reducers/auth";
import { ClientsReducer } from "../reducers/clients";
import { CommercesReducer } from "../reducers/commerces";
import { IssuersReducer } from "../reducers/issuers";
import { PaymentFacilitatorsReducer } from "../reducers/paymentFacilitators";
import { ReportsReducer } from "../reducers/reports";
import { SubscriptionsReducer } from "../reducers/subscriptions";
import { SubscriptorsReducer } from "../reducers/subscriptors";
import { CourtesySubscriptorsReducer } from "../reducers/courtesySubscriptors";
import { TransactionsReducer } from "../reducers/transactions";
import { UsersReducer } from "../reducers/users";

const composeEnhancers =
  typeof window === "object" && process.env.REACT_APP_REDUX_DEVTOOLS === "true" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk /*logger*/)
  // other store enhancers if any
);

export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      auth: AuthReducer,
      clients: ClientsReducer,
      commerces: CommercesReducer,
      issuers: IssuersReducer,
      paymentFacilitators: PaymentFacilitatorsReducer,
      reports: ReportsReducer,
      subscriptions: SubscriptionsReducer,
      subscriptors: SubscriptorsReducer,
      courtesySubscriptors: CourtesySubscriptorsReducer,
      transactions: TransactionsReducer,
      users: UsersReducer,
    }),
    enhancer
  );

  return store;
};

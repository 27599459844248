import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberHelper } from "../../helpers/numberHelper";
import { currencyHelper } from "../../helpers/currencyHelper";
import { statusHelper } from "../../helpers/statusHelper";
import CancelSubscription from "./CancelSubscription";
import { Tooltip } from "@material-ui/core";
import { stringHelper } from "../../helpers/stringHelper";
import { texts } from "../../data/texts";

function SubscriptionRow({ userData, subscription, subscriptionOpened, setSubscriptionOpened }) {
  const transactionsCount = userData.transactions && userData.transactions.filter((t) => t.state === 0).length;

  return (
    <>
      <div className="subscription-row">
        <div className="column">
          <span className="subscription-title">
            <Tooltip placement="top" title={statusHelper.getStatusName(subscription.subscriptionStatus)}>
              <div
                className="subscription-status"
                style={{
                  backgroundColor: statusHelper.getStatusColor(
                    subscription.subscriptionStatus,
                    subscription.validUntil
                  ),
                }}
              />
            </Tooltip>
            {subscription.subscriptionType.recurrence !== 0 && (
              <span>
                {subscription.subscriptionType?.name}{" "}
                <span className="subscription-recurrence">{`(${transactionsCount}/${subscription.subscriptionType.recurrence})`}</span>
              </span>
            )}
          </span>
          <div className="payment-chunk">
            <span className="title">{texts.lastPaymentMade}</span>
            <span>{moment(subscription.subscriptionLastPaymentDate).format("DD/MM/YYYY")}</span>
            <span className="payment-method">
              <img src={userData.instrument?.issuerImageUrl} alt="issuer" />
              {stringHelper.getMaskedInstrument(userData.instrument?.name)}
            </span>
          </div>
        </div>
        <div className="column">
          <span className="subscription-title">
            {`${currencyHelper.getCurrencyToken(subscription.subscriptionType?.currency)} ${numberHelper.addIVAToAmount(
              subscription.subscriptionType?.subscriptionBaseCost,
              subscription.subscriptionType?.taxPercentage
            )}`}
          </span>
          <div className="payment-chunk">
            <span className="title">{texts.validUntil}</span>
            <span>{moment(subscription.validUntil).format("DD/MM/YYYY")}</span>
            <CancelSubscription subscription={subscription} userData={userData} />
          </div>
        </div>
        <div className="column">
          <FontAwesomeIcon
            icon="chevron-right"
            className={`icon ${subscriptionOpened === subscription.id ? "opened" : ""}`}
            onClick={() => {
              if (subscriptionOpened !== subscription.id) {
                setSubscriptionOpened(subscription.id);
              } else {
                setSubscriptionOpened(null);
              }
            }}
          />
        </div>
      </div>
      {subscriptionOpened === subscription.id ? (
        <div className="subscription-row-transactions">
          <div className="subscription-row-title">
            <div className="column">{texts.date}</div>
            <div className="column">{texts.paymentMethod}</div>
            <div className="column">{texts.status}</div>
            <div className="column">{texts.amount}</div>
          </div>
          {userData &&
            userData.transactions.map((t) => {
              if (t.subscription && t.subscription.id === subscription.id) {
                return (
                  <div key={t.id} className="subscription-row-transaction">
                    <div className="column">{moment(t.createdAt).format("DD/MM/YYYY")}</div>
                    <div className="column">
                      <img src={t.instrument.issuerImageUrl} alt="issuer" />
                      {stringHelper.getMaskedInstrument(t.instrument.name)}
                    </div>
                    <div className="column">{`${statusHelper.getStatusName(t.state)}`}</div>
                    <div className="column">{t.amount}</div>
                  </div>
                );
              }
              return null;
            })}
        </div>
      ) : null}
    </>
  );
}

export default SubscriptionRow;

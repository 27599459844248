import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loading from "../common/animations/Loading";
import TotalSubscriptionsChart from "./TotalSubscriptionsChart";
import { fetchDashboard } from "../../store/action_creators/reports.actions";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { texts } from "../../data/texts";

const mapStateToProps = (state) => {
  return {
    reports: state.reports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDashboard: (clientId) => {
    dispatch(fetchDashboard(clientId));
  },
});

function Dashboard({ reports, fetchDashboard, clientId }) {
  useEffect(() => {
    if ((!reports.dashboard && !reports.isLoading && !reports.errorMessage) || reports.dashboardClientId !== clientId) {
      fetchDashboard(clientId);
    }
  }, [reports.dashboard, reports.isLoading, reports.errorMessage, reports.dashboardClientId, fetchDashboard, clientId]);

  return (
    <div className="dashboard">
      {!clientId && (
        <div className="dashboard-title">
          <h3>{texts.dashboard}</h3>
        </div>
      )}
      <div className="dashboard-content">
        <div className="cards">
          <div className="dashboard-card">
            <span className="value">
              {reports.dashboard ? reports.dashboard.totalSubscriptors : <Loading size={80} />}
            </span>
            <span className="title">{texts.totalSubscriptors}</span>
          </div>
          <div className="dashboard-card">
            <span className="value">
              {reports.dashboard ? reports.dashboard.courtesySubscriptors : <Loading size={80} />}
            </span>
            <span className="title">{texts.courtesySubscriptions}</span>
          </div>
          <div className="dashboard-card">
            <span className="value">
              {reports.dashboard ? reports.dashboard.churnSubscriptors : <Loading size={80} />}
            </span>
            <span className="title-with-tooltip">
              {texts.expiredSubscriptions}
              <Tooltip className="tooltip" title={texts.expiredSubscriptionsExplanation}>
                <InfoOutlinedIcon color="primary" fontSize="small" />
              </Tooltip>
            </span>
          </div>
        </div>
        {clientId === undefined && (
          <div className="dashboard-chart">
            <span className="title">{texts.sixMonthsSubscriptors}</span>
            <TotalSubscriptionsChart />
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

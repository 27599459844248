import * as ActionTypes from "../config/ActionTypes";
import moment from "moment";

export const initialState = {
  isLoading: false,
  errorMessage: null,
  currentPage: 1,
  pageCount: 0,
  rowCount: 0,
  totals: 0,
  totalsDollar: 0,
  totalsFailed: 0,
  totalsDollarFailed: 0,
  transactions: null,
  transactionsClientId: null,
  filters: {
    from: moment().add(-1, "years"),
    to: moment(),
    minAmount: 0,
    maxAmount: 1000,
    subscriptionTypes: [],
  },
};

export const TransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_TRANSACTIONS:
      let newTransactions = state.transactions
        ? state.transactions.concat(action.payload.results)
        : action.payload.results;

      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        currentPage: action.payload.currentPage,
        pageCount: action.payload.pageCount,
        rowCount: action.payload.rowCount,
        totals: action.payload.totals,
        totalsDollar: action.payload.totalsDollar,
        totalsFailed: action.payload.totalsFailed,
        totalsDollarFailed: action.payload.totalsDollarFailed,
        transactions: newTransactions,
      };

    case ActionTypes.TRANSACTIONS_LOADING:
      return {
        ...state,
        isLoading: true,
        transactionsClientId: action.payload,
        errorMessage: null,
      };

    case ActionTypes.TRANSACTIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };

    case ActionTypes.APPLY_FILTERS:
      return {
        ...state,
        filters: action.payload,
        transactions: [],
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

import * as ActionTypes from "../config/ActionTypes";

export const initialState = {
  isLoading: false,
  errorMessage: null,
  issuers: null,
  pfIssuersLoading: false,
  pfIssuersErrorMessage: null,
  pfIssuers: null,
  creatingIssuer: false,
  createIssuerErrorMessage: null,
  createIssuerSuccess: false,
  creatingPFIssuer: false,
  createPFIssuerErrorMessage: null,
  createPFIssuerSuccess: false,
  updatingIssuer: false,
  updateIssuerErrorMessage: null,
  updateIssuerSuccess: false,
  deletingIssuer: false,
  deleteIssuerErrorMessage: null,
  deleteIssuerSuccess: false,
  deletingPFIssuer: false,
  deletePFIssuerErrorMessage: null,
  deletePFIssuerSuccess: false,
};

export const IssuersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_ISSUERS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        issuers: action.payload,
      };

    case ActionTypes.ISSUERS_LOADING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };

    case ActionTypes.ISSUERS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
        issuers: null,
      };

    case ActionTypes.ADD_PAYMENT_FACILITATOR_ISSUERS:
      return {
        ...state,
        pfIssuersLoading: false,
        pfIssuersErrorMessage: null,
        pfIssuers: action.payload,
      };

    case ActionTypes.PAYMENT_FACILITATOR_ISSUERS_LOADING:
      return {
        ...state,
        pfIssuersLoading: true,
        pfIssuersErrorMessage: null,
      };

    case ActionTypes.PAYMENT_FACILITATOR_ISSUERS_FAILED:
      return {
        ...state,
        pfIssuersLoading: false,
        pfIssuersErrorMessage: action.payload,
        pfIssuers: null,
      };

    case ActionTypes.ADD_ISSUER:
      let newIssuers = state.issuers || [];
      newIssuers.push(action.payload);

      return {
        ...state,
        creatingIssuer: false,
        createIssuerErrorMessage: null,
        createIssuerSuccess: true,
        issuers: newIssuers,
      };

    case ActionTypes.CREATING_ISSUER:
      return {
        ...state,
        creatingIssuer: true,
        createIssuerErrorMessage: null,
        createIssuerSuccess: false,
      };

    case ActionTypes.CREATE_ISSUER_FAILED:
      return {
        ...state,
        creatingIssuer: false,
        createIssuerErrorMessage: action.payload,
        createIssuerSuccess: false,
      };

    case ActionTypes.ADD_PAYMENT_FACILITATOR_ISSUER:
      let newPFIssuers = state.pfIssuers || [];
      newPFIssuers.push(action.payload);

      return {
        ...state,
        creatingPFIssuer: false,
        createPFIssuerErrorMessage: null,
        createPFIssuerSuccess: true,
        pfIssuers: newPFIssuers,
      };

    case ActionTypes.CREATING_PAYMENT_FACILITATOR_ISSUER:
      return {
        ...state,
        creatingPFIssuer: true,
        createPFIssuerErrorMessage: null,
        createPFIssuerSuccess: false,
      };

    case ActionTypes.CREATE_PAYMENT_FACILITATOR_FAILED:
      return {
        ...state,
        creatingPFIssuer: false,
        createPFIssuerErrorMessage: action.payload,
        createPFIssuerSuccess: false,
      };

    case ActionTypes.UPDATE_ISSUER:
      let updatedIssuers = state.issuers.map((item, index) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        return {
          ...action.payload,
        };
      });

      return {
        ...state,
        updatingIssuer: false,
        updateIssuerErrorMessage: null,
        updateIssuerSuccess: true,
        issuers: updatedIssuers,
      };

    case ActionTypes.UPDATING_ISSUER:
      return {
        ...state,
        updatingIssuer: true,
        updateIssuerErrorMessage: null,
        updateIssuerSuccess: false,
      };

    case ActionTypes.UPDATE_ISSUER_FAILED:
      return {
        ...state,
        updatingIssuer: false,
        updateIssuerErrorMessage: action.payload,
        updateIssuerSuccess: false,
      };

    case ActionTypes.DELETE_ISSUER:
      let nonDeletedIssuers = state.issuers.filter((item, index) => {
        if (item.id !== action.payload) {
          return item;
        }
        return null;
      });

      return {
        ...state,
        deletingIssuer: false,
        deleteIssuerErrorMessage: null,
        deleteIssuerSuccess: true,
        issuers: nonDeletedIssuers,
      };

    case ActionTypes.DELETING_ISSUER:
      return {
        ...state,
        deletingIssuer: true,
        deleteIssuerErrorMessage: null,
        deleteIssuerSuccess: false,
      };

    case ActionTypes.DELETE_ISSUER_FAILED:
      return {
        ...state,
        deletingIssuer: false,
        deleteIssuerErrorMessage: action.payload,
        deleteIssuerSuccess: false,
      };

    case ActionTypes.DELETE_PAYMENT_FACILITATOR_ISSUER:
      let nonDeletedPFIssuers = state.pfIssuers.filter((item, index) => {
        if (item.issuer.id !== action.payload) {
          return item;
        }
        return null;
      });

      return {
        ...state,
        deletingPFIssuer: false,
        deletePFIssuerErrorMessage: null,
        deletePFIssuerSuccess: true,
        pfIssuers: nonDeletedPFIssuers,
      };

    case ActionTypes.DELETING_PAYMENT_FACILITATOR_ISSUER:
      return {
        ...state,
        deletingPFIssuer: true,
        deletePFIssuerErrorMessage: null,
        deletePFIssuerSuccess: false,
      };

    case ActionTypes.DELETE_PAYMENT_FACILITATOR_ISSUER_FAILED:
      return {
        ...state,
        deletingPFIssuer: false,
        deletePFIssuerErrorMessage: action.payload,
        deletePFIssuerSuccess: false,
      };

    case ActionTypes.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
